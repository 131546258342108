import { markRaw, defineAsyncComponent } from 'vue';
import { store } from '@/store';

import LoadingComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageLoader.vue';

const ThirdFloor = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/6822-03.vue'
    ).then((module) => {
      store.dispatch('DigitalTwinStore/addComponentToCachedList', '6822-03');
      return module.default;
    }),

  loadingComponent: LoadingComponent,

  delay: 0,
});

// Suites
import Suite300 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-300.vue';
import Suite310 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-310.vue';
import Suite312 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-312.vue';
import Suite315 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-315.vue';
import Suite319A from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-319A.vue';
import Suite319B from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesSchematics/6822-03-suite-319B.vue';

// Hotspots
import Suite300Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite300Drawing.vue';
import Suite310Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite310Drawing.vue';
import Suite312Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite312Drawing.vue';
import Suite315Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite315Drawing.vue';
import Suite319AHotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite319ADrawing.vue';
import Suite319BHotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesHotspots/suite319BDrawing.vue';

// VAVs in the Common area
import VAV313 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/CommonArea/VAV313Drawing.vue';
import VAV314 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/CommonArea/VAV314Drawing.vue';
import VAV315 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/CommonArea/VAV315Drawing.vue';
import VAV316 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/CommonArea/VAV316Drawing.vue';

// VAVs in suites
import Suite300ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite300ControlsList.ts';
import Suite310ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite310ControlsList.ts';
import Suite312ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite312ControlsList.ts';
import Suite315ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite315ControlsList.ts';
import Suite319AControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite319AControlsList.ts';
import Suite319BControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/suite319BControlsList.ts';

import {
  dialog,
  childTab,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors';

import { IFloor } from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import eventBus from '@/Core.Service.Domain/Shared/eventBus';

const handleAction = (tab: MouseEvent | KeyboardEvent | number) => {
  if (typeof tab === 'number') {
    dialog.value = true;
    childTab.value = tab;
    eventBus.$emit('update:selectedVAVId', 0);
  }
};

const handleVAVAction = async (
  controlId: MouseEvent | KeyboardEvent | number
) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = true;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const thirdFloor: IFloor<Record<string, unknown>> = {
  id: 0,
  name: 'MGMOB - 3rd Floor',
  ahuId: 2093,
  loadedName: '6822-03',
  component: markRaw(ThirdFloor),
  showAHUButton: true,
  hotspotsList: [
    // Suite 315
    {
      id: 0,
      name: 'Suite 315',
      component: markRaw(Suite315Hotspot),
      techInfo: {
        location: 'Suite 315',
        icon: '',
        devices: ['VAV 3-24', 'VAV 3-25', 'VAV 3-41', 'VAV 3-42'],
      },
      style: {
        position: 'absolute',
        top: '0.5%',
        left: '3.6%',
        width: '35.3%',
        height: '35.3%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '0%',
        left: '3.6%',
        width: '35.3%',
        height: '35.3%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        left: '19%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 310
    {
      id: 1,
      name: 'Suite 310',
      component: markRaw(Suite310Hotspot),
      techInfo: {
        location: 'Suite 310',
        icon: '',
        devices: [
          'VAV 3-24',
          'VAV 3-25',
          'VAV 3-26',
          'VAV 3-27',
          'VAV 3-28',
          'VAV 3-29',
        ],
      },
      style: {
        position: 'absolute',
        top: '-0.22%',
        left: '36.33%',
        width: '31.5%',
        height: '31.5%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '-0.25%',
        left: '36.5%',
        width: '30.9%',
        height: '30.9%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        left: '49%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 300
    {
      id: 2,
      name: 'Suite 300',
      component: markRaw(Suite300Hotspot),
      techInfo: {
        location: 'Suite 300',
        icon: '',
        devices: ['VAV 3-1', 'VAV 3-2', 'VAV 3-3', 'VAV 3-4', 'VAV 3-5'],
      },
      style: {
        position: 'absolute',
        top: '36.6%',
        left: '63.2%',
        width: '40.1%',
        height: '40.1%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '35.5%',
        left: '63.5%',
        width: '39.5%',
        height: '39.5%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '55%',
        left: '81%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 312
    {
      id: 3,
      name: 'Suite 312',
      component: markRaw(Suite312Hotspot),
      techInfo: {
        location: 'Suite 312',
        icon: '',
        devices: [
          'VAV 3-43',
          'VAV 3-44',
          'VAV 3-45',
          'VAV 3-46',
          'VAV 3-47',
          'VAV 3-48',
          'VAV 3-49',
          'VAV 3-50',
          'VAV 3-51',
        ],
      },
      style: {
        position: 'absolute',
        top: '36.55%',
        left: '27.3%',
        width: '45.65%',
        height: '45.65%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '35.5%',
        left: '28%',
        width: '44.5%',
        height: '44.5%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '65%',
        left: '48%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 319A
    {
      id: 4,
      name: 'Suite 319A',
      component: markRaw(Suite319AHotspot),
      techInfo: {
        location: 'Suite 319A',
        icon: '',
        devices: ['VAV 3-32', 'VAV 3-33', 'VAV 3-34', 'VAV 3-35'],
      },
      style: {
        position: 'absolute',
        top: '36.6%',
        left: '10.3%',
        width: '40.1%',
        height: '40.1%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '35.5%',
        left: '10.7%',
        width: '39.4%',
        height: '39.4%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '55%',
        left: '21%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 319B
    {
      id: 5,
      name: 'Suite 319B',
      component: markRaw(Suite319BHotspot),
      techInfo: {
        location: 'Suite 319B',
        icon: '',
        devices: ['VAV 3-30', 'VAV 3-31'],
      },
      style: {
        position: 'absolute',
        top: '36.6%',
        left: '-9.75%',
        width: '40.1%',
        height: '40.1%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '35.4%',
        left: '-9.28%',
        width: '39.3%',
        height: '39.3%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '55%',
        left: '8%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 3-13
    {
      id: 6,
      name: 'VAV 3-13',
      controlId: 2339,
      isFloorVAV: true,
      component: markRaw(VAV313),
      techInfo: {
        location: 'VAV 3-13',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '11.6%',
        right: '51.35%',
        width: '45%',
        height: '45%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '32.8%',
        right: '75%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 3-16
    {
      id: 7,
      name: 'VAV 3-16',
      controlId: 2342,
      isFloorVAV: true,
      component: markRaw(VAV316),
      techInfo: {
        location: 'VAV 3-16',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '33.3%',
        right: '51.6%',
        width: '10.85%',
        height: '10.85%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '37.5%',
        right: '57%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 3-14
    {
      id: 8,
      name: 'VAV 3-14',
      controlId: 2340,
      isFloorVAV: true,
      component: markRaw(VAV314),
      techInfo: {
        location: 'VAV 3-14',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '11.9%',
        right: '3.55%',
        width: '44.5%',
        height: '44.5%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '32.8%',
        right: '24%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 3-15
    {
      id: 9,
      name: 'VAV 3-15',
      controlId: 2341,
      isFloorVAV: true,
      component: markRaw(VAV315),
      techInfo: {
        location: 'VAV 3-15',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '28.8%',
        right: '37.1%',
        width: '29.1%',
        height: '29.1%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '45%',
        right: '49.5%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
  ],
  childrenSuites: [
    // Suite 315
    {
      id: 0,
      name: 'Suite 315',
      disabled: false,
      styles: {
        width: '85%',
        transform: 'translate(25%, 5%)',
        height: 'auto',
        top: '0',
      },
      component: markRaw(Suite315),
      controlsList: [...Suite315ControlsList],
      activeTooltip: null,
    },
    // Suite 310
    {
      id: 1,
      name: 'Suite 310',
      disabled: false,
      styles: {
        width: '85%',
        transform: 'translate(25%, 5%)',
        height: 'auto',
        top: '0',
      },
      component: markRaw(Suite310),
      controlsList: [...Suite310ControlsList],
      activeTooltip: null,
    },
    // Suite 300
    {
      id: 2,
      name: 'Suite 300',
      disabled: false,
      styles: {
        width: '70%',
        transform: 'translate(40%, 5%)',
        height: 'auto',
        top: '0',
      },
      component: markRaw(Suite300),
      controlsList: [...Suite300ControlsList],
      activeTooltip: null,
    },
    // Suite 312
    {
      id: 3,
      name: 'Suite 312',
      disabled: false,
      styles: {
        width: '80%',
        transform: 'translate(40%, 20%)',
        height: 'auto',
      },
      component: markRaw(Suite312),
      controlsList: [...Suite312ControlsList],
      activeTooltip: null,
    },
    // Suite 319A
    {
      id: 4,
      name: 'Suite 319A',
      disabled: false,
      styles: {
        width: '70%',
        transform: 'translate(45%, 8%)',
        height: 'auto',
      },
      component: markRaw(Suite319A),
      controlsList: [...Suite319AControlsList],
      activeTooltip: null,
    },
    // Suite 319B
    {
      id: 5,
      name: 'Suite 319B',
      disabled: false,
      styles: {
        width: '35%',
        transform: 'translate(150%, 5%)',
        height: 'auto',
      },
      component: markRaw(Suite319B),
      controlsList: [...Suite319BControlsList],
      activeTooltip: null,
    },
  ],
  activeTooltip: null,
};

export default thirdFloor;
