<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <section>
    <Form ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="onSubmit">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
        />
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :autofocus="true"
                :rules="[() => !!site.name || 'Name is required']"
                v-model="site.name"
                label="Name"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col class="d-flex justify-start">
              <v-radio-group
                v-model="site.isExcludedFromAI"
                color="primary"
                inline
              >
                <template v-slot:label>
                  <span
                    >Is this site <strong>Excluded</strong> from AI
                    Analysis?</span
                  >
                </template>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-start">
              <v-radio-group
                v-model="site.hasDigitalTwin"
                color="primary"
                inline
              >
                <template v-slot:label>
                  <span>Does this site have a Digital Twin?</span>
                </template>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="v-input__slot">
                <label-component
                  label="Address Lookup"
                  :labelBold="false"
                  :valueIndent="false"
                >
                  <input
                    label="Lookup"
                    v-model="site.address"
                    ref="autocomplete"
                    type="text"
                    placeholder="type all or part of a place"
                    class="v-text-field search-location"
                  />
                </label-component>
              </div>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="2">
              <v-text-field v-model="site.streetNumber" label="Number" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="site.address"
                label="Address Lookup"
                hint="Type all or part of a place"
                variant="outlined"
                ref="autocomplete"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="site.country"
                :rules="[() => !!site.country || 'Country is required']"
                label="Country"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="4">
              <v-text-field
                v-model="site.city"
                :rules="[() => !!site.city || 'City is required']"
                label="City or District"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="site.state"
                :rules="[() => !!site.state || 'State is required']"
                label="Region, State, or Territory"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="site.postalCode"
                :rules="[() => !!site.postalCode || 'Postal Code is required']"
                label="Postal Code"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="3">
              <v-text-field
                v-model="siteCoordinatesLat"
                :rules="[() => !!site.latitude || 'Latitude is required']"
                label="Latitude"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="siteCoordinatesLng"
                :rules="[() => !!site.longitude || 'Longitude is required']"
                label="Longitude"
              />
            </v-col>

            <v-col cols="3">
              <v-select
                v-model="site.timeZoneId"
                :items="timezones"
                :custom-filter="timezoneFilter"
                :rules="[() => !!site.timeZoneId || 'Time Zone is required']"
                item-title="name"
                item-value="id"
                label="Time Zone"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :items="weatherStations"
                item-title="name"
                item-value="id"
                v-model="site.weatherStationId"
                label="Weather Station"
                required
                variant="solo"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="3">
              <v-select
                v-model="site.utilityCompanyIds"
                :rules="[
                  () => !!site.utilityCompanyIds || 'Utility is required',
                ]"
                :items="utilityCompanies"
                :item-title="(item) => item.name || ''"
                :item-value="(item) => item.id"
                label="Utility"
                multiple
              />
            </v-col>

            <v-col cols="3">
              <v-select
                :items="marketContexts"
                item-title="name"
                item-value="id"
                v-model="site.marketContextId"
                label="Market Context"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.kwhCost"
                :rules="[() => !!site.kwhCost || 'Cost per kWh is required']"
                label="Cost per kWh ($)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.thermCost"
                label="Cost per Therm ($)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="site.waterCost" label="Cost per Gal ($)" />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.cO2Threshold"
                label="CO2 Threshold"
                type="number"
                min="800"
                max="1500"
                step="1"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="4">
              <v-select
                :rules="[
                  () => !!site.siteTypeId || 'Building Type is required',
                ]"
                :items="siteTypes"
                item-title="name"
                item-value="id"
                v-model="site.siteTypeId"
                label="Building Type"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.squareFootage"
                :rules="[() => !!site.squareFootage || 'Sq. Ft. is required']"
                label="Sq. Ft."
                type="number"
                min="0"
                max="50000"
                step="500"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                v-model="site.floors"
                :rules="[() => !!site.floors || 'Floors is required']"
                label="Floors"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.occupants"
                :rules="[() => !!site.occupants || 'Occupants is required']"
                label="Occupants"
                type="number"
                min="0"
                max="10000"
                step="50"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                :rules="[() => !!site.year || 'Year Built is required']"
                :items="years"
                v-model="site.year"
                label="Year Built"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-label>Open Time:</v-label>
              <br />
              <v-time-picker
                hide-header
                v-model="selectedSiteStartStopTime.startStopTime.siteStartTime"
                format="ampm"
                width="15vw"
                landscape
                color="greenGraph"
                @input="handleTimeChange"
                scrollable
                :disabled="!selectedSiteStartStopTime.isOpen"
              />
            </v-col>
            <v-col cols="4">
              <v-label>Closing Time:</v-label>
              <br />
              <v-time-picker
                hide-header
                v-model="selectedSiteStartStopTime.startStopTime.siteEndTime"
                format="ampm"
                width="15vw"
                landscape
                color="heatColor"
                :disabled="!selectedSiteStartStopTime.isOpen"
                @input="handleTimeChange"
                scrollable
              />
            </v-col>
            <v-col cols="2">
              <v-switch
                :label="openClosedLabel"
                v-model="selectedSiteStartStopTime.isOpen"
                color="success"
                @update:modelValue="handleOpenClose"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedSiteStartStopTime"
                :rules="[() => !!selectedSiteStartStopTime || 'Select a day']"
                :items="siteStartStopTimes"
                :item-title="(item) => item.dayOfWeekName || ''"
                :return-object="true"
                label="Select days"
                @change-value="toggleOpenCloseLabel"
              />
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="8">
              <div class="text-h6">Contacts (optional)</div>
            </v-col>
            <v-col cols="2">
              <v-btn color="primary" @click="addContact">Add Contact</v-btn>
            </v-col>
          </v-row>
          <v-row
            justify="space-between"
            v-for="(contact, index) in site.updateSiteContactResourceModels"
            :key="contact.contactMethodId"
          >
            <v-col>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="contact.siteContactName"
                    :rules="[
                      () =>
                        !!contact.siteContactName || 'Contact Name is required',
                    ]"
                    label="Contact Name"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="contact.siteContactTitle"
                    :rules="[
                      () =>
                        !!contact.siteContactTitle ||
                        'Contact Title is required',
                    ]"
                    label="Contact Title"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="loading === false"
                    :items="siteContactNotificationFrequencies"
                    v-model="contact.siteContactNotificationFrequencyId"
                    label="Contact Frequency"
                    item-title="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn color="primary" @click="removeContact(index)">
                    Remove Contact
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(
                  contactMethod, methodIndex
                ) in contact.updateSiteContactMethodResourceModels"
                :key="contactMethod.contactMethodId"
              >
                <v-col cols="4">
                  <v-select
                    :items="contactTypes"
                    v-model="contactMethod.contactMethodId"
                    label="Contact Type"
                    item-title="text"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="contactMethod.contactMethodId !== 2"
                    v-model="contactMethod.siteContactMethodValue"
                    :rules="[
                      () =>
                        !!contactMethod.siteContactMethodValue ||
                        'Number is required',
                    ]"
                    label="Number"
                    masked
                  />

                  <v-text-field
                    v-if="contactMethod.contactMethodId === 2"
                    v-model="contactMethod.siteContactMethodValue"
                    :rules="[
                      () =>
                        !!contactMethod.siteContactMethodValue ||
                        'Email is required',
                      (email) =>
                        /.+@.+\..+/.test(email) || 'E-mail must be valid',
                    ]"
                    label="Email"
                  />
                </v-col>
                <v-col cols="2">
                  <v-switch
                    v-model="contactMethod.isPreferred"
                    :label="`Preferred`"
                    @update:modelValue="
                      togglePrefer(methodIndex, contactMethod, contact)
                    "
                  />
                </v-col>
                <v-col cols="2" v-if="methodIndex === 0">
                  <v-btn
                    color="primary"
                    @click="addContactMethod(contact)"
                    v-bind:disabled="
                      contact.updateSiteContactMethodResourceModels.length >= 2
                    "
                    >Add Method</v-btn
                  >
                </v-col>
                <v-col cols="2" v-if="methodIndex > 0">
                  <v-btn
                    color="primary"
                    @click="removeContactMethod(contact, methodIndex)"
                  >
                    Remove Method
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider class="mt-6" />
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="8">
              <div class="text-h6">Site Image (optional)</div>
            </v-col>
            <v-col cols="8">
              <v-file-input
                :rules="rules"
                small-chips
                label="Select Image For Site (.jpg, .jpeg, .png, .bmp)"
                accept=".jpg, .jpeg, .png, .bmp"
                prepend-icon="mdi-camera"
                v-model="siteImageFile"
                @change="showFilePreview($event)"
                class="pr-4"
              />
              <p class="text-caption font-weight-light">
                *Images are cropped at 345x200px. Image size limit is 50 kB.
              </p>
            </v-col>
          </v-row>
          <v-img
            v-if="site.siteImageDataUrl"
            :src="site?.siteImageDataUrl"
            max-height="200"
            max-width="345"
          />
          <p
            class="text-caption font-weight-light"
            v-if="imageSize"
            v-bind:style="imageSize > 50 ? 'color: red' : 'color: black'"
          >
            Current image size: {{ imageSize }} kb
          </p>
          <v-btn
            title="download image"
            color="primary"
            class="mr-5"
            @click="downloadImage"
            download
          >
            Download Image
          </v-btn>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                :disabled="invalid"
                type="submit"
                color="primary"
                class="mr-5"
              >
                Save
              </v-btn>
              <v-btn :to="`/sites`">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </Form>
  </section>
</template>

<style>
.search-location {
  display: block;
  width: 80vw;
  outline: none;
  text-align: left;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.42);
  border-width: 0 0 thin 0;
}
</style>
<script>
import { Loader } from 'google-maps';
import { Form, defineRule } from 'vee-validate';
import { mask } from 'vue-the-mask';

import { mapActions } from 'vuex';
import api from '../_api';

import Label from '@/modules/controls/_components/Label.vue';

import { createSiteStartStopTimeResourceModel } from '../_resourceModels/createSiteStartStopTimeResourceModel';
import { createStartStopTimeResourceModel } from '../_resourceModels/createStartStopTimeResourceModel';
import mapPlaceComponents from '../_resourceModels/mapAddress';
import { updateSiteContactMethodResourceModel } from '../_resourceModels/updateSiteContactMethodResourceModel';
import { updateSiteContactResourceModel } from '../_resourceModels/updateSiteContactResourceModel';
import { updateSiteResourceModel } from '../_resourceModels/updateSiteResourceModel';

export default {
  name: 'EditSite',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'label-component': Label,
  },

  directives: {
    mask,
  },

  data() {
    return {
      rules: [
        (value) =>
          !value || value.size < 3000000 || 'Image size must be less than 3MB.',
      ],
      loading: true,
      valid: true,
      server_errors: [],
      emailMethod: false,

      states: [],

      weatherStations: [],
      timezones: [],
      utilityCompanies: [],
      marketContexts: [],
      siteTypes: [],
      siteStartStopTimes: [],

      contactTypes: [
        { value: 3, text: 'Text' },
        { value: 2, text: 'Email' },
      ],

      years: this.getYears(),
      pickerLabels: { start: 'Open Time', end: 'Closing Time' },
      google: null,
      autocompleted: null,
      site: new updateSiteResourceModel(),
      siteName: null,
      selectedSiteStartStopTime: { startStopTime: {} },
      presetStartStopTimes: [],
      openClosedLabel: 'Closed',
      dayOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      siteContactNotificationFrequencies: null,
      siteImageFile: null,
      imageSize: null,
    };
  },

  mounted() {
    if (this.google === null) {
      const loader = new Loader('AIzaSyCda-Sl20-2xJ-k7nCtmRiOTXWRgfS6P60', {
        libraries: ['places'],
      });
      loader
        .load()
        .then((gData) => {
          const inputField = this.$refs.autocomplete.$el.querySelector('input');
          inputField.placeholder = '';
          this.google = { ...gData };
          this.autocompleted = new this.google.maps.places.Autocomplete(
            inputField,
            { types: ['geocode'] }
          );

          this.autocompleted.addListener('place_changed', () => {
            const place = this.autocompleted.getPlace();
            const addressDetail = mapPlaceComponents(place);

            this.site.latitude = addressDetail.lat;
            this.site.longitude = addressDetail.lng;
            this.site.address = addressDetail.address;
            this.site.city = addressDetail.city;
            this.site.country = addressDetail.country;
            this.site.state = addressDetail.state;
            this.site.postalCode = addressDetail.postalCode;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },

  async created() {
    this.getWeatherstations();
    this.getTimezones();
    this.getUtilityCompanies();
    this.getSiteTypes();
    this.getMarketContexts();
    this.getStates();
    this.getSiteContactFrequencies();
    this.getSite();
    this.getSites();
    this.registerCustomValidators();
    this.loading = false;
  },

  computed: {
    siteCoordinatesLat: {
      get() {
        return Number(this.site.latitude).toFixed(5);
      },
      set(value) {
        this.site.latitude = value;
      },
    },
    siteCoordinatesLng: {
      get() {
        return Number(this.site.longitude).toFixed(5);
      },
      set(value) {
        this.site.longitude = value;
      },
    },
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
    }),

    toggleOpenCloseLabel() {
      this.openClosedLabel = this.selectedSiteStartStopTime.isOpen
        ? 'Open'
        : 'Closed';
    },

    handleOpenClose() {
      this.toggleOpenCloseLabel();

      if (this.selectedSiteStartStopTime.dayOfWeekName === 'All') {
        this.resetAllSiteStartStopTimes(this.selectedSiteStartStopTime);
      }
    },

    resetAllSiteStartStopTimes(selectedSiteStartStopTime) {
      let resetTimes = this.siteStartStopTimes.map((s, i) => {
        return this.newStartStopTime(
          i - 1,
          s.dayOfWeekName,
          selectedSiteStartStopTime.isOpen,
          JSON.parse(JSON.stringify(selectedSiteStartStopTime.startStopTime))
        );
      });
      this.siteStartStopTimes = resetTimes;
    },

    handleTimeChange() {
      if (this.selectedSiteStartStopTime.dayOfWeekName === 'All') {
        this.resetAllSiteStartStopTimes(this.selectedSiteStartStopTime);
      }
    },

    getStates() {
      this.states = api.getStates();
    },

    async getSites() {
      try {
        let response = await api.getSites();
        response.forEach((site) => {
          site.longitude = site.longitude.toFixed(5);
        });
        this.sites = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getWeatherstations() {
      try {
        let response = await api.getWeatherStations();
        this.weatherStations = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getTimezones() {
      try {
        let response = await api.getTimezones();
        this.timezones = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getUtilityCompanies() {
      try {
        let response = await api.getUtilityCompanies();
        this.utilityCompanies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getMarketContexts() {
      try {
        let response = await api.getMarketContexts();
        this.marketContexts = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteTypes() {
      try {
        let response = await api.getSiteTypes();
        this.siteTypes = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteContactFrequencies() {
      try {
        let response = await api.getSiteContactNotificationFrequencies();
        this.siteContactNotificationFrequencies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSite() {
      try {
        let response = await api.getSiteById(this.$route.params.id);
        this.site = response;
        this.siteName = response.name;
        this.siteStartStopTimes = this.mapSiteStartStopTime(
          this.site.siteStartStopTime
        );
        this.selectedSiteStartStopTime = this.siteStartStopTimes[1];
        this.imageSize = this.site.imageSize;
        this.handleOpenClose();
        this.setDefaultSite(this.site);
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
      this.loading = false;
    },

    mapSiteStartStopTime(sssTimes) {
      let mappedTimes = [];

      for (let i = 0; i < this.dayOfWeek.length; i++) {
        let existingSssTime = sssTimes.find((t) => t.dayOfWeekId === i);
        if (existingSssTime) {
          existingSssTime.isOpen = true;
          mappedTimes.push(existingSssTime);
        } else {
          let newSssTime = this.newStartStopTime(i, this.dayOfWeek[i], false);
          mappedTimes.push(newSssTime);
        }
      }

      //add the 'all' pick
      mappedTimes.unshift(this.newStartStopTime(mappedTimes.length + 1, 'All'));

      return mappedTimes;
    },

    newStartStopTime(
      index,
      dayOfWeekName,
      isOpen,
      startStopTime = new createStartStopTimeResourceModel(
        '07:00:00',
        '19:00:00'
      )
    ) {
      let newSssTime = new createSiteStartStopTimeResourceModel(
        this.$route.params.id,
        index,
        dayOfWeekName,
        isOpen
      );
      // let startStopTime = new createStartStopTimeResourceModel(
      //   "07:00:00",
      //   "19:00:00"
      // );
      startStopTime.id = 0; //TODO: make an update resource model
      newSssTime.startStopTime = startStopTime;
      newSssTime.id = 0;
      return newSssTime;
    },

    async getSiteStartStopTimes() {
      try {
        let response = await api.getSiteStartStopTimes(this.$route.params.id);
        this.siteStartStopTimes = response;
        if (response.length > 0) {
          this.selectedSiteStartStopTime = response[0];
        }
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        this.site.siteStartStopTime = this.mapUpdatedStartStopTime(
          this.siteStartStopTimes
        );
        await api.updateSite(this.site).then(() => {
          if (this.siteImageFile) {
            api
              .updateSiteImage(this.site.siteId, this.siteImageFile)
              .then(() => {
                this.loading = false;
                this.$toast.show('Site updated.', null, 'success');
                this.$router.push({ name: 'SitesRoute' });
              });
          } else {
            this.loading = false;
            this.$toast.show('Site updated.', null, 'success');
            this.$router.push({ name: 'SitesRoute' });
          }
        });
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }

      this.loading = false;
    },

    mapUpdatedStartStopTime(sSSTimes) {
      var openSSSTimes = sSSTimes.filter(
        (t) => t.isOpen === true && t.dayOfWeekName !== 'All'
      );
      return openSSSTimes;
    },

    getYears() {
      let thisYear = new Date().getFullYear(),
        yearList = [];
      let startYear = 1900;
      while (startYear <= thisYear) {
        yearList.push(thisYear--);
      }
      return yearList;
    },

    addContact() {
      let newContact = new updateSiteContactResourceModel();
      this.site.updateSiteContactResourceModels.push(newContact);
    },

    removeContact(index) {
      this.site.updateSiteContactResourceModels.splice(index, 1);
    },

    addContactMethod(contact) {
      let newContactMethod = new updateSiteContactMethodResourceModel(
        null,
        null,
        false
      );
      contact.updateSiteContactMethodResourceModels.push(newContactMethod);
    },

    removeContactMethod(contact, index) {
      contact.updateSiteContactMethodResourceModels.splice(index, 1);
    },

    togglePrefer(index, contactMethod, contact) {
      let value = contactMethod.isPreferred;

      if (
        value === false ||
        contact.updateSiteContactMethodResourceModels.length === 1
      ) {
        return;
      } else {
        for (
          let i = 0;
          i < contact.updateSiteContactMethodResourceModels.length;
          i++
        ) {
          if (i === index) {
            continue;
          } else {
            contact.updateSiteContactMethodResourceModels[
              i
            ].isPreferred = false;
          }
        }
      }
    },

    showFilePreview(e) {
      this.imageSize = e.size / 1000;
      this.site.siteImageDataUrl = URL.createObjectURL(e);
    },

    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors
          ? error.response.data.errors
          : [error]
        : [error];
    },

    timezoneFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.sites) {
          let existingNames = this.sites.map((a) => a.name);
          //filter site name out of list if in edit
          existingNames = existingNames.filter((n) => {
            return n !== this.siteName;
          });
          valid = existingNames.indexOf(value) === -1;
        }
        if (!valid) {
          return `${field} is already used by another site.  Enter a different name.`;
        }
        return valid;
      });
    },

    downloadImage() {
      try {
        let fileType = this.site.siteImageDataUrl.split(';')[0].split('/')[1];
        let linkSource = this.site.siteImageDataUrl;
        let fileLink = document.createElement('a');
        fileLink.href = linkSource;
        fileLink.setAttribute(
          'download',
          this.site.name + ' - Image.' + fileType
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        return Promise.reject(error);
      }
      this.loading = false;
    },
  },
};
</script>
