import { markRaw } from 'vue';

import VAV213Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV213Drawing.vue';
import VAV214Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV214Drawing.vue';
import VAV215Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV215Drawing.vue';
import VAV216Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV216Drawing.vue';
import VAV217Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV217Drawing.vue';
import VAV219Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV219Drawing.vue';
import VAV220Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV220Drawing.vue';
import VAV221Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fpch/VAV221Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 2-13',
    controlId: 2221,
    component: markRaw(VAV213Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-13',
      },
    },
    style: {
      position: 'absolute',
      top: '9%',
      left: '17.4%',
      width: '29%',
      height: '29%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '29%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 2-14',
    controlId: 2221,
    disabled: true,
    component: markRaw(VAV214Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-14',
      },
    },
    style: {
      position: 'absolute',
      top: '9.2%',
      left: '39.7%',
      width: '28.8%',
      height: '28.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '51%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 2-15',
    controlId: 2223,
    component: markRaw(VAV215Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-15',
      },
    },
    style: {
      position: 'absolute',
      top: '9.2%',
      left: '66.8%',
      width: '28.95%',
      height: '28.95%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '78%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 2-16',
    controlId: 2224,
    component: markRaw(VAV216Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-16',
      },
    },
    style: {
      position: 'absolute',
      top: '9.2%',
      left: '81.5%',
      width: '43.1%',
      height: '43.1%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '100%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 2-17',
    controlId: 2225,
    component: markRaw(VAV217Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-17',
      },
    },
    style: {
      position: 'absolute',
      top: '52.38%',
      left: '89.7%',
      width: '24%',
      height: '24%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '64%',
      left: '99%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 5,
    name: 'VAV 2-19',
    controlId: 2227,
    component: markRaw(VAV219Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-19',
      },
    },
    style: {
      position: 'absolute',
      top: '31.4%',
      left: '32.08%',
      width: '78.3%',
      height: '78.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '45%',
      left: '70%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 6,
    name: 'VAV 2-20',
    controlId: 2228,
    component: markRaw(VAV220Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-20',
      },
    },
    style: {
      position: 'absolute',
      top: '49.7%',
      left: '30%',
      width: '51.8%',
      height: '51.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '73%',
      left: '52.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 7,
    name: 'VAV 2-21',
    controlId: 2229,
    component: markRaw(VAV221Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-21',
      },
    },
    style: {
      position: 'absolute',
      top: '37.9%',
      left: '-1.5%',
      width: '63.8%',
      height: '63.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '65%',
      left: '24.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
