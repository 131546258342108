<template>
  <v-card class="monitors-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ label }}
      </v-app-bar-title>
      <v-row>
        <v-col>
          <v-autocomplete
            class="mt-6 mr-5"
            :items="sitesForAginovaMonitors"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            return-object
            variant="outlined"
            density="compact"
            @update:modelValue="setDefaultSiteFromMonitorsDomain"
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            bg-color="white"
            single-line
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AginovaMonitorsBar',

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
    };
  },

  async created() {
    await this.getSitesForAginova();
    this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
  },

  computed: {
    ...mapGetters({
      sitesForAginovaMonitors:
        'MonitorsStore/AginovaMonitorsStore/sitesForAginovaMonitors',
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Monitor';
    },

    userCanDelete() {
      return this.$can('delete', 'meters');
    },
    userCanUpdate() {
      return this.$can('update', 'meters');
    },

    label() {
      return this.$route.meta.label || 'Aginova Monitors';
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapActions({
      getSitesForAginova:
        'MonitorsStore/AginovaMonitorsStore/getSitesForAginova',
      getAginovaMonitorsBySiteId:
        'MonitorsStore/AginovaMonitorsStore/getAginovaMonitorsBySiteId',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setAginovaMonitorsTerm:
        'MonitorsStore/AginovaMonitorsStore/AGINOVA_MONITORS_SEARCH_TERM',
    }),

    setDefaultSiteFromMonitorsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesForAginovaMonitors[0];

      this.getAginovaMonitorsBySiteId({
        selectedSite: this.selectedSite,
      }).then(() => {
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleSearchInput(value) {
      this.setAginovaMonitorsTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setAginovaMonitorsTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.monitors-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
