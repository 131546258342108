import { markRaw } from 'vue';

import VAV11Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite109/VAV11Drawing.vue';
import VAV12Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite109/VAV12Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 1-1',
    controlId: 2147,
    component: markRaw(VAV11Hotspot),
    techInfo: {
      device: {
        label: 'VAV 1-1',
      },
    },
    style: {
      position: 'absolute',
      top: '-4.1%',
      right: '27.9%',
      width: '62.9%',
      height: '62.9%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '30%',
      left: '39%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 1-2',
    controlId: 2148,
    component: markRaw(VAV12Hotspot),
    techInfo: {
      location: '2',
      icon: '',
      device: {
        label: 'VAV 1-2',
      },
    },
    style: {
      position: 'absolute',
      top: '34.8%',
      left: '9.2%',
      width: '62.8%',
      height: '62.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '78%',
      left: '36.7%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
