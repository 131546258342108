<template>
  <Form ref="obs_range">
    <v-row>
      <v-col cols="auto" md="6" sm="12">
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="innerValue.startDateTime"
              :model-value="
                dateTimeToUSFormatFactory(innerValue.startDateTime, dateOptions)
              "
              :label="innerLabels.start"
              prepend-icon="event"
              readonly
              variant="outlined"
              density="compact"
              color="primary"
              :rules="[isDateRangeValid]"
              :error-messages="errors"
              name="start date"
              ref="startPicker"
              v-bind="props"
            />
          </template>
          <v-date-picker
            v-model="innerValue.startDateTime"
            color="primary"
            name="start date"
          />
          <v-btn color="primary" @click="startMenu = false"> Close </v-btn>
        </v-menu>
      </v-col>

      <v-col cols="auto" md="6" sm="12">
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="innerValue.endDateTime"
              :model-value="
                dateTimeToUSFormatFactory(innerValue.endDateTime, dateOptions)
              "
              :label="innerLabels.end"
              prepend-icon="event"
              readonly
              variant="outlined"
              density="compact"
              color="primary"
              :rules="[isDateRangeValid]"
              name="end date"
              ref="endPicker"
              v-bind="props"
            />
          </template>
          <v-date-picker
            v-model="innerValue.endDateTime"
            color="primary"
            name="end date"
          />
          <v-btn color="primary" @click="endMenu = false"> Close </v-btn>
        </v-menu>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { Form, defineRule } from 'vee-validate';
import { DateTime } from 'luxon';
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },

  props: {
    value: {
      type: [Object, String],
      default: () => {
        return {
          startDateTime: DateTime.local(),
          endDateTime: DateTime.local(),
        };
      },
    },
    dateFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    debounce: {
      type: Number,
      default: 100,
    },
    labels: {
      type: [Object, String],
      default: () => {
        return {
          start: 'Start Date',
          end: 'End Date',
        };
      },
    },
  },

  data() {
    return {
      innerValue: {
        startDateTime: '',
        endDateTime: '',
      },
      startMenu: false,
      endMenu: false,
      innerDateFormat: '',
      innerLabels: {},
      pendingChange: false,
      dateOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      errors: [],
    };
  },

  created() {
    this.registerCustomValidators();
    if (this.value) {
      this.innerValue = this.value;
    }

    if (this.labels) {
      this.innerLabels = this.labels;
    }

    if (this.dateFormat) {
      this.innerDateFormat = this.dateFormat;
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', this.formatDates(newVal));
    },

    value(newVal) {
      if (newVal) {
        this.innerValue = this.formatDates(newVal);
      }
    },

    dateFormat(newVal) {
      if (newVal) {
        this.innerDateFormat = newVal;
      }
    },

    labels(newVal) {
      if (newVal) {
        this.innerLabels = newVal;
      }
    },

    pendingChange(newVal) {
      if (newVal === false) {
        this.$emit('change', this.innerValue);
      }
    },
  },

  methods: {
    dateTimeToUSFormatFactory,
    debounceHandler(func, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
      };
    },
    // changeHandler(propName, e) {
    //   const adapter = useDate();
    //   this.innerValue[propName] = adapter.date(e.$d);
    //   // this.innerValue[propName] = DateTime.toISODate();
    //   console.log('evetn', e);
    //   console.log('changeHandler', propName);
    //   this.pendingChange = true;
    // },

    defaultDates() {
      this.innerValue = {
        startDateTime: DateTime.fromFormat(
          DateTime.local(),
          this.innerDateFormat
        ),
        endDateTime: DateTime.fromFormat(
          DateTime.local(),
          this.innerDateFormat
        ),
      };
    },

    formatDates(input) {
      if (input.startDateTime && input.endDateTime) {
        const sdt = DateTime.fromISO(input.startDateTime).toFormat(
          this.innerDateFormat
        );
        const edt = DateTime.fromISO(input.endDateTime).toFormat(
          this.innerDateFormat
        );
        input.startDateTime = sdt;
        input.endDateTime = edt;
      }

      return input;
    },

    registerCustomValidators() {
      defineRule('valid_date_range', () => {
        const dr_valid =
          new Date(this.innerValue.startDateTime) <
          new Date(this.innerValue.endDateTime);
        if (dr_valid === true && this.$refs.obs_range) {
          this.$refs.obs_range.reset();
          return true;
        }
        return 'Start Date must be less than End Date.';
      });
    },

    isDateRangeValid() {
      if (this.innerValue.startDateTime > this.innerValue.endDateTime) {
        this.errors.push('Start Date must be less than End Date.');
        return false;
      } else {
        this.errors = [];
        return true;
      }
    },
  },
};
</script>
