<template>
  <div class="mt-n3 px-2">
    <!-- <v-card class="pl-3"> -->
    <v-row v-if="aginovaMonitorError || isLoading">
      <v-col>
        <ProgressLinearLoaderVue v-if="isLoading" :isLoading="isLoading" />
        <BannerAlertVue v-if="aginovaMonitorError" :message="bannerMessage" />
      </v-col>
    </v-row>
    <v-row v-if="!aginovaMonitorError && !isLoading">
      <v-col cols="5">
        <v-card class="pa-4">
          <!-- Aginova Monitor Details -->
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="AginovaMonitorStatus.deviceName"
              disabled
              variant="outlined"
            >
              <template v-slot:label>
                <span class="text--disabled text-caption font-weight-bold">
                  Device Name
                </span>
              </template>
            </v-text-field>

            <v-text-field
              v-model="AginovaMonitorStatus.friendlyName"
              required
              :disabled="isSiteLocationEditMode || isSiteLocationAddMode"
              variant="outlined"
              :class="
                isSiteLocationEditMode || isSiteLocationAddMode
                  ? 'text--disabled'
                  : 'text--enabled'
              "
            >
              <template v-slot:label>
                <span
                  class="text-primary text-caption font-weight-bold"
                  :class="{
                    'text--disabled':
                      isSiteLocationEditMode || isSiteLocationAddMode,
                  }"
                >
                  Friendly Name
                </span>
              </template>
            </v-text-field>
            <!-- Site Location Add/Edit -->
            <SiteLocationCard
              :MonitorStatus="AginovaMonitorStatus"
              :isMonitorDirty="isAginovaMonitorDirty"
              :siteLocation="siteLocation"
              :isSiteLocationEditMode="isSiteLocationEditMode"
              :isSiteLocationAddMode="isSiteLocationAddMode"
              :siteLocationsForMonitor="siteLocationsForAginovaMonitor"
              @site-location-edit-mode="toggleEditMode"
              @site-location-add-mode="toggleAddMode"
              @cancel-site-location-edit-mode="handleCancelSiteLocationEditMode"
              @init="init"
              @update:siteLocationName="siteLocation.siteLocationName = $event"
            />
            <!-- Control Buttons -->
            <v-btn
              class="rounded-2 mr-2"
              color="primary"
              variant="flat"
              @click="handleSave"
              :disabled="!isAginovaMonitorDirty || isSiteLocationEditMode"
            >
              <div
                class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
              >
                Save
              </div>
            </v-btn>

            <v-btn
              class="rounded-2"
              color="warning"
              variant="flat"
              @click="handleCancelButtonClick"
              :disabled="isSiteLocationEditMode || isSiteLocationAddMode"
            >
              <div
                class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
              >
                {{ getCancelButtonText }}
              </div>
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
      <!-- Sensors List -->
      <v-col cols="7" v-if="sensorsList.length">
        <SensorsList :SensorsList="sensorsList" />
      </v-col>
      <v-col cols="8" v-else>
        <BannerAlertVue :message="sensorsListMessage" />
      </v-col>
    </v-row>
    <!-- </v-card> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert';

import SensorsList from '@/Core.Service.Domain/Monitors/Aginova/Components/SensorsList.vue';

import SiteLocationCard from '@/Core.Service.Domain/Monitors/Monitors.Common/Components/SiteLocationCard.vue';

import API from '@/Core.Service.Domain/Monitors/Aginova/API';

export default {
  components: {
    ProgressLinearLoaderVue,
    BannerAlertVue,
    SensorsList,
    SiteLocationCard,
  },

  computed: {
    ...mapGetters({
      getAginovaMonitorByDeviceId:
        'MonitorsStore/AginovaMonitorsStore/getAginovaMonitorByDeviceId',
      siteLocationsForAginovaMonitor:
        'MonitorsStore/AginovaMonitorsStore/siteLocationsForAginovaMonitor',
    }),

    bannerMessage() {
      return 'There was an error loading this monitor';
    },

    sensorsListMessage() {
      return 'There are no sensors for this monitor';
    },

    sensorsList() {
      return this.getAginovaMonitorByDeviceId.sensors;
    },

    isAginovaMonitorDirty() {
      return Object.values(this.isDirty).some((value) => value);
    },

    getCancelButtonText() {
      if (
        this.isAginovaMonitorDirty &&
        (!this.isSiteLocationEditMode || !this.isSiteLocationAddMode)
      ) {
        return 'Cancel';
      } else {
        return 'Go Back';
      }
    },
  },

  data: () => ({
    valid: true,
    aginovaMonitorError: false,
    isLoading: false,
    AginovaMonitorInfo: {},
    isSiteLocationEditMode: false,
    isSiteLocationAddMode: false,
    siteLocation: {},
    isDirty: {
      friendlyName: false,
      siteLocationName: false,
      siteLocationId: false,
    },
    AginovaMonitorStatus: {},
  }),

  watch: {
    'AginovaMonitorStatus.friendlyName': function (newVal) {
      this.isDirty.friendlyName =
        `${newVal}` !== `${this.AginovaMonitorInfo.friendlyName}`;
    },
    'AginovaMonitorStatus.siteLocationId': function (newVal) {
      this.isDirty.siteLocationId =
        `${newVal}` !== `${this.AginovaMonitorInfo.siteLocationId}`;
    },
    'siteLocation.siteLocationName': function (newVal) {
      this.isDirty.siteLocationName =
        `${newVal}` !== `${this.AginovaMonitorInfo.siteLocationName}`;
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      aginovaMonitorByDeviceId:
        'MonitorsStore/AginovaMonitorsStore/aginovaMonitorByDeviceId',
      getSiteLocationsForAginovaMonitor:
        'MonitorsStore/AginovaMonitorsStore/getSiteLocationsForAginovaMonitor',
    }),

    async init() {
      try {
        this.isLoading = true;
        const aginovaMonitor = await this.aginovaMonitorByDeviceId(
          this.$route.params.id
        );

        const { siteId } = aginovaMonitor;
        await this.getSiteLocationsForAginovaMonitor(siteId);

        this.AginovaMonitorInfo = {
          ...this.mapAginovaMonitorBySiteLocationId(aginovaMonitor),
        };

        this.cloneAginovaMonitorInfo();
        this.resetDirty();
      } catch (error) {
        this.aginovaMonitorError = true;
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },

    resetDirty() {
      this.isDirty.friendlyName = false;
      this.isDirty.siteLocationName = false;
      this.isDirty.siteLocationId = false;
    },

    handleCancel() {
      this.cloneAginovaMonitorInfo();
    },

    handleGoBack() {
      this.$router.push({ name: 'AginovaMonitorsList' });
    },

    async handleSave() {
      if (!this.isAginovaMonitorDirty) {
        return;
      }
      this.isLoading = true;
      try {
        await API.updateAginovaMonitor(this.AginovaMonitorStatus);
        this.init();
      } catch (error) {
        this.aginovaMonitorError = true;
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },

    cloneAginovaMonitorInfo() {
      this.AginovaMonitorStatus = { ...this.AginovaMonitorInfo };
      this.siteLocation.siteLocationName =
        this.AginovaMonitorInfo.siteLocationName;
    },

    mapAginovaMonitorBySiteLocationId(aginovaMonitor) {
      try {
        const siteLocation = this.siteLocationsForAginovaMonitor.filter(
          (siteLocation) => {
            return siteLocation.id === aginovaMonitor.siteLocationId;
          }
        );
        if (siteLocation.length) {
          this.siteLocation = siteLocation[0];
          aginovaMonitor.siteLocationName = siteLocation[0].siteLocationName;
        }

        return aginovaMonitor;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    handleCancelSiteLocationEditMode() {
      this.isSiteLocationEditMode = false;
      this.isSiteLocationAddMode = false;
      this.cloneAginovaMonitorInfo();
    },

    toggleEditMode(value) {
      this.isSiteLocationEditMode = value;
    },
    toggleAddMode(value) {
      this.isSiteLocationAddMode = value;
    },

    handleCancelButtonClick() {
      if (this.isAginovaMonitorDirty && !this.isSiteLocationEditMode) {
        this.handleCancel();
      } else {
        this.handleGoBack();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.text--enabled) {
  input {
    color: $primary !important;
  }
}
</style>
