import { markRaw } from 'vue';

import VAV31Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite300/VAV31Drawing.vue';
import VAV32Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite300/VAV32Drawing.vue';
import VAV33Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite300/VAV33Drawing.vue';
import VAV34Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite300/VAV34Drawing.vue';
import VAV35Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite300/VAV35Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 3-5',
    controlId: 2338,
    component: markRaw(VAV35Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-5',
      },
    },
    style: {
      position: 'absolute',
      top: '6.1%',
      left: '27.92%',
      width: '28.2%',
      height: '28.2%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '39%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 3-4',
    controlId: 2337,
    component: markRaw(VAV34Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-4',
      },
    },
    style: {
      position: 'absolute',
      top: '34.3%',
      left: '18.05%',
      width: '59.4%',
      height: '59.4%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '60%',
      left: '39%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 3-1',
    controlId: 2334,
    component: markRaw(VAV31Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-1',
      },
    },
    style: {
      position: 'absolute',
      top: '6.12%',
      left: '29.2%',
      width: '77.5%',
      height: '77.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '30%',
      left: '65%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 3-2',
    controlId: 2335,
    component: markRaw(VAV32Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-2',
      },
    },
    style: {
      position: 'absolute',
      top: '5.82%',
      left: '40.2%',
      width: '96%',
      height: '96%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '50%',
      left: '87.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 3-3',
    controlId: 2336,
    component: markRaw(VAV33Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-3',
      },
    },
    style: {
      position: 'absolute',
      top: '67.5%',
      left: '28%',
      width: '50.65%',
      height: '50.65%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '92%',
      left: '61%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
