<template>
  <section>
    <v-row>
      <v-col v-if="$can('create', 'sites')">
        <v-btn color="primary" to="/site/create">
          <v-icon start>mdi-plus-thick</v-icon>New Site
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-text-field
          v-model="search"
          label="Search"
          variant="solo"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- </v-row> -->
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
    ></v-progress-linear>
    <v-data-table
      :headers="headers"
      :items="sites"
      :hide-default-footer="sites.length <= 10"
      :search="search"
      :options="tableOptions"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.floors }}</td>
          <td>{{ item.occupants }}</td>
          <td>{{ item.squareFootage }}</td>
          <td>{{ item.kwhCost }}</td>
          <td>{{ item.latitude }}</td>
          <td>{{ item.longitude }}</td>
          <td>
            <v-btn :to="`/site/edit/${item.siteId}`" icon>
              <v-icon size="medium">mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </section>
</template>

<script>
export default {
  name: 'SiteIndex',
  async created() {
    let response = await window.axios.get('/v1/sites');
    response.data.data.forEach((site) => {
      site.latitude = Number(site.latitude).toFixed(5);
      site.longitude = Number(site.longitude).toFixed(5);
    });
    this.sites = response.data.data;
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      search: '',
      headers: [
        {
          title: 'Site Name',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'name',
          sortable: true,
        },
        {
          title: 'Floors',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'floors',
          sortable: true,
        },
        {
          title: 'Occupants',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'occupants',
          sortable: true,
        },
        {
          title: 'Square Footage',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'squareFootage',
          sortable: true,
        },
        {
          title: 'kWh Cost',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'kwhCost',
          sortable: true,
        },
        {
          title: 'Latitude',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'latitude',
          sortable: true,
        },
        {
          title: 'Longitude',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'longitude',
          sortable: true,
        },
        {
          title: 'Actions',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'id',
          sortable: false,
        },
      ],
      tableOptions: { itemsPerPage: 10 },
      sites: [],

      toggleView: 0,
    };
  },
};
</script>
