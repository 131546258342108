<template>
  <v-dialog
    :model-value="dialog"
    :width="cardWidth"
    @update:model-value="$emit('update:dialog', $event)"
  >
    <v-card height="100vh">
      <v-toolbar color="primary">
        <v-tabs
          v-model="localChildTab"
          align-tabs="start"
          slider-color="#378CDA"
          bg-color="primary"
        >
          <v-tab
            v-for="(suite, index) in floors[currentTab].childrenSuites"
            :key="index"
            :value="index"
            @click="handleTabClick(index)"
          >
            <span
              class="text-subtitle-1 text-white"
              :class="localChildTab === index ? 'font-weight-bold' : ''"
            >
              {{ suite.name }}
            </span>
          </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn
          class="mr-3"
          prepend-icon="mdi-backspace"
          @click="$emit('update:dialog', false)"
        >
          Close
        </v-btn>
      </v-toolbar>

      <v-tabs-window v-model="localChildTab">
        <v-tabs-window-item
          v-for="(suite, index) in floors[currentTab].childrenSuites"
          :key="index"
          :value="index"
        >
          <v-container fluid class="image-child-container">
            <v-row>
              <v-col cols="12">
                <ImageViewer :isDialog="true">
                  <template v-slot:Hotspot v-if="suite.controlsList">
                    <div v-for="(hotspot, i) in suite.controlsList" :key="i">
                      <component
                        :is="hotspot.component"
                        :color="hotspot.color"
                        :style="{
                          ...hotspot.style,
                        }"
                        :isHovered="suite.activeTooltip === hotspot.id"
                      />
                      <VAVChipComponent
                        :hotspot="hotspot"
                        :suite="suite"
                        :selectedVAVId="selectedVAVId"
                      />
                    </div>
                  </template>
                  <template v-slot:Image>
                    <component
                      :is="suite.component"
                      :style="suite.styles ? { ...suite.styles } : ''"
                    />
                  </template>
                </ImageViewer>

                <!-- Mini map -->
                <MiniMap>
                  <span class="text-primary font-weight-bold">
                    {{ floors[currentTab].name }}
                  </span>
                  <ImageViewer class="image-view" :isMiniMap="true">
                    <template v-slot:Hotspot>
                      <div
                        v-for="(hotspot, i) in floors[currentTab].hotspotsList"
                        :key="i"
                      >
                        <component
                          v-if="!hotspot.isFloorVAV"
                          :is="hotspot.component"
                          :color="
                            hoveredIndex === i || localChildTab === i
                              ? hotspot.color
                              : 'transparent'
                          "
                          :style="{
                            ...hotspot.style,
                            ...hotspot.miniMapStyle,
                          }"
                          @click="handleMiniMapClick(hotspot, i)"
                          @mouseenter="handleMouseEnter(i)"
                          @mouseleave="handleMouseLeave"
                        />
                      </div>
                    </template>
                    <template v-slot:Image>
                      <component
                        :is="floors[currentTab].component"
                        :style="{ width: '100%', height: '200px' }"
                      />
                    </template>
                  </ImageViewer>
                </MiniMap>

                <!-- VAV Card -->
                <VAVCard
                  class="vav-card"
                  v-if="
                    Object.keys(vav).length &&
                    selectedVAVId !== 0 &&
                    vav.controlName !== '' &&
                    selectedVAVId === vav.controlId &&
                    suite.controlsList &&
                    suite.controlsList.length
                  "
                  :VAVInfo="vav"
                  :borderColor="getBorderColorByHotspotName(suite)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useDisplay } from 'vuetify';

import ImageViewer from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageViewer.vue';
import MiniMap from '@/Core.Service.Domain/Controls/DigitalTwin/Components/MiniMap.vue';
import VAVCard from '@/Core.Service.Domain/Controls/DigitalTwin/Components/VAVCard.vue';

import VAVChipComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/VAVChipComponent.vue';

import {
  IVAV,
  IFloor,
  IHotspot,
} from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';

const { name } = useDisplay();

const cardWidth = computed(() => {
  switch (name.value) {
    case 'xs':
      return '95vw';
    case 'sm':
      return '95vw';
    case 'md':
      return '95vw';
    case 'lg':
      return '95vw';
    case 'xl':
      return '85vw';
    case 'xxl':
      return '60vw';
    default:
      return undefined;
  }
});

const props = defineProps({
  floors: {
    type: Array as () => Array<IFloor<Record<string, unknown>>>,
    required: true,
  },
  currentTab: {
    type: Number,
    required: true,
  },
  childTab: Number,
  vav: {
    type: Object as () => IVAV,
    required: true,
  },
  selectedVAVId: Number,
  dialog: Boolean,
});

const getBorderColorByHotspotName = (suite: {
  controlsList: IHotspot<Record<string, unknown>>[];
}) => {
  if (suite.controlsList) {
    return suite.controlsList.find(
      (hotspot: IHotspot<Record<string, unknown>>) =>
        hotspot.controlId !== undefined &&
        hotspot.controlId === props.selectedVAVId
    )?.color;
  }
};

watch(
  () => props.childTab,
  (newValue) => {
    localChildTab.value = newValue;
  }
);

const localChildTab = ref();

const emits = defineEmits(['update:dialog', 'update:childTab']);

const hoveredIndex = ref<number | null>();

const handleMouseEnter = (index: number) => {
  hoveredIndex.value = index; // Set the current hovered index
};

const handleMouseLeave = () => {
  hoveredIndex.value = null; // Reset the hovered index
};

const handleTabClick = (index: number) => {
  emits('update:childTab', index);
};

const handleMiniMapClick = (
  hotspot: IHotspot<Record<string, unknown>>,
  index: number
) => {
  hotspot.hotSpotAction(index);
  emits('update:childTab', index);
};
</script>

<style lang="scss">
.image-view {
  width: auto;
  height: 80vh;
}

.image-child-container {
  width: 100%;
  height: 85vh;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.vav-card {
  position: absolute;
  top: 27px;
  right: 120px;
  z-index: 100;
}

.dialog-tooltip {
  background: none !important;
}
</style>

<style lang="scss" scoped>
:deep(.v-tab__slider) {
  height: 6px !important;
}

:deep(.v-label) {
  opacity: 1 !important;
}

.v-chip {
  z-index: 100;
}
</style>
