<template>
  <v-dialog
    v-model="dialogValue"
    width="80%"
    transition="slide-x-reverse-transition"
    content-class="map-dialog"
  >
    <v-card class="rounded-2 px-10 py-9 map-dialog__card">
      <v-row>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex mb-n5">
              <v-toolbar-title
                class="text-primary font-weight-bold mr-auto text-h5"
              >
                {{ `${controlDetails.name} - Settings Changes' History` }}
              </v-toolbar-title>
              <div>
                <v-btn
                  variant="plain"
                  size="small"
                  class="mr-n3"
                  @click="dialogValue = false"
                >
                  <v-icon color="primary"> mdi-close-circle </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <span class="text-primary text-subtitle-2">
                {{ controlDetails.siteLocationName }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!--Settings History List-->
      <v-row>
        <PowerControlSettingsHistoryList
          :SettingsHistoryList="SettingsHistoryList"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/Core.Service.Domain/Controls/PowerControls/API';
import { mapActions } from 'vuex';

import PowerControlSettingsHistoryList from '@/Core.Service.Domain/Controls/PowerControls/Components/PowerControlSettingsHistoryList.vue';

export default {
  name: 'PowerControlHistoryDialog',

  props: {
    controlDetails: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PowerControlSettingsHistoryList,
  },

  beforeUnmount() {
    this.setControlsSettingsHistoryMessagesTimerOn(false);
    this.setLateraMenuOpen(false);
  },

  created() {
    this.handleSettingsHistory();
    this.setControlsSettingsHistoryMessagesTimerOn(true);
    this.setLateraMenuOpen(true);
  },

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },

  data() {
    return {
      SettingsHistoryList: [],
    };
  },

  methods: {
    ...mapActions({
      setControlsSettingsHistoryMessagesTimerOn:
        'ControlsStore/setControlsSettingsHistoryMessagesTimerOn',
      setLateraMenuOpen: 'UIStore/setLateraMenuOpen',
    }),

    async handleSettingsHistory() {
      try {
        this.SettingsHistoryList = await API.GetPowerControlSettingsMessages(
          this.controlDetails.controlId
        );
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.$emit('update:settingsHistoryList');
      }
    },
  },
};
</script>
