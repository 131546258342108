<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="$emit('update:dialog', $event)"
    width="auto"
  >
    <v-card :width="`${cardWidth}`" height="50%">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <span class="text-subtitle-1 text-white">
            {{ ahuDevice.controlFriendlyName }}
            {{ ahuDevice.controlSiteLocationName }}
          </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!--Dirty Icon-->
        <v-tooltip location="bottom" content-class="vav-control-tooltip">
          <template v-slot:activator="{ props }">
            <v-icon
              v-if="isAHUDirty"
              class="mr-4 ahu-control-dirty-icon"
              color="accentOrange"
              v-bind="props"
            >
              {{ 'mdi-alert-circle' }}
            </v-icon>
          </template>
          <!--Warnings-->
          <AHUDialogAlert
            :isAHUDirty="isAHUDirty"
            :getAHUNewSettings="getAHUNewSettings"
          />
        </v-tooltip>
      </v-toolbar>

      <v-container fluid class="ahu-container">
        <v-row>
          <v-col>
            <v-row>
              <!--Pressure Setpoints Card-->
              <v-col>
                <v-card variant="outlined" class="px-2">
                  <v-row>
                    <v-col>
                      <span
                        class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                      >
                        Pressure Setpoints
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="mt-1 mb-n5 px-1">
                    <v-col cols="6">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Building Static Pressure Setpoint"
                        :model-value="ahuDevice.buildingStaticPressureSetpoint"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Building Static Pressure Setpoint (Sensor)"
                        :model-value="
                          ahuDevice.buildingStaticPressureSetpointActualValue
                        "
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row class="px-1 mt-n7 mb-n5">
                    <v-col cols="6">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Duct Static Pressure Setpoint"
                        :model-value="ahuDevice.ductStaticPressureSetpoint"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Duct Static Pressure Setpoint (Sensor)"
                        :model-value="
                          ahuDevice.ductStaticPressureSetpointActualValue
                        "
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <!--Build Occupancy Card-->
              <v-col>
                <v-card variant="outlined" class="px-2">
                  <v-row>
                    <v-col>
                      <span
                        class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                      >
                        Building Occupancy
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="mt-1 mb-n5 px-1">
                    <v-col>
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="AHU Mode [ Occupied | Unoccupied | Tenant Override ]"
                        :model-value="ahuDevice.occupationMode"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <!--Supply Temperature Setpoints Card-->
              <v-col>
                <v-card variant="outlined" class="px-2">
                  <v-row>
                    <v-col>
                      <span
                        class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                      >
                        Supply Temperature Setpoints
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="mt-1 mb-n5 px-1">
                    <v-col cols="6">
                      <v-number-input
                        class="rounded-2 text-primary"
                        :color="userCanUpdate ? 'warning' : 'primary'"
                        :readonly="!userCanUpdate"
                        :base-color="userCanUpdate ? 'warning' : 'primary'"
                        data-testid="netSupplyAirCoolingSetpointTextField"
                        control-variant="stacked"
                        inset
                        :step="0.5"
                        label="Net Supply Air Cooling Setpoint"
                        v-model="localSettings.netSupplyAirCoolingSetpoint"
                        variant="outlined"
                        density="compact"
                      >
                        <template #append-inner v-if="userCanUpdate">
                          <v-icon color="warning">mdi-pencil-box</v-icon>
                        </template>
                      </v-number-input>
                    </v-col>
                    <v-col cols="6">
                      <v-number-input
                        class="rounded-2 text-primary"
                        :color="userCanUpdate ? 'warning' : 'primary'"
                        :readonly="!userCanUpdate"
                        :base-color="userCanUpdate ? 'warning' : 'primary'"
                        data-testid="minSupplyAirCoolingSetpointTextField"
                        control-variant="stacked"
                        inset
                        :step="0.1"
                        label="Min Supply Air Cooling Setpoint"
                        v-model="localSettings.minSupplyAirCoolingSetpoint"
                        variant="outlined"
                        density="compact"
                      >
                        <template #append-inner v-if="userCanUpdate">
                          <v-icon color="warning">mdi-pencil-box</v-icon>
                        </template>
                      </v-number-input>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 mt-n7 mb-n5 px-1">
                    <v-col cols="6">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Effective Supply Air Cooling Setpoint"
                        :model-value="
                          ahuDevice.effectiveSupplyAirCoolingSetpoint
                        "
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <!--System Parameters Card-->
              <v-col>
                <v-card variant="outlined" class="px-2">
                  <v-row>
                    <v-col>
                      <span
                        class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                      >
                        System Parameters
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="mt-1 mb-n5 px-1">
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Airflow Status [ Flow | No Flow ]"
                        :model-value="ahuDevice.airFlowStatus"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Supply Fan Capacity %"
                        :model-value="ahuDevice.supplyFanCapacity"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Return Fan Capacity %"
                        :model-value="ahuDevice.returnFanCapacity"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row class="px-1 mt-n7">
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Return Air Temperature (RAT)"
                        :model-value="ahuDevice.returnAirTemperature"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Supply Air Temperature (SAT)"
                        :model-value="ahuDevice.supplyAirTemperature"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        class="rounded-2 text-primary"
                        label="Outside Air Temperature (OAT)"
                        :model-value="ahuDevice.outsideAirTemperature"
                        variant="outlined"
                        density="compact"
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <!-- Warning, Problem, Fault -->
                  <v-row class="px-1 mt-n7 mb-1">
                    <v-col cols="1">
                      <v-card variant="outlined" class="px-2 py-1">
                        <v-icon
                          :color="ahuDevice.warning ? 'red' : 'green'"
                          :icon="
                            ahuDevice.warning
                              ? 'mdi-alert-circle'
                              : 'mdi-check-circle'
                          "
                          start
                        />
                        <span
                          class="text-primary text-caption text-sm-subtitle-1"
                        >
                          Warning
                        </span>
                      </v-card>
                    </v-col>
                    <v-col cols="1">
                      <v-card variant="outlined" class="px-2 py-1">
                        <v-icon
                          :color="ahuDevice.problem ? 'red' : 'green'"
                          :icon="
                            ahuDevice.problem
                              ? 'mdi-alert-circle'
                              : 'mdi-check-circle'
                          "
                          start
                        />
                        <span
                          class="text-primary text-caption text-sm-subtitle-1"
                        >
                          Problem
                        </span>
                      </v-card>
                    </v-col>
                    <v-col cols="1">
                      <v-card variant="outlined" class="px-2 py-1">
                        <v-icon
                          :color="ahuDevice.fault ? 'red' : 'green'"
                          :icon="
                            ahuDevice.fault
                              ? 'mdi-alert-circle'
                              : 'mdi-check-circle'
                          "
                          start
                        />
                        <span
                          class="text-primary text-caption text-sm-subtitle-1"
                        >
                          Fault
                        </span>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="8" align-self="center"> </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="isAHUSetLoading"
              data-testid="resetAHUSetpointButton"
              class="rounded-2"
              color="primary"
              variant="flat"
              block
              size="large"
              @click="isAHUDirty ? resetChangedSettings() : handleCancel()"
            >
              <div class="text-subtitle-2 text-white text-truncate">
                {{ isAHUDirty ? 'Reset' : 'Cancel' }}
              </div>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              :loading="isAHUSetLoading"
              :disabled="!isAHUDirty || !userCanUpdate"
              class="rounded-2"
              :color="!isAHUDirty ? 'grey' : 'accentOrange'"
              data-testid="setAHUSetpointButton"
              variant="flat"
              block
              size="large"
              @click="handleSet"
            >
              <div class="text-subtitle-2 text-white text-truncate">Set</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch, computed, getCurrentInstance } from 'vue';
import { useDisplay } from 'vuetify';

import API from '@/Core.Service.Domain/Controls/DigitalTwin/API/index.ts';

import { IAHU } from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';

import AHUDialogAlert from '@/Core.Service.Domain/Controls/DigitalTwin/Components/AHUDialogAlert.vue';

import { capitalizeFirstLetter } from '@/Core.Patterns/Adapter/index.ts';
import { splitCamelCase } from '@/Core.Patterns/Factory/index.ts';

import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

const { name } = useDisplay();

const isAHUSetLoading = ref(false);
const isDirty = ref({
  netSupplyAirCoolingSetpoint: false,
  minSupplyAirCoolingSetpoint: false,
});

const isAHUDirty = computed(() =>
  Object.values(isDirty.value).some((value) => value)
);

const cardWidth = computed(() => {
  switch (name.value) {
    case 'xs':
      return '95vw';
    case 'sm':
      return '95vw';
    case 'md':
      return '95vw';
    case 'lg':
      return '95vw';
    case 'xl':
      return '85vw';
    case 'xxl':
      return '60vw';
    default:
      return undefined;
  }
});

const props = defineProps({
  ahuDevice: {
    type: Object as () => IAHU,
    required: true,
  },
  dialog: Boolean,
});

const emits = defineEmits(['update:dialog']);

const localSettings = ref<
  Pick<IAHU, 'netSupplyAirCoolingSetpoint' | 'minSupplyAirCoolingSetpoint'>
>({
  netSupplyAirCoolingSetpoint: 0,
  minSupplyAirCoolingSetpoint: 0,
});

watch(
  () => [
    props.ahuDevice.netSupplyAirCoolingSetpoint,
    props.ahuDevice.minSupplyAirCoolingSetpoint,
  ],
  () => {
    localSettings.value.netSupplyAirCoolingSetpoint =
      props.ahuDevice.netSupplyAirCoolingSetpoint;
    localSettings.value.minSupplyAirCoolingSetpoint =
      props.ahuDevice.minSupplyAirCoolingSetpoint;
  },
  { immediate: true }
);

watch(
  () => [
    localSettings.value.netSupplyAirCoolingSetpoint,
    localSettings.value.minSupplyAirCoolingSetpoint,
  ],
  ([netVal, minVal]) => {
    isDirty.value.netSupplyAirCoolingSetpoint =
      Number(netVal) !== props.ahuDevice.netSupplyAirCoolingSetpoint;
    isDirty.value.minSupplyAirCoolingSetpoint =
      Number(minVal) !== props.ahuDevice.minSupplyAirCoolingSetpoint;
  }
);

const instance = getCurrentInstance()!;
const appContext = instance?.appContext.config.globalProperties;

const userCanUpdate = computed(() => {
  return appContext?.$can('update', 'controls');
});
const handleSet = async () => {
  if (!isAHUDirty.value || !userCanUpdate.value) return;
  isAHUSetLoading.value = true;

  try {
    await API.setAHUSetpoint(props.ahuDevice.controlId, {
      netSupplyAirCoolingSetpoint:
        localSettings.value.netSupplyAirCoolingSetpoint,
      minSupplyAirCoolingSetpoint:
        localSettings.value.minSupplyAirCoolingSetpoint,
    });
    resetIsDirty();

    const options: ToastOptions = {
      title: 'Success',
      message: `AHU Setpoints have been successfully updated.<br />Values can take up to 2 minutes to reflect.`,
      status: 'success',
    };
    appContext?.$toast.show(options);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isAHUSetLoading.value = false;
  }
};

const resetIsDirty = () => {
  isDirty.value = {
    netSupplyAirCoolingSetpoint: false,
    minSupplyAirCoolingSetpoint: false,
  };
};

const resetChangedSettings = () => {
  if (!isAHUDirty.value) return;
  localSettings.value.netSupplyAirCoolingSetpoint =
    props.ahuDevice.netSupplyAirCoolingSetpoint;
  localSettings.value.minSupplyAirCoolingSetpoint =
    props.ahuDevice.minSupplyAirCoolingSetpoint;

  resetIsDirty();
};

const handleCancel = () => {
  resetChangedSettings();
  emits('update:dialog', false);
};

const getAHUNewSettings = () => {
  const dirtySetting: {
    name: string;
    currentSetting: string;
    newSetting: string;
  }[] = [];

  Object.entries(isDirty.value).forEach(([key, value]) => {
    if (value) {
      const typedKey = key as keyof IAHU;
      dirtySetting.push({
        name: splitCamelCase(capitalizeFirstLetter(`${key}`)),
        currentSetting: `${props.ahuDevice[typedKey]}`,
        newSetting: `${
          localSettings.value[
            typedKey as
              | 'netSupplyAirCoolingSetpoint'
              | 'minSupplyAirCoolingSetpoint'
          ]
        }`,
      });
    }
  });

  return dirtySetting;
};
</script>

<style lang="scss" scoped>
.ahu-container {
  width: 100%;
  height: 50%;
  overflow: hidden;
  position: relative;
}

.v-field__append-inner > .v-icon {
  opacity: 1 !important;
}

.ahu-control-dirty-icon {
  cursor: pointer;
}

:deep(input[readonly]) {
  color: grey !important;
  cursor: not-allowed;
}
</style>
