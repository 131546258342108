import { markRaw } from 'vue';

import VAV263Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV263Drawing.vue';
import VAV264Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV264Drawing.vue';
import VAV265Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV265Drawing.vue';
import VAV266Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV266Drawing.vue';
import VAV267Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV267Drawing.vue';
import VAV268Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV268Drawing.vue';
import VAV269Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciW/VAV269Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 2-63',
    controlId: 2234,
    component: markRaw(VAV263Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-63',
      },
    },
    style: {
      position: 'absolute',
      top: '51.3%',
      left: '81.5%',
      width: '49.1%',
      height: '49.1%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      left: '103%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 2-64',
    controlId: 2235,
    component: markRaw(VAV264Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-64',
      },
    },
    style: {
      position: 'absolute',
      top: '60.9%',
      left: '60.05%',
      width: '39.5%',
      height: '39.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '79%',
      left: '75%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 2-65',
    controlId: 2236,
    component: markRaw(VAV265Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-65',
      },
    },
    style: {
      position: 'absolute',
      top: '62.5%',
      left: '24%',
      width: '36%',
      height: '36%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '79%',
      left: '40%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 2-66',
    controlId: 2291,
    component: markRaw(VAV266Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-66',
      },
    },
    style: {
      position: 'absolute',
      top: '9%',
      left: '0.3%',
      width: '53.5%',
      height: '53.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '30%',
      left: '24%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 2-67',
    controlId: 2292,
    component: markRaw(VAV267Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-67',
      },
    },
    style: {
      position: 'absolute',
      top: '-8.5%',
      left: '35%',
      width: '48.1%',
      height: '48.1%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '12%',
      left: '55%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 5,
    name: 'VAV 2-68',
    controlId: 2293,
    component: markRaw(VAV268Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-68',
      },
    },
    style: {
      position: 'absolute',
      top: '11.6%',
      left: '20.1%',
      width: '59.6%',
      height: '59.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '46%',
      left: '45%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 6,
    name: 'VAV 2-69',
    controlId: 2294,
    component: markRaw(VAV269Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-69',
      },
    },
    style: {
      position: 'absolute',
      top: '8.4%',
      left: '34.7%',
      width: '73%',
      height: '73%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '46%',
      left: '80%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
