<template>
  <Form ref="obs">
    <v-form @submit.prevent="submit">
      <v-container fluid>
        <v-card class="pa-4 rounded-2">
          <v-row>
            <v-col sm="6">
              <v-select
                :items="inputTypes"
                item-value="inputKey"
                item-title="typeName"
                :rules="[(v) => !!v || 'Input type is required']"
                :loading="isLoadingTypes"
                :model-value="selectedInput"
                label="Strategy Template Trigger"
                hint="What input type should trigger this strategy template?"
                @update:modelValue="
                  (value) => value && handleSelectInputType(value)
                "
                persistent-hint
                return-object
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="6">
              <v-select
                :items="frequencies"
                item-value="id"
                item-title="text"
                :rules="[(v) => !!v || 'Frequency is required']"
                :model-value="selectedFrequency"
                label="Execution Frequency"
                hint="How often should the rule be executed?"
                persistent-hint
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field
                autofocus
                v-model="strategyName"
                label="Strategy Template Name"
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="strategyDescription"
                label="Strategy Template Description"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-card variant="outlined">
                <v-card-text id="divRuleEditor" ref="divRuleEditor" />
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="ruleInvalid"
                class="mr-5"
              >
                Save
              </v-btn>
              <v-btn color="primary" @click="validateRule">Validate</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-form>
  </Form>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import API from '@/Core.Service.Domain/Strategies/StrategyTemplates/API';

import { Form } from 'vee-validate';

import {
  ISelectedTemplate,
  IRuleEditor,
  StrategyValidator,
  IStrategyPayload,
  InputType,
} from '@/Core.Service.Domain/Strategies/Strategies/types/strategies.ts';
import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

const instance = getCurrentInstance()!;
const appContext = instance?.appContext.config.globalProperties;

// Refs
const siteId = ref<string>('');
const inputTypes = ref<InputType[]>([]);
const ruleInvalid = ref(true);
const strategyName = ref('');
const frequencies = ref([
  { id: 'EveryFiveMinutes', text: 'Every 5 minutes' },
  { id: 'EveryTenMinutes', text: 'Every 10 minutes' },
  { id: 'EveryFifteenMinutes', text: 'Every 15 minutes' },
  { id: 'EveryThirtyMinutes', text: 'Every 30 minutes' },
  { id: 'EveryHour', text: 'Every hour' },
  { id: 'EveryDay', text: 'Every day' },
]);

const selectedFrequency = ref('EveryFifteenMinutes');
const strategyDescription = ref('');
const selectedInput = ref<InputType | null>(null);
const isLoadingTypes = ref(false);
const editorSettings = ref<{
  editorData: unknown;
  sourceData: unknown;
  ruleData: unknown;
} | null>(null);
const ruleEditor = ref<IRuleEditor | ISelectedTemplate | null>(null);

const route = useRoute();
const router = useRouter();

// Lifecycle
onMounted(async () => {
  const codeEffectsScript = document.createElement('script');
  codeEffectsScript.setAttribute('src', '/js/codeeffects.editor.js');
  document.head.appendChild(codeEffectsScript);

  const cssLink1 = document.createElement('link');
  cssLink1.setAttribute('rel', 'stylesheet');
  cssLink1.setAttribute('href', '/js/codeeffects.common.css');
  document.head.appendChild(cssLink1);

  const cssLink2 = document.createElement('link');
  cssLink2.setAttribute('rel', 'stylesheet');
  cssLink2.setAttribute('href', '/js/codeeffects.gray.css');
  document.head.appendChild(cssLink2);
  siteId.value = Array.isArray(route.params.siteId)
    ? route.params.siteId[0]
    : route.params.siteId;
  inputTypes.value = await API.getInputTypes();
});

// Methods
const submit = async () => {
  const valid = await (instance.refs.obs as StrategyValidator).validate();
  if (!valid) {
    return;
  }
  const ruleContent = ruleEditor.value?.extract();

  const payload: IStrategyPayload = {
    siteId: siteId.value,
    strategyName: strategyName.value,
    strategyDescription: strategyDescription.value,
    inputTypeName: selectedInput.value,
    frequency: selectedFrequency.value,
    editorData: ruleContent as object,
  };

  await API.saveStrategyTemplate(payload);

  const options: ToastOptions = {
    title: 'Success',
    message: 'Successfully created your strategy template.',
  };
  appContext?.$toast.show(options);
  router.push({ name: 'StrategyTemplatesList' });
};

const validateRule = async () => {
  const valid = await (instance.refs.obs as StrategyValidator).validate();
  if (!valid) {
    return;
  }
  const ruleContent = ruleEditor.value?.extract();

  const payload: IStrategyPayload = {
    strategyName: strategyName.value,
    strategyDescription: strategyDescription.value,
    inputTypeName: selectedInput.value,
    frequency: selectedFrequency.value,
    editorData: ruleContent as object,
  };

  const validationResult = await API.validateRule(payload);
  if (validationResult.empty) {
    const options: ToastOptions = {
      title: 'Warning',
      message: 'Rule cannot be empty.',
      status: 'warning',
    };

    appContext?.$toast.show(options);
    ruleInvalid.value = true;
    return;
  }

  if (!validationResult.valid) {
    ruleEditor.value?.loadInvalids(validationResult.clientInvalidData);
    ruleInvalid.value = true;
  } else {
    const options: ToastOptions = {
      title: 'Success',
      message: 'Rule is valid and can be saved.',
    };

    appContext?.$toast.show(options);
    ruleInvalid.value = false;
  }
};

const handleSelectInputType = async (input: InputType | null) => {
  if (!input) return;
  selectedInput.value = input;
  isLoadingTypes.value = true;
  try {
    editorSettings.value = await API.getEditorSettingsForInput(
      input.inputKey as string
    );
    loadEditorSettings();
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isLoadingTypes.value = false;
  }
};

const handleCancelCrud = () => {
  router.push({ name: 'StrategyTemplatesList' });
};

const loadEditorSettings = () => {
  const $rule = window['$rule'];
  if (ruleEditor.value != null) ruleEditor.value.dispose();
  if ($rule && $rule.Context) {
    $rule.Context.clear();
  }

  ruleEditor.value = $rule.init(editorSettings.value?.editorData);
  ruleEditor.value?.clear();
  ruleEditor.value?.loadSettings(editorSettings.value?.sourceData);
  if (editorSettings.value?.ruleData)
    ruleEditor.value?.loadRule(editorSettings.value.ruleData);
};
</script>

<style lang="scss">
div .ceRule {
  min-height: 20vh;
}
</style>
