<template>
  <v-card class="notifications-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Notifications
      </v-app-bar-title>
      <v-row>
        <v-col>
          <v-autocomplete
            class="mt-6 mr-5"
            :items="sitesList"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            return-object
            variant="outlined"
            density="compact"
            @update:modelValue="
              (selectedSite) => {
                setDefaultSiteFromNotificationsDomain(selectedSite);
              }
            "
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            bg-color="white"
            single-line
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>

      <template v-slot:append>
        <v-btn
          class="rounded-2 mr-2"
          color="primary"
          variant="flat"
          size="large"
          @click="handleBackToPortfolio"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            Back to My Portfolio
          </div>
        </v-btn>
        <v-btn
          class="rounded-2"
          color="accentOrange"
          variant="flat"
          size="large"
          @click="handleMarkAllAsRead"
          :loading="isMarkAllLoading"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            Mark All as Read
          </div>
        </v-btn>
      </template>
      <v-spacer />
    </v-app-bar>
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'NotificationsBar',

  components: {
    ProgressLinearLoaderVue,
  },

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
      siteId: null,
      isMarkAllLoading: false,
    };
  },

  async mounted() {
    try {
      if (this.sitesList.length === 0) {
        await this.getPortfolioSites();
      }
      if (!this.defaultSite) {
        await this.setDefaultSite(this.sitesList[0]);
      }
      this.setDefaultSiteFromNotificationsDomain(this.defaultSite);
    } catch (error) {
      return Promise.reject(error);
    }

    eventBus.$on('isMarkAllLoading', (bool) => {
      this.isMarkAllLoading = bool;
    });
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
      isLoading:
        'ViewSupportStore/NotificationsStore/getNotificationsIsLoading',
      sitesList: 'ViewSupportStore/PortfolioStore/getPortfolioSitesList',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Notification';
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromNotificationsDomain(this.defaultSite);
      this.clearSearchTerm();
    },

    sitesList: {
      handler() {
        if (this.sitesList.length > 0) {
          this.setDefaultSiteFromNotificationsDomain(this.defaultSite);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      getNotificationsBySiteId:
        'ViewSupportStore/NotificationsStore/getNotificationsBySiteId',
      setDefaultSite: 'session/setDefaultSite',
      getPortfolioSites: 'ViewSupportStore/PortfolioStore/getPortfolioSites',
    }),

    ...mapMutations({
      setNotificationsSearchTerm:
        'ViewSupportStore/NotificationsStore/NOTIFICATIONS_SEARCH_TERM',
    }),

    setDefaultSiteFromNotificationsDomain(selectedSite = this.defaultSite) {
      const siteId = selectedSite.siteId || selectedSite.id;
      const selectedSiteId = this.$route.params.siteId || siteId;

      this.selectedSite = this.sitesList.find(
        (site) => site.siteId === Number(selectedSiteId)
      );

      this.getNotificationsBySiteId(siteId).then(() => {
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
        this.$router
          .push({
            name: 'AllNotifications',
            params: { siteId: this.siteId },
          })
          .catch(() => {});
      });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setNotificationsSearchTerm('');
      } else {
        this.setNotificationsSearchTerm(value);
      }
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setNotificationsSearchTerm('');
    },

    handleBackToPortfolio() {
      this.$router.push({ name: 'Portfolio' });
    },

    handleMarkAllAsRead() {
      eventBus.$emit('markAllAsRead');
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.siteId = to.params.siteId;
    next();
  },
};
</script>
<style lang="scss" scoped>
.notifications-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
