<template>
  <div class="ahu-button" data-cy="AHUButton">
    <v-btn
      v-if="userCanUpdate"
      :loading="isAHULoading"
      color="primary"
      @click="$emit('update:ahu-settings')"
    >
      <template v-slot:prepend>
        <v-icon>mdi-air-purifier</v-icon>
      </template>
      <span> AHU Settings </span>
    </v-btn>
    <v-btn
      v-else
      :loading="isAHULoading"
      color="primary"
      @mouseenter="$emit('get:ahu-id')"
    >
      <template v-slot:prepend>
        <v-icon>mdi-air-purifier</v-icon>
      </template>
      <span> AHU Settings </span>
    </v-btn>
    <!-- Tooltip content -->
    <v-tooltip
      v-if="!userCanUpdate"
      activator="parent"
      location="start"
      content-class="dialog-tooltip"
    >
      <DigitalTwinTooltip :tooltipInfo="AHUSettings">
        <v-progress-circular
          v-if="isAHUButtonLoading"
          color="#378CDA"
          indeterminate
        />
        <table v-else>
          <tbody>
            <tr
              v-for="(value, key) in renameAHUKeysFromRemapAHUObject(
                AHUSettings
              )"
              :key="key"
            >
              <td cols="6" class="text-primary text-subtitle-2 text-left pr-7">
                {{ splitCamelCase(capitalizeFirstLetter(`${key}`)) }}
              </td>
              <td
                cols="6"
                class="text-primary text-subtitle-2 text-left pl-7 font-weight-bold"
              >
                {{ setUnitByValue(key, value ?? '') }}
              </td>
            </tr>
          </tbody>
        </table>
      </DigitalTwinTooltip>
    </v-tooltip>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, watch, computed, getCurrentInstance } from 'vue';
import { capitalizeFirstLetter } from '@/Core.Patterns/Adapter/index.ts';
import { splitCamelCase } from '@/Core.Patterns/Factory/index.ts';

import API from '@/Core.Service.Domain/Controls/DigitalTwin/API/index.ts';

import { IAHU } from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';

import DigitalTwinTooltip from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DigitalTwinTooltip.vue';

const isAHUButtonLoading = ref(false);
const AHUSettings = ref<IAHU>({} as IAHU);

const currentAHUId = inject('currentAHUId', ref(0));
const isAHULoading = inject('isAHULoading', ref(false));

const handleTooltipData = async (ahuId: number) => {
  try {
    AHUSettings.value = {} as IAHU;
    isAHUButtonLoading.value = true;
    AHUSettings.value = await API.getAHUById(ahuId);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isAHUButtonLoading.value = false;
  }
};

watch(
  () => currentAHUId.value,
  (newValue: number) => {
    if (newValue) {
      handleTooltipData(newValue);
    }
  },
  { deep: true }
);

const remapAHUObject = (ahu: IAHU) => {
  const keysValuesToRemove = [
    'siteId',
    'siteName',
    'controlId',
    'controlName',
    'controlFriendlyName',
    'usageDateTime',
  ];

  const remappedAHU = { ...ahu };
  keysValuesToRemove.forEach((key) => {
    delete remappedAHU[key as keyof IAHU];
  });
  return remappedAHU;
};

const renameAHUKeysFromRemapAHUObject = (ahu: IAHU) => {
  const remappedKeys: { [key: string]: string } = {
    controlSiteLocationName: 'location',
    timeSinceLastUpdate: 'lastUpdate',
  };

  const remappedAHU = remapAHUObject(ahu);
  const renamedAHU = Object.keys(remappedAHU).reduce((acc, key) => {
    const newKey = remappedKeys[key] || key;
    return { ...acc, [newKey]: remappedAHU[key as keyof IAHU] };
  }, {} as IAHU);

  return renamedAHU;
};

const setUnitByValue = (key: keyof IAHU, value: string | number | boolean) => {
  if (key === 'occupationMode') {
    if (/^occ/i.test(value as string)) {
      return 'Occupied';
    }
    if (/^unocc/i.test(value as string)) {
      return 'Unoccupied';
    } else {
      return 'Tenant Override';
    }
  }

  if (key === 'supplyFanCapacity' || key === 'returnFanCapacity') {
    return `${value} %`;
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
};

const instance = getCurrentInstance()!;
const appContext = instance?.appContext.config.globalProperties;

const userCanUpdate = computed(() => {
  return appContext?.$can('update', 'controls');
});
</script>

<style lang="scss" scoped>
.ahu-button button {
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  text-transform: none;
}

.controls button:hover,
.ahu-button button:hover {
  background-color: #f0f0f0;
}

.ahu-button {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
</style>
