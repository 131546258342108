<template>
  <svg
    width="210"
    height="169"
    viewBox="0 0 210 169"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.75 2.74001H33.66" stroke="black" stroke-width="0.5" />
    <path d="M59.88 2.74001H147.6" stroke="black" stroke-width="0.5" />
    <path d="M166.28 2.74001H208.5" stroke="black" stroke-width="0.5" />
    <path d="M3.13 1.41H34.18" stroke="black" stroke-width="0.5" />
    <path d="M59.88 1.41H99.24" stroke="black" stroke-width="0.5" />
    <path d="M105.92 1.41H148.94" stroke="black" stroke-width="0.5" />
    <path d="M164.95 1.41H201.93" stroke="black" stroke-width="0.5" />
    <path d="M208.34 1.41H210.71" stroke="black" stroke-width="0.5" />
    <path d="M208.5 2.74001V66.46" stroke="black" stroke-width="0.5" />
    <path d="M208.5 66.46H205.57" stroke="black" stroke-width="0.5" />
    <path d="M205.57 66.46V71.99" stroke="black" stroke-width="0.5" />
    <path d="M205.57 71.99H205.4" stroke="black" stroke-width="0.5" />
    <path d="M205.4 71.99V109.69" stroke="black" stroke-width="0.5" />
    <path d="M205.4 109.69H207.54" stroke="black" stroke-width="0.5" />
    <path d="M207.54 109.69V139.04" stroke="black" stroke-width="0.5" />
    <path d="M207.54 139.04H205.4" stroke="black" stroke-width="0.5" />
    <path d="M205.4 139.04V164.76" stroke="black" stroke-width="0.5" />
    <path d="M205.4 164.76H194.09" stroke="black" stroke-width="0.5" />
    <path d="M194.09 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M194.09 166.9H164.74" stroke="black" stroke-width="0.5" />
    <path d="M164.74 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M164.74 164.76H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.85 164.76V167.03" stroke="black" stroke-width="0.5" />
    <path d="M142.85 167.03H113.5" stroke="black" stroke-width="0.5" />
    <path d="M113.5 167.03V164.76" stroke="black" stroke-width="0.5" />
    <path d="M113.5 164.76H91.62" stroke="black" stroke-width="0.5" />
    <path d="M91.62 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M91.62 166.9H62.26" stroke="black" stroke-width="0.5" />
    <path d="M62.26 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M62.26 164.76H40.38" stroke="black" stroke-width="0.5" />
    <path d="M40.38 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M40.38 166.9H11.02" stroke="black" stroke-width="0.5" />
    <path d="M11.02 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M11.02 164.76H8.26001" stroke="black" stroke-width="0.5" />
    <path d="M8.26001 164.76V161.56" stroke="black" stroke-width="0.5" />
    <path d="M8.26001 161.56H-10.86" stroke="black" stroke-width="0.5" />
    <path d="M0.75 161.56V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M0.75 51.05H44.78" stroke="black" stroke-width="0.5" />
    <path d="M46.12 51.05H69.24" stroke="black" stroke-width="0.5" />
    <path d="M78.85 51.05H125.12" stroke="black" stroke-width="0.5" />
    <path d="M85.41 49.71H125.12" stroke="black" stroke-width="0.5" />
    <path d="M0.75 49.71H69.24" stroke="black" stroke-width="0.5" />
    <path d="M78.38 49.71H80.61" stroke="black" stroke-width="0.5" />
    <path d="M81.94 49.71H84.08" stroke="black" stroke-width="0.5" />
    <path d="M0.75 79.87H44.78" stroke="black" stroke-width="0.5" />
    <path d="M0.75 78.53H44.78" stroke="black" stroke-width="0.5" />
    <path d="M0.75 108.69H44.78" stroke="black" stroke-width="0.5" />
    <path d="M0.75 107.35H44.78" stroke="black" stroke-width="0.5" />
    <path d="M0.75 137.51H44.78" stroke="black" stroke-width="0.5" />
    <path d="M0.75 136.18H44.78" stroke="black" stroke-width="0.5" />
    <path d="M44.78 164.76V147.92" stroke="black" stroke-width="0.5" />
    <path d="M44.78 138.31V137.51" stroke="black" stroke-width="0.5" />
    <path d="M44.78 136.18V119.1" stroke="black" stroke-width="0.5" />
    <path d="M44.78 109.49V108.69" stroke="black" stroke-width="0.5" />
    <path d="M44.78 107.35V106.02" stroke="black" stroke-width="0.5" />
    <path d="M44.78 96.41V79.87" stroke="black" stroke-width="0.5" />
    <path d="M44.78 78.53V77.2" stroke="black" stroke-width="0.5" />
    <path d="M44.78 67.59V51.05" stroke="black" stroke-width="0.5" />
    <path d="M46.12 164.76V152.29" stroke="black" stroke-width="0.5" />
    <path d="M46.12 150.96V147.92" stroke="black" stroke-width="0.5" />
    <path d="M60.93 164.76V152.29" stroke="black" stroke-width="0.5" />
    <path d="M60.93 150.96V133.24" stroke="black" stroke-width="0.5" />
    <path d="M62.26 164.76V134.57" stroke="black" stroke-width="0.5" />
    <path d="M46.12 138.31V119.1" stroke="black" stroke-width="0.5" />
    <path d="M62.13 65.34V73.42" stroke="black" stroke-width="0.5" />
    <path d="M62.13 83.03V117.23" stroke="black" stroke-width="0.5" />
    <path d="M63.46 65.34V71.21" stroke="black" stroke-width="0.5" />
    <path d="M63.46 72.54V73.42" stroke="black" stroke-width="0.5" />
    <path d="M63.46 83.03V93.19" stroke="black" stroke-width="0.5" />
    <path d="M63.46 95.32V115.89" stroke="black" stroke-width="0.5" />
    <path d="M46.12 109.49V106.02" stroke="black" stroke-width="0.5" />
    <path d="M46.12 96.41V77.2" stroke="black" stroke-width="0.5" />
    <path d="M46.12 67.59V51.05" stroke="black" stroke-width="0.5" />
    <path d="M44.78 106.55H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 106.02H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 95.88H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 96.41H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 77.73H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 77.2H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 67.06H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 67.59H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 108.96H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 109.49H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 119.63H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 119.1H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 138.31V137.51" stroke="black" stroke-width="0.5" />
    <path d="M44.78 137.78H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 138.31H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 148.45H46.12" stroke="black" stroke-width="0.5" />
    <path d="M44.78 147.92H46.12" stroke="black" stroke-width="0.5" />
    <path d="M40.38 168.11H39.85" stroke="black" stroke-width="0.5" />
    <path d="M39.85 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M39.85 166.9H40.38" stroke="black" stroke-width="0.5" />
    <path d="M40.38 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M11.02 166.9H11.56" stroke="black" stroke-width="0.5" />
    <path d="M11.56 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M15.87 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M16.43 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M11.56 168.11H11.02" stroke="black" stroke-width="0.5" />
    <path d="M11.02 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M35 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M35 166.9H35.53" stroke="black" stroke-width="0.5" />
    <path d="M35.53 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M35.53 168.11H35" stroke="black" stroke-width="0.5" />
    <path d="M39.85 166.9H35.53" stroke="black" stroke-width="0.5" />
    <path d="M35 166.9H16.43" stroke="black" stroke-width="0.5" />
    <path d="M15.87 166.9H11.56" stroke="black" stroke-width="0.5" />
    <path d="M35 168.11H16.43" stroke="black" stroke-width="0.5" />
    <path d="M15.87 168.11H11.56" stroke="black" stroke-width="0.5" />
    <path d="M39.85 168.11H35.53" stroke="black" stroke-width="0.5" />
    <path d="M11.56 167.79H39.85" stroke="black" stroke-width="0.5" />
    <path d="M39.85 167.52H11.56" stroke="black" stroke-width="0.5" />
    <path d="M39.85 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M11.56 167.52V167.79L39.85 167.52H11.56Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M39.85 167.52L11.56 167.79H39.85V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M11.56 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M15.87 168.11H16.43" stroke="black" stroke-width="0.5" />
    <path d="M15.87 166.9H16.43" stroke="black" stroke-width="0.5" />
    <path d="M91.62 168.11H91.08" stroke="black" stroke-width="0.5" />
    <path d="M91.08 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M91.08 166.9H91.62" stroke="black" stroke-width="0.5" />
    <path d="M91.62 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M62.26 166.9H62.8" stroke="black" stroke-width="0.5" />
    <path d="M62.8 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M67.11 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M67.66 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M62.8 168.11H62.26" stroke="black" stroke-width="0.5" />
    <path d="M62.26 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M86.24 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M86.24 166.9H86.77" stroke="black" stroke-width="0.5" />
    <path d="M86.77 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M86.77 168.11H86.24" stroke="black" stroke-width="0.5" />
    <path d="M91.08 166.9H86.77" stroke="black" stroke-width="0.5" />
    <path d="M86.24 166.9H67.66" stroke="black" stroke-width="0.5" />
    <path d="M67.11 166.9H62.8" stroke="black" stroke-width="0.5" />
    <path d="M86.24 168.11H67.66" stroke="black" stroke-width="0.5" />
    <path d="M67.11 168.11H62.8" stroke="black" stroke-width="0.5" />
    <path d="M91.08 168.11H86.77" stroke="black" stroke-width="0.5" />
    <path d="M62.8 167.79H91.08" stroke="black" stroke-width="0.5" />
    <path d="M91.08 167.52H62.8" stroke="black" stroke-width="0.5" />
    <path d="M91.08 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M62.8 167.52V167.79L91.08 167.52H62.8Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M91.08 167.52L62.8 167.79H91.08V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M62.8 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M67.11 168.11H67.66" stroke="black" stroke-width="0.5" />
    <path d="M67.11 166.9H67.66" stroke="black" stroke-width="0.5" />
    <path d="M142.85 168.11H142.32" stroke="black" stroke-width="0.5" />
    <path d="M142.32 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M142.32 166.9H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.85 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M113.5 166.9H114.03" stroke="black" stroke-width="0.5" />
    <path d="M114.03 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M118.34 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M118.9 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M114.03 168.11H113.5" stroke="black" stroke-width="0.5" />
    <path d="M113.5 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M137.48 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M137.48 166.9H138.01" stroke="black" stroke-width="0.5" />
    <path d="M138.01 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M138.01 168.11H137.48" stroke="black" stroke-width="0.5" />
    <path d="M142.32 166.9H138.01" stroke="black" stroke-width="0.5" />
    <path d="M137.48 166.9H118.9" stroke="black" stroke-width="0.5" />
    <path d="M118.34 166.9H114.03" stroke="black" stroke-width="0.5" />
    <path d="M137.48 168.11H118.9" stroke="black" stroke-width="0.5" />
    <path d="M118.34 168.11H114.03" stroke="black" stroke-width="0.5" />
    <path d="M142.32 168.11H138.01" stroke="black" stroke-width="0.5" />
    <path d="M114.03 167.79H142.32" stroke="black" stroke-width="0.5" />
    <path d="M142.32 167.52H114.03" stroke="black" stroke-width="0.5" />
    <path d="M142.32 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M114.03 167.52V167.79L142.32 167.52H114.03Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M142.32 167.52L114.03 167.79H142.32V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M114.03 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M118.34 168.11H118.9" stroke="black" stroke-width="0.5" />
    <path d="M118.34 166.9H118.9" stroke="black" stroke-width="0.5" />
    <path d="M194.09 168.11H193.56" stroke="black" stroke-width="0.5" />
    <path d="M193.56 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M193.56 166.9H194.09" stroke="black" stroke-width="0.5" />
    <path d="M194.09 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M164.74 166.9H165.27" stroke="black" stroke-width="0.5" />
    <path d="M165.27 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M169.58 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M170.14 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M165.27 168.11H164.74" stroke="black" stroke-width="0.5" />
    <path d="M164.74 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M188.71 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M188.71 166.9H189.25" stroke="black" stroke-width="0.5" />
    <path d="M189.25 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M189.25 168.11H188.71" stroke="black" stroke-width="0.5" />
    <path d="M193.56 166.9H189.25" stroke="black" stroke-width="0.5" />
    <path d="M188.71 166.9H170.14" stroke="black" stroke-width="0.5" />
    <path d="M169.58 166.9H165.27" stroke="black" stroke-width="0.5" />
    <path d="M188.71 168.11H170.14" stroke="black" stroke-width="0.5" />
    <path d="M169.58 168.11H165.27" stroke="black" stroke-width="0.5" />
    <path d="M193.56 168.11H189.25" stroke="black" stroke-width="0.5" />
    <path d="M165.27 167.79H193.56" stroke="black" stroke-width="0.5" />
    <path d="M193.56 167.52H165.27" stroke="black" stroke-width="0.5" />
    <path d="M193.56 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M165.27 167.52V167.79L193.56 167.52H165.27Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M193.56 167.52L165.27 167.79H193.56V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M165.27 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M169.58 168.11H170.14" stroke="black" stroke-width="0.5" />
    <path d="M169.58 166.9H170.14" stroke="black" stroke-width="0.5" />
    <path d="M164.74 166.9H194.09" stroke="black" stroke-width="0.5" />
    <path d="M194.09 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M194.09 164.76H164.74" stroke="black" stroke-width="0.5" />
    <path d="M164.74 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M113.5 166.9H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.85 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M142.85 164.76H113.5" stroke="black" stroke-width="0.5" />
    <path d="M113.5 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M11.02 166.9H40.38" stroke="black" stroke-width="0.5" />
    <path d="M40.38 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M40.38 164.76H11.02" stroke="black" stroke-width="0.5" />
    <path d="M11.02 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M62.26 166.9H91.62" stroke="black" stroke-width="0.5" />
    <path d="M91.62 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M91.62 164.76H62.26" stroke="black" stroke-width="0.5" />
    <path d="M62.26 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M11.02 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M40.38 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M62.26 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M91.62 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M113.5 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M142.85 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M164.74 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M194.09 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M6.48999 166.9H207.54" stroke="black" stroke-width="0.5" />
    <path d="M-4.71997 168.94H209.58" stroke="black" stroke-width="0.5" />
    <path d="M6.48999 163.7V166.9" stroke="black" stroke-width="0.5" />
    <path d="M-10.86 161.56H8.09003" stroke="black" stroke-width="0.5" />
    <path d="M8.26001 164.76H11.02" stroke="black" stroke-width="0.5" />
    <path d="M40.38 164.76H62.26" stroke="black" stroke-width="0.5" />
    <path d="M91.62 164.76H113.5" stroke="black" stroke-width="0.5" />
    <path d="M142.85 164.76H164.74" stroke="black" stroke-width="0.5" />
    <path d="M194.09 164.76H205.4" stroke="black" stroke-width="0.5" />
    <path d="M205.4 164.76V154.39" stroke="black" stroke-width="0.5" />
    <path d="M209.58 168.94V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M207.54 166.9V71.99" stroke="black" stroke-width="0.5" />
    <path d="M205.4 139.04V109.69" stroke="black" stroke-width="0.5" />
    <path d="M-105.49 163.7H6.48999" stroke="black" stroke-width="0.5" />
    <path d="M208.74 109.69V110.22" stroke="black" stroke-width="0.5" />
    <path d="M208.16 110.22H207.54" stroke="black" stroke-width="0.5" />
    <path d="M207.54 110.22V109.69" stroke="black" stroke-width="0.5" />
    <path d="M207.54 109.69H208.74" stroke="black" stroke-width="0.5" />
    <path d="M207.54 139.04V138.51" stroke="black" stroke-width="0.5" />
    <path d="M207.54 138.51H208.16" stroke="black" stroke-width="0.5" />
    <path d="M207.54 134.2H208.43" stroke="black" stroke-width="0.5" />
    <path d="M207.54 133.64H208.43" stroke="black" stroke-width="0.5" />
    <path d="M208.74 138.51V139.04" stroke="black" stroke-width="0.5" />
    <path d="M208.74 139.04H207.54" stroke="black" stroke-width="0.5" />
    <path d="M208.16 115.07H207.54" stroke="black" stroke-width="0.5" />
    <path d="M207.54 115.07V114.53" stroke="black" stroke-width="0.5" />
    <path d="M207.54 114.53H208.16" stroke="black" stroke-width="0.5" />
    <path d="M208.74 114.53V115.07" stroke="black" stroke-width="0.5" />
    <path d="M207.54 110.22V114.53" stroke="black" stroke-width="0.5" />
    <path d="M207.54 115.07V133.64" stroke="black" stroke-width="0.5" />
    <path d="M207.54 134.2V138.51" stroke="black" stroke-width="0.5" />
    <path d="M208.74 115.07V133.64" stroke="black" stroke-width="0.5" />
    <path d="M208.74 134.2V138.51" stroke="black" stroke-width="0.5" />
    <path d="M208.74 110.22V114.53" stroke="black" stroke-width="0.5" />
    <path d="M208.43 138.51V110.22" stroke="black" stroke-width="0.5" />
    <path d="M208.16 110.22V138.51" stroke="black" stroke-width="0.5" />
    <path d="M208.16 110.22H208.43" stroke="black" stroke-width="0.5" />
    <path
      d="M208.16 138.51H208.43L208.16 110.22V138.51Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M208.16 110.22L208.43 138.51V110.22H208.16Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M208.16 138.51H208.43" stroke="black" stroke-width="0.5" />
    <path d="M208.74 134.2V133.64" stroke="black" stroke-width="0.5" />
    <path d="M207.54 134.2V133.64" stroke="black" stroke-width="0.5" />
    <path d="M207.54 139.04H209.58" stroke="black" stroke-width="0.5" />
    <path d="M207.54 109.69H209.58" stroke="black" stroke-width="0.5" />
    <path d="M3.13 1.41V0.339996" stroke="black" stroke-width="0.5" />
    <path d="M-3.01001 0.339996H3.13" stroke="black" stroke-width="0.5" />
    <path d="M105.92 1.41V0.339996" stroke="black" stroke-width="0.5" />
    <path d="M99.24 1.41V0.339996" stroke="black" stroke-width="0.5" />
    <path d="M99.24 0.339996H105.92" stroke="black" stroke-width="0.5" />
    <path d="M166.28 2.74001V23.47" stroke="black" stroke-width="0.5" />
    <path d="M166.28 32.61V34.25" stroke="black" stroke-width="0.5" />
    <path d="M166.28 35.59V56.69" stroke="black" stroke-width="0.5" />
    <path d="M166.28 65.83V67.25" stroke="black" stroke-width="0.5" />
    <path d="M166.28 68.59V89.51" stroke="black" stroke-width="0.5" />
    <path d="M166.28 98.65V100.29" stroke="black" stroke-width="0.5" />
    <path d="M166.28 101.63V103.27" stroke="black" stroke-width="0.5" />
    <path d="M166.28 112.41V133.24" stroke="black" stroke-width="0.5" />
    <path d="M164.95 1.41V10.9" stroke="black" stroke-width="0.5" />
    <path d="M164.95 12.24V23.47" stroke="black" stroke-width="0.5" />
    <path d="M148.94 2.74001V10.9" stroke="black" stroke-width="0.5" />
    <path d="M148.94 12.24V35.59" stroke="black" stroke-width="0.5" />
    <path d="M147.6 2.74001V34.25" stroke="black" stroke-width="0.5" />
    <path d="M164.95 33.08V56.69" stroke="black" stroke-width="0.5" />
    <path d="M164.95 66.29V89.51" stroke="black" stroke-width="0.5" />
    <path d="M164.95 99.12V102.8" stroke="black" stroke-width="0.5" />
    <path d="M164.95 112.41V133.24" stroke="black" stroke-width="0.5" />
    <path d="M148.94 65.34V73.42" stroke="black" stroke-width="0.5" />
    <path d="M148.94 83.03V117.23" stroke="black" stroke-width="0.5" />
    <path d="M147.6 65.34V71.21" stroke="black" stroke-width="0.5" />
    <path d="M147.6 72.54V73.89" stroke="black" stroke-width="0.5" />
    <path d="M147.6 83.03V115.89" stroke="black" stroke-width="0.5" />
    <path d="M208.5 34.25H166.28" stroke="black" stroke-width="0.5" />
    <path d="M208.5 35.59H166.28" stroke="black" stroke-width="0.5" />
    <path d="M175.89 33.08H166.28" stroke="black" stroke-width="0.5" />
    <path d="M166.28 32.61H175.88" stroke="black" stroke-width="0.5" />
    <path d="M166.28 33.08V32.61" stroke="black" stroke-width="0.5" />
    <path d="M166.28 23.47L168.16 23.65" stroke="black" stroke-width="0.5" />
    <path d="M168.16 23.65L169.96 24.2" stroke="black" stroke-width="0.5" />
    <path d="M169.96 24.2L171.62 25.09" stroke="black" stroke-width="0.5" />
    <path d="M171.62 25.09L173.08 26.28" stroke="black" stroke-width="0.5" />
    <path d="M173.08 26.28L174.27 27.74" stroke="black" stroke-width="0.5" />
    <path d="M174.27 27.74L175.16 29.4" stroke="black" stroke-width="0.5" />
    <path d="M175.16 29.4L175.7 31.2" stroke="black" stroke-width="0.5" />
    <path d="M175.7 31.2L175.89 33.08" stroke="black" stroke-width="0.5" />
    <path d="M166.28 33.08H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 33.61H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 23.47H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 22.94H164.95" stroke="black" stroke-width="0.5" />
    <path d="M205.57 71.99H207.54" stroke="black" stroke-width="0.5" />
    <path d="M205.57 67.25H166.28" stroke="black" stroke-width="0.5" />
    <path d="M205.57 68.59H166.28" stroke="black" stroke-width="0.5" />
    <path d="M175.89 66.29H166.28" stroke="black" stroke-width="0.5" />
    <path d="M166.28 65.83H175.88" stroke="black" stroke-width="0.5" />
    <path d="M166.28 66.29V65.83" stroke="black" stroke-width="0.5" />
    <path d="M166.28 56.69L168.16 56.87" stroke="black" stroke-width="0.5" />
    <path d="M168.16 56.87L169.96 57.42" stroke="black" stroke-width="0.5" />
    <path d="M169.96 57.42L171.62 58.3" stroke="black" stroke-width="0.5" />
    <path d="M171.62 58.3L173.08 59.5" stroke="black" stroke-width="0.5" />
    <path d="M173.08 59.5L174.27 60.96" stroke="black" stroke-width="0.5" />
    <path d="M174.27 60.96L175.16 62.62" stroke="black" stroke-width="0.5" />
    <path d="M175.16 62.62L175.7 64.42" stroke="black" stroke-width="0.5" />
    <path d="M175.7 64.42L175.89 66.29" stroke="black" stroke-width="0.5" />
    <path d="M166.28 66.29H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 66.83H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 56.69H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 56.15H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 98.65V100.29" stroke="black" stroke-width="0.5" />
    <path d="M205.4 100.29H166.28" stroke="black" stroke-width="0.5" />
    <path d="M207.54 101.63H166.28" stroke="black" stroke-width="0.5" />
    <path d="M175.89 99.12H166.28" stroke="black" stroke-width="0.5" />
    <path d="M166.28 98.65H175.88" stroke="black" stroke-width="0.5" />
    <path d="M166.28 99.12V98.65" stroke="black" stroke-width="0.5" />
    <path d="M166.28 89.51L168.16 89.69" stroke="black" stroke-width="0.5" />
    <path d="M168.16 89.69L169.96 90.24" stroke="black" stroke-width="0.5" />
    <path d="M169.96 90.24L171.62 91.13" stroke="black" stroke-width="0.5" />
    <path d="M171.62 91.13L173.08 92.32" stroke="black" stroke-width="0.5" />
    <path d="M173.08 92.32L174.27 93.78" stroke="black" stroke-width="0.5" />
    <path d="M174.27 93.78L175.16 95.44" stroke="black" stroke-width="0.5" />
    <path d="M175.16 95.44L175.7 97.24" stroke="black" stroke-width="0.5" />
    <path d="M175.7 97.24L175.89 99.12" stroke="black" stroke-width="0.5" />
    <path d="M166.28 99.12H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 99.65H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 89.51H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 88.97H164.95" stroke="black" stroke-width="0.5" />
    <path d="M175.89 102.8H166.28" stroke="black" stroke-width="0.5" />
    <path d="M166.28 103.27H175.88" stroke="black" stroke-width="0.5" />
    <path d="M166.28 102.8V103.27" stroke="black" stroke-width="0.5" />
    <path d="M166.28 112.41L168.16 112.23" stroke="black" stroke-width="0.5" />
    <path d="M168.16 112.23L169.96 111.68" stroke="black" stroke-width="0.5" />
    <path d="M169.96 111.68L171.62 110.79" stroke="black" stroke-width="0.5" />
    <path d="M171.62 110.79L173.08 109.6" stroke="black" stroke-width="0.5" />
    <path d="M173.08 109.6L174.27 108.14" stroke="black" stroke-width="0.5" />
    <path d="M174.27 108.14L175.16 106.48" stroke="black" stroke-width="0.5" />
    <path d="M175.16 106.48L175.7 104.68" stroke="black" stroke-width="0.5" />
    <path d="M175.7 104.68L175.89 102.8" stroke="black" stroke-width="0.5" />
    <path d="M166.28 102.8H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 102.27H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 112.41H164.95" stroke="black" stroke-width="0.5" />
    <path d="M166.28 112.94H164.95" stroke="black" stroke-width="0.5" />
    <path d="M207.54 133.24H166.28" stroke="black" stroke-width="0.5" />
    <path d="M164.95 133.24H163.95" stroke="black" stroke-width="0.5" />
    <path d="M154.34 133.24H119.23" stroke="black" stroke-width="0.5" />
    <path d="M148.94 117.23H99.23" stroke="black" stroke-width="0.5" />
    <path d="M89.62 117.23H74.4" stroke="black" stroke-width="0.5" />
    <path d="M64.79 117.23H62.13" stroke="black" stroke-width="0.5" />
    <path d="M147.6 115.89H118.76" stroke="black" stroke-width="0.5" />
    <path d="M117.43 115.89H99.23" stroke="black" stroke-width="0.5" />
    <path d="M90.09 115.89H88.25" stroke="black" stroke-width="0.5" />
    <path d="M86.92 115.89H74.4" stroke="black" stroke-width="0.5" />
    <path d="M65.26 115.89H63.46" stroke="black" stroke-width="0.5" />
    <path d="M109.62 133.24H105.66" stroke="black" stroke-width="0.5" />
    <path d="M96.05 133.24H60.93" stroke="black" stroke-width="0.5" />
    <path d="M207.54 134.57H163.95" stroke="black" stroke-width="0.5" />
    <path d="M154.34 134.57H153.02" stroke="black" stroke-width="0.5" />
    <path d="M151.69 134.57H119.23" stroke="black" stroke-width="0.5" />
    <path d="M110.09 134.57H108.31" stroke="black" stroke-width="0.5" />
    <path d="M106.98 134.57H105.19" stroke="black" stroke-width="0.5" />
    <path d="M96.05 134.57H62.26" stroke="black" stroke-width="0.5" />
    <path d="M154.34 144.18V134.57" stroke="black" stroke-width="0.5" />
    <path d="M154.81 134.57V144.17" stroke="black" stroke-width="0.5" />
    <path d="M154.34 134.57H154.81" stroke="black" stroke-width="0.5" />
    <path d="M163.95 134.57L163.76 136.45" stroke="black" stroke-width="0.5" />
    <path d="M163.76 136.45L163.21 138.25" stroke="black" stroke-width="0.5" />
    <path d="M163.21 138.25L162.33 139.91" stroke="black" stroke-width="0.5" />
    <path d="M162.33 139.91L161.13 141.37" stroke="black" stroke-width="0.5" />
    <path d="M161.13 141.37L159.68 142.56" stroke="black" stroke-width="0.5" />
    <path d="M159.68 142.56L158.01 143.45" stroke="black" stroke-width="0.5" />
    <path d="M158.01 143.45L156.21 144" stroke="black" stroke-width="0.5" />
    <path d="M156.21 144L154.34 144.18" stroke="black" stroke-width="0.5" />
    <path d="M163.95 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M164.48 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M154.34 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M153.8 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M153.02 134.57V164.76" stroke="black" stroke-width="0.5" />
    <path d="M151.69 134.57V164.76" stroke="black" stroke-width="0.5" />
    <path d="M109.62 144.18V134.57" stroke="black" stroke-width="0.5" />
    <path d="M110.09 134.57V144.17" stroke="black" stroke-width="0.5" />
    <path d="M109.62 134.57H110.09" stroke="black" stroke-width="0.5" />
    <path d="M119.23 134.57L119.05 136.45" stroke="black" stroke-width="0.5" />
    <path d="M119.05 136.45L118.5 138.25" stroke="black" stroke-width="0.5" />
    <path d="M118.5 138.25L117.61 139.91" stroke="black" stroke-width="0.5" />
    <path d="M117.61 139.91L116.42 141.37" stroke="black" stroke-width="0.5" />
    <path d="M116.42 141.37L114.96 142.56" stroke="black" stroke-width="0.5" />
    <path d="M114.96 142.56L113.3 143.45" stroke="black" stroke-width="0.5" />
    <path d="M113.3 143.45L111.5 144" stroke="black" stroke-width="0.5" />
    <path d="M111.5 144L109.62 144.18" stroke="black" stroke-width="0.5" />
    <path d="M119.23 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M119.77 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M109.62 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M109.09 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M108.31 134.57V164.76" stroke="black" stroke-width="0.5" />
    <path d="M106.98 134.57L106.97 164.76" stroke="black" stroke-width="0.5" />
    <path d="M106.98 136.65H108.31" stroke="black" stroke-width="0.5" />
    <path d="M105.66 144.18V134.57" stroke="black" stroke-width="0.5" />
    <path d="M105.19 134.57V144.17" stroke="black" stroke-width="0.5" />
    <path d="M105.66 134.57H105.19" stroke="black" stroke-width="0.5" />
    <path d="M96.05 134.57L96.24 136.45" stroke="black" stroke-width="0.5" />
    <path d="M96.24 136.45L96.78 138.25" stroke="black" stroke-width="0.5" />
    <path d="M96.78 138.25L97.67 139.91" stroke="black" stroke-width="0.5" />
    <path d="M97.67 139.91L98.87 141.37" stroke="black" stroke-width="0.5" />
    <path d="M98.87 141.37L100.32 142.56" stroke="black" stroke-width="0.5" />
    <path d="M100.32 142.56L101.98 143.45" stroke="black" stroke-width="0.5" />
    <path d="M101.98 143.45L103.79 144" stroke="black" stroke-width="0.5" />
    <path d="M103.79 144L105.66 144.18" stroke="black" stroke-width="0.5" />
    <path d="M96.05 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M95.52 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M105.66 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M106.19 134.57V133.24" stroke="black" stroke-width="0.5" />
    <path d="M68.53 134.57V164.76" stroke="black" stroke-width="0.5" />
    <path d="M46.12 152.29H50.26" stroke="black" stroke-width="0.5" />
    <path d="M59.87 152.29H60.93" stroke="black" stroke-width="0.5" />
    <path d="M46.12 150.96H50.26" stroke="black" stroke-width="0.5" />
    <path d="M59.4 150.96H60.93" stroke="black" stroke-width="0.5" />
    <path d="M59.87 141.35V150.96" stroke="black" stroke-width="0.5" />
    <path d="M59.4 150.96V141.36" stroke="black" stroke-width="0.5" />
    <path d="M59.87 150.96H59.4" stroke="black" stroke-width="0.5" />
    <path d="M50.26 150.96L50.44 149.08" stroke="black" stroke-width="0.5" />
    <path d="M50.44 149.08L50.99 147.28" stroke="black" stroke-width="0.5" />
    <path d="M50.99 147.28L51.88 145.62" stroke="black" stroke-width="0.5" />
    <path d="M51.88 145.62L53.07 144.16" stroke="black" stroke-width="0.5" />
    <path d="M53.07 144.16L54.53 142.97" stroke="black" stroke-width="0.5" />
    <path d="M54.53 142.97L56.19 142.08" stroke="black" stroke-width="0.5" />
    <path d="M56.19 142.08L57.99 141.54" stroke="black" stroke-width="0.5" />
    <path d="M57.99 141.54L59.87 141.35" stroke="black" stroke-width="0.5" />
    <path d="M59.87 150.96V152.29" stroke="black" stroke-width="0.5" />
    <path d="M60.4 150.96V152.29" stroke="black" stroke-width="0.5" />
    <path d="M50.26 150.96V152.29" stroke="black" stroke-width="0.5" />
    <path d="M49.73 150.96V152.29" stroke="black" stroke-width="0.5" />
    <path d="M63.46 65.34H62.13" stroke="black" stroke-width="0.5" />
    <path d="M147.6 65.87H105.74" stroke="black" stroke-width="0.5" />
    <path d="M147.6 66.14H145.85" stroke="black" stroke-width="0.5" />
    <path d="M145.02 66.14H143.35" stroke="black" stroke-width="0.5" />
    <path d="M142.52 66.14H140.85" stroke="black" stroke-width="0.5" />
    <path d="M140.02 66.14H138.35" stroke="black" stroke-width="0.5" />
    <path d="M137.51 66.14H135.85" stroke="black" stroke-width="0.5" />
    <path d="M135.01 66.14H133.34" stroke="black" stroke-width="0.5" />
    <path d="M132.51 66.14H130.84" stroke="black" stroke-width="0.5" />
    <path d="M130.01 66.14H128.34" stroke="black" stroke-width="0.5" />
    <path d="M127.51 66.14H125.84" stroke="black" stroke-width="0.5" />
    <path d="M125 66.14H123.34" stroke="black" stroke-width="0.5" />
    <path d="M122.5 66.14H120.83" stroke="black" stroke-width="0.5" />
    <path d="M120 66.14H118.33" stroke="black" stroke-width="0.5" />
    <path d="M117.5 66.14H115.83" stroke="black" stroke-width="0.5" />
    <path d="M115 66.14H113.33" stroke="black" stroke-width="0.5" />
    <path d="M112.5 66.14H110.83" stroke="black" stroke-width="0.5" />
    <path d="M109.99 66.14H108.33" stroke="black" stroke-width="0.5" />
    <path d="M107.49 66.14H105.74" stroke="black" stroke-width="0.5" />
    <path d="M99.32 65.87H63.46" stroke="black" stroke-width="0.5" />
    <path d="M147.6 71.21H105.74" stroke="black" stroke-width="0.5" />
    <path d="M99.32 71.21H63.46" stroke="black" stroke-width="0.5" />
    <path d="M105.74 64.8H99.32" stroke="black" stroke-width="0.5" />
    <path d="M147.6 72.54H118.76" stroke="black" stroke-width="0.5" />
    <path d="M117.43 72.54H88.25" stroke="black" stroke-width="0.5" />
    <path d="M86.92 72.54H63.46" stroke="black" stroke-width="0.5" />
    <path d="M147.6 65.34H148.94" stroke="black" stroke-width="0.5" />
    <path d="M137.99 73.42H147.6" stroke="black" stroke-width="0.5" />
    <path d="M147.6 73.89H138.01" stroke="black" stroke-width="0.5" />
    <path d="M147.6 73.42V73.89" stroke="black" stroke-width="0.5" />
    <path d="M147.6 83.03L145.73 82.84" stroke="black" stroke-width="0.5" />
    <path d="M145.73 82.84L143.93 82.3" stroke="black" stroke-width="0.5" />
    <path d="M143.93 82.3L142.26 81.41" stroke="black" stroke-width="0.5" />
    <path d="M142.26 81.41L140.81 80.21" stroke="black" stroke-width="0.5" />
    <path d="M140.81 80.21L139.61 78.76" stroke="black" stroke-width="0.5" />
    <path d="M139.61 78.76L138.73 77.1" stroke="black" stroke-width="0.5" />
    <path d="M138.73 77.1L138.18 75.29" stroke="black" stroke-width="0.5" />
    <path d="M138.18 75.29L137.99 73.42" stroke="black" stroke-width="0.5" />
    <path d="M147.6 73.42H148.94" stroke="black" stroke-width="0.5" />
    <path d="M147.6 72.89H148.94" stroke="black" stroke-width="0.5" />
    <path d="M147.6 83.03H148.94" stroke="black" stroke-width="0.5" />
    <path d="M147.6 83.56H148.94" stroke="black" stroke-width="0.5" />
    <path d="M118.76 72.54V115.89" stroke="black" stroke-width="0.5" />
    <path d="M117.43 72.54V115.89" stroke="black" stroke-width="0.5" />
    <path d="M88.25 72.54V115.89" stroke="black" stroke-width="0.5" />
    <path d="M86.92 72.54V93.19" stroke="black" stroke-width="0.5" />
    <path d="M86.92 95.32V115.89" stroke="black" stroke-width="0.5" />
    <path d="M73.07 73.42H63.46" stroke="black" stroke-width="0.5" />
    <path d="M63.46 73.89H73.06" stroke="black" stroke-width="0.5" />
    <path d="M63.46 73.42V73.89" stroke="black" stroke-width="0.5" />
    <path d="M63.46 83.03L65.34 82.84" stroke="black" stroke-width="0.5" />
    <path d="M65.34 82.84L67.14 82.3" stroke="black" stroke-width="0.5" />
    <path d="M67.14 82.3L68.8 81.41" stroke="black" stroke-width="0.5" />
    <path d="M68.8 81.41L70.26 80.21" stroke="black" stroke-width="0.5" />
    <path d="M70.26 80.21L71.45 78.76" stroke="black" stroke-width="0.5" />
    <path d="M71.45 78.76L72.34 77.1" stroke="black" stroke-width="0.5" />
    <path d="M72.34 77.1L72.89 75.29" stroke="black" stroke-width="0.5" />
    <path d="M72.89 75.29L73.07 73.42" stroke="black" stroke-width="0.5" />
    <path d="M63.46 73.42H62.13" stroke="black" stroke-width="0.5" />
    <path d="M63.46 72.89H62.13" stroke="black" stroke-width="0.5" />
    <path d="M63.46 83.03H62.13" stroke="black" stroke-width="0.5" />
    <path d="M63.46 83.56H62.13" stroke="black" stroke-width="0.5" />
    <path d="M63.46 93.19H86.92" stroke="black" stroke-width="0.5" />
    <path d="M63.46 95.32H86.92" stroke="black" stroke-width="0.5" />
    <path d="M64.79 106.29V115.89" stroke="black" stroke-width="0.5" />
    <path d="M65.26 115.89V106.3" stroke="black" stroke-width="0.5" />
    <path d="M64.79 115.89H65.26" stroke="black" stroke-width="0.5" />
    <path d="M74.4 115.89L74.21 114.02" stroke="black" stroke-width="0.5" />
    <path d="M74.21 114.02L73.66 112.22" stroke="black" stroke-width="0.5" />
    <path d="M73.66 112.22L72.78 110.56" stroke="black" stroke-width="0.5" />
    <path d="M72.78 110.56L71.58 109.1" stroke="black" stroke-width="0.5" />
    <path d="M71.58 109.1L70.13 107.91" stroke="black" stroke-width="0.5" />
    <path d="M70.13 107.91L68.47 107.02" stroke="black" stroke-width="0.5" />
    <path d="M68.47 107.02L66.66 106.47" stroke="black" stroke-width="0.5" />
    <path d="M66.66 106.47L64.79 106.29" stroke="black" stroke-width="0.5" />
    <path d="M64.79 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M64.26 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M74.4 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M74.93 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M89.62 106.29V115.89" stroke="black" stroke-width="0.5" />
    <path d="M90.09 115.89V106.3" stroke="black" stroke-width="0.5" />
    <path d="M89.62 115.89H90.09" stroke="black" stroke-width="0.5" />
    <path d="M99.23 115.89L99.04 114.02" stroke="black" stroke-width="0.5" />
    <path d="M99.04 114.02L98.49 112.22" stroke="black" stroke-width="0.5" />
    <path d="M98.49 112.22L97.61 110.56" stroke="black" stroke-width="0.5" />
    <path d="M97.61 110.56L96.41 109.1" stroke="black" stroke-width="0.5" />
    <path d="M96.41 109.1L94.96 107.91" stroke="black" stroke-width="0.5" />
    <path d="M94.96 107.91L93.29 107.02" stroke="black" stroke-width="0.5" />
    <path d="M93.29 107.02L91.49 106.47" stroke="black" stroke-width="0.5" />
    <path d="M91.49 106.47L89.62 106.29" stroke="black" stroke-width="0.5" />
    <path d="M89.62 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M89.08 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M99.23 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M99.76 115.89V117.23" stroke="black" stroke-width="0.5" />
    <path d="M148.94 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M148.94 10.9H154.51" stroke="black" stroke-width="0.5" />
    <path d="M163.65 10.9H164.95" stroke="black" stroke-width="0.5" />
    <path d="M148.94 12.24H154.51" stroke="black" stroke-width="0.5" />
    <path d="M164.12 12.24H164.95" stroke="black" stroke-width="0.5" />
    <path d="M164.12 1.3V10.9" stroke="black" stroke-width="0.5" />
    <path d="M163.65 10.9V1.31" stroke="black" stroke-width="0.5" />
    <path d="M164.12 10.9H163.65" stroke="black" stroke-width="0.5" />
    <path d="M154.51 10.9L154.69 9.03" stroke="black" stroke-width="0.5" />
    <path d="M154.69 9.03L155.24 7.23" stroke="black" stroke-width="0.5" />
    <path d="M155.24 7.23L156.13 5.57001" stroke="black" stroke-width="0.5" />
    <path d="M156.13 5.57001L157.32 4.11" stroke="black" stroke-width="0.5" />
    <path d="M157.32 4.11L158.78 2.92" stroke="black" stroke-width="0.5" />
    <path d="M158.78 2.92L160.44 2.03" stroke="black" stroke-width="0.5" />
    <path d="M160.44 2.03L162.24 1.48" stroke="black" stroke-width="0.5" />
    <path d="M162.24 1.48L164.12 1.3" stroke="black" stroke-width="0.5" />
    <path d="M164.12 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M164.65 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M154.51 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M153.98 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M148.94 12.24H147.6" stroke="black" stroke-width="0.5" />
    <path d="M84.08 2.74001V11.05" stroke="black" stroke-width="0.5" />
    <path d="M84.08 12.38V30.7" stroke="black" stroke-width="0.5" />
    <path d="M84.08 41.49V49.71" stroke="black" stroke-width="0.5" />
    <path d="M85.41 2.74001V30.7" stroke="black" stroke-width="0.5" />
    <path d="M85.41 40.15V49.71" stroke="black" stroke-width="0.5" />
    <path d="M81.94 2.74001V11.05" stroke="black" stroke-width="0.5" />
    <path d="M81.94 41.49V49.71" stroke="black" stroke-width="0.5" />
    <path d="M80.61 2.74001V12.38" stroke="black" stroke-width="0.5" />
    <path d="M80.61 40.15V49.71" stroke="black" stroke-width="0.5" />
    <path d="M59.88 1.41V10.9" stroke="black" stroke-width="0.5" />
    <path d="M44.48 10.9H48.69" stroke="black" stroke-width="0.5" />
    <path d="M57.83 10.9H59.88" stroke="black" stroke-width="0.5" />
    <path d="M43.96 12.24H48.69" stroke="black" stroke-width="0.5" />
    <path d="M58.29 12.24H61.22" stroke="black" stroke-width="0.5" />
    <path d="M58.29 1.3V10.9" stroke="black" stroke-width="0.5" />
    <path d="M57.83 10.9V1.31" stroke="black" stroke-width="0.5" />
    <path d="M58.29 10.9H57.83" stroke="black" stroke-width="0.5" />
    <path d="M48.69 10.9L48.87 9.03" stroke="black" stroke-width="0.5" />
    <path d="M48.87 9.03L49.42 7.23" stroke="black" stroke-width="0.5" />
    <path d="M49.42 7.23L50.31 5.57001" stroke="black" stroke-width="0.5" />
    <path d="M50.31 5.57001L51.5 4.11" stroke="black" stroke-width="0.5" />
    <path d="M51.5 4.11L52.96 2.92" stroke="black" stroke-width="0.5" />
    <path d="M52.96 2.92L54.62 2.03" stroke="black" stroke-width="0.5" />
    <path d="M54.62 2.03L56.42 1.48" stroke="black" stroke-width="0.5" />
    <path d="M56.42 1.48L58.29 1.3" stroke="black" stroke-width="0.5" />
    <path d="M58.29 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M58.83 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M48.69 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M48.15 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M59.88 2.74001H60.46" stroke="black" stroke-width="0.5" />
    <path d="M60.55 2.74001V11.57" stroke="black" stroke-width="0.5" />
    <path d="M58.83 11.57H60.55" stroke="black" stroke-width="0.5" />
    <path d="M48.15 11.57H45.01" stroke="black" stroke-width="0.5" />
    <path d="M44.48 11.57H44.22" stroke="black" stroke-width="0.5" />
    <path d="M61.22 2.74001V12.24" stroke="black" stroke-width="0.5" />
    <path d="M45.01 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M44.48 10.9V12.24" stroke="black" stroke-width="0.5" />
    <path d="M34.18 1.41L44.48 10.9" stroke="black" stroke-width="0.5" />
    <path d="M33.66 2.74001L43.96 12.24" stroke="black" stroke-width="0.5" />
    <path d="M33.66 1.84L44.22 11.57" stroke="black" stroke-width="0.5" />
    <path d="M33.66 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M81.94 11.05H84.08" stroke="black" stroke-width="0.5" />
    <path d="M80.61 12.38H85.41" stroke="black" stroke-width="0.5" />
    <path d="M80.61 40.15H85.41" stroke="black" stroke-width="0.5" />
    <path d="M81.94 41.49H84.08" stroke="black" stroke-width="0.5" />
    <path d="M83.01 12.38H84.41" stroke="black" stroke-width="0.5" />
    <path d="M85.24 12.38H86.63" stroke="black" stroke-width="0.5" />
    <path d="M86.63 12.38V13.62" stroke="black" stroke-width="0.5" />
    <path d="M86.63 14.45V16.12" stroke="black" stroke-width="0.5" />
    <path d="M86.63 16.95V18.62" stroke="black" stroke-width="0.5" />
    <path d="M86.63 19.46V21.12" stroke="black" stroke-width="0.5" />
    <path d="M86.63 21.96V23.62" stroke="black" stroke-width="0.5" />
    <path d="M86.63 24.46V26.13" stroke="black" stroke-width="0.5" />
    <path d="M86.63 26.96V28.63" stroke="black" stroke-width="0.5" />
    <path d="M86.63 29.46V30.7" stroke="black" stroke-width="0.5" />
    <path d="M86.63 30.7H85.24" stroke="black" stroke-width="0.5" />
    <path d="M84.41 30.7H83.01" stroke="black" stroke-width="0.5" />
    <path d="M83.01 30.7V29.46" stroke="black" stroke-width="0.5" />
    <path d="M83.01 28.63V26.96" stroke="black" stroke-width="0.5" />
    <path d="M83.01 26.13V24.46" stroke="black" stroke-width="0.5" />
    <path d="M83.01 23.62V21.96" stroke="black" stroke-width="0.5" />
    <path d="M83.01 21.12V19.46" stroke="black" stroke-width="0.5" />
    <path d="M83.01 18.62V16.95" stroke="black" stroke-width="0.5" />
    <path d="M83.01 16.12V14.45" stroke="black" stroke-width="0.5" />
    <path d="M83.01 13.62V12.38" stroke="black" stroke-width="0.5" />
    <path d="M83.01 30.7H84.08" stroke="black" stroke-width="0.5" />
    <path d="M83.54 30.7V40.15" stroke="black" stroke-width="0.5" />
    <path d="M78.85 40.1V49.71" stroke="black" stroke-width="0.5" />
    <path d="M78.38 49.71V40.12" stroke="black" stroke-width="0.5" />
    <path d="M78.85 49.71H78.38" stroke="black" stroke-width="0.5" />
    <path d="M69.24 49.71L69.43 47.84" stroke="black" stroke-width="0.5" />
    <path d="M69.43 47.84L69.97 46.04" stroke="black" stroke-width="0.5" />
    <path d="M69.97 46.04L70.86 44.37" stroke="black" stroke-width="0.5" />
    <path d="M70.86 44.37L72.06 42.92" stroke="black" stroke-width="0.5" />
    <path d="M72.06 42.92L73.51 41.72" stroke="black" stroke-width="0.5" />
    <path d="M73.51 41.72L75.17 40.84" stroke="black" stroke-width="0.5" />
    <path d="M75.17 40.84L76.98 40.29" stroke="black" stroke-width="0.5" />
    <path d="M76.98 40.29L78.85 40.1" stroke="black" stroke-width="0.5" />
    <path d="M78.85 49.71V51.05" stroke="black" stroke-width="0.5" />
    <path d="M79.38 49.71V51.05" stroke="black" stroke-width="0.5" />
    <path d="M69.24 49.71V51.05" stroke="black" stroke-width="0.5" />
    <path d="M68.71 49.71V51.05" stroke="black" stroke-width="0.5" />
    <path d="M147.6 34.25H146.68" stroke="black" stroke-width="0.5" />
    <path d="M137.08 34.25H134.46" stroke="black" stroke-width="0.5" />
    <path d="M148.94 35.59H146.68" stroke="black" stroke-width="0.5" />
    <path d="M137.54 35.59H135.79" stroke="black" stroke-width="0.5" />
    <path d="M134.46 34.25V52.43" stroke="black" stroke-width="0.5" />
    <path d="M135.79 35.59V52.43" stroke="black" stroke-width="0.5" />
    <path d="M135.79 52.43H134.46" stroke="black" stroke-width="0.5" />
    <path d="M125.12 49.71V51.05" stroke="black" stroke-width="0.5" />
    <path d="M85.41 48.45H87.34" stroke="black" stroke-width="0.5" />
    <path d="M88.17 48.45H89.84" stroke="black" stroke-width="0.5" />
    <path d="M90.67 48.45H92.34" stroke="black" stroke-width="0.5" />
    <path d="M93.17 48.45H94.84" stroke="black" stroke-width="0.5" />
    <path d="M95.68 48.45H97.34" stroke="black" stroke-width="0.5" />
    <path d="M98.18 48.45H99.85" stroke="black" stroke-width="0.5" />
    <path d="M100.68 48.45H102.35" stroke="black" stroke-width="0.5" />
    <path d="M103.18 48.45H104.85" stroke="black" stroke-width="0.5" />
    <path d="M105.68 48.45H107.35" stroke="black" stroke-width="0.5" />
    <path d="M108.19 48.45H109.85" stroke="black" stroke-width="0.5" />
    <path d="M110.69 48.45H112.36" stroke="black" stroke-width="0.5" />
    <path d="M113.19 48.45H114.86" stroke="black" stroke-width="0.5" />
    <path d="M115.69 48.45H117.36" stroke="black" stroke-width="0.5" />
    <path d="M118.19 48.45H119.86" stroke="black" stroke-width="0.5" />
    <path d="M120.69 48.45H122.36" stroke="black" stroke-width="0.5" />
    <path d="M123.2 48.45H125.12" stroke="black" stroke-width="0.5" />
    <path d="M125.12 48.45V49.96" stroke="black" stroke-width="0.5" />
    <path d="M125.12 50.79V52.3" stroke="black" stroke-width="0.5" />
    <path d="M125.12 52.3H123.2" stroke="black" stroke-width="0.5" />
    <path d="M122.36 52.3H120.69" stroke="black" stroke-width="0.5" />
    <path d="M119.86 52.3H118.19" stroke="black" stroke-width="0.5" />
    <path d="M117.36 52.3H115.69" stroke="black" stroke-width="0.5" />
    <path d="M114.86 52.3H113.19" stroke="black" stroke-width="0.5" />
    <path d="M112.36 52.3H110.69" stroke="black" stroke-width="0.5" />
    <path d="M109.85 52.3H108.19" stroke="black" stroke-width="0.5" />
    <path d="M107.35 52.3H105.68" stroke="black" stroke-width="0.5" />
    <path d="M104.85 52.3H103.18" stroke="black" stroke-width="0.5" />
    <path d="M102.35 52.3H100.68" stroke="black" stroke-width="0.5" />
    <path d="M99.85 52.3H98.18" stroke="black" stroke-width="0.5" />
    <path d="M97.34 52.3H95.68" stroke="black" stroke-width="0.5" />
    <path d="M94.84 52.3H93.17" stroke="black" stroke-width="0.5" />
    <path d="M92.34 52.3H90.67" stroke="black" stroke-width="0.5" />
    <path d="M89.84 52.3H88.17" stroke="black" stroke-width="0.5" />
    <path d="M87.34 52.3H85.41" stroke="black" stroke-width="0.5" />
    <path d="M85.41 52.3V50.79" stroke="black" stroke-width="0.5" />
    <path d="M85.41 49.96V48.45" stroke="black" stroke-width="0.5" />
    <path d="M125.12 51.72H134.46" stroke="black" stroke-width="0.5" />
    <path d="M137.08 45.19V35.59" stroke="black" stroke-width="0.5" />
    <path d="M137.54 35.59V45.18" stroke="black" stroke-width="0.5" />
    <path d="M137.08 35.59H137.54" stroke="black" stroke-width="0.5" />
    <path d="M146.68 35.59L146.5 37.46" stroke="black" stroke-width="0.5" />
    <path d="M146.5 37.46L145.95 39.26" stroke="black" stroke-width="0.5" />
    <path d="M145.95 39.26L145.06 40.93" stroke="black" stroke-width="0.5" />
    <path d="M145.06 40.93L143.87 42.38" stroke="black" stroke-width="0.5" />
    <path d="M143.87 42.38L142.41 43.58" stroke="black" stroke-width="0.5" />
    <path d="M142.41 43.58L140.75 44.46" stroke="black" stroke-width="0.5" />
    <path d="M140.75 44.46L138.95 45.01" stroke="black" stroke-width="0.5" />
    <path d="M138.95 45.01L137.08 45.19" stroke="black" stroke-width="0.5" />
    <path d="M137.08 35.59V34.25" stroke="black" stroke-width="0.5" />
    <path d="M136.54 35.59V34.25" stroke="black" stroke-width="0.5" />
    <path d="M146.68 35.59V34.25" stroke="black" stroke-width="0.5" />
    <path d="M147.22 35.59V34.25" stroke="black" stroke-width="0.5" />
    <path d="M147.6 9.00999H93.36" stroke="black" stroke-width="0.5" />
    <path d="M93.36 9.00999V41.69" stroke="black" stroke-width="0.5" />
    <path d="M93.36 41.69H134.46" stroke="black" stroke-width="0.5" />
    <path d="M80.04 92.6H75.65" stroke="black" stroke-width="0.5" />
    <path d="M75.65 92.6L75.61 92.61" stroke="black" stroke-width="0.5" />
    <path d="M75.61 92.61L75.59 92.63" stroke="black" stroke-width="0.5" />
    <path d="M75.59 92.63L75.58 92.67" stroke="black" stroke-width="0.5" />
    <path d="M75.58 92.67V93.01" stroke="black" stroke-width="0.5" />
    <path d="M75.58 93.01H75.23" stroke="black" stroke-width="0.5" />
    <path d="M75.23 93.01V92.6" stroke="black" stroke-width="0.5" />
    <path d="M75.23 92.6L75.28 92.42" stroke="black" stroke-width="0.5" />
    <path d="M75.28 92.42L75.41 92.3" stroke="black" stroke-width="0.5" />
    <path d="M75.41 92.3L75.58 92.25" stroke="black" stroke-width="0.5" />
    <path d="M75.58 92.25H80.04" stroke="black" stroke-width="0.5" />
    <path d="M80.04 92.25H84.84" stroke="black" stroke-width="0.5" />
    <path d="M84.84 92.25L85.01 92.3" stroke="black" stroke-width="0.5" />
    <path d="M85.01 92.3L85.14 92.42" stroke="black" stroke-width="0.5" />
    <path d="M85.14 92.42L85.19 92.6" stroke="black" stroke-width="0.5" />
    <path d="M85.19 92.6V93.01" stroke="black" stroke-width="0.5" />
    <path d="M85.19 93.01H84.84" stroke="black" stroke-width="0.5" />
    <path d="M84.84 93.01V92.67" stroke="black" stroke-width="0.5" />
    <path d="M84.84 92.67L84.83 92.63" stroke="black" stroke-width="0.5" />
    <path d="M84.83 92.63L84.8 92.61" stroke="black" stroke-width="0.5" />
    <path d="M84.8 92.61L84.77 92.6" stroke="black" stroke-width="0.5" />
    <path d="M84.77 92.6H80.04" stroke="black" stroke-width="0.5" />
    <path d="M86.33 80.34L86.34 80.3" stroke="black" stroke-width="0.5" />
    <path d="M86.34 80.3L86.36 80.28" stroke="black" stroke-width="0.5" />
    <path d="M86.36 80.28L86.4 80.27" stroke="black" stroke-width="0.5" />
    <path d="M86.4 80.27H86.75" stroke="black" stroke-width="0.5" />
    <path d="M86.75 80.27V79.92" stroke="black" stroke-width="0.5" />
    <path d="M86.75 79.92H86.33" stroke="black" stroke-width="0.5" />
    <path d="M86.33 79.92L86.16 79.97" stroke="black" stroke-width="0.5" />
    <path d="M86.16 79.97L86.03 80.09" stroke="black" stroke-width="0.5" />
    <path d="M86.03 80.09L85.98 80.27" stroke="black" stroke-width="0.5" />
    <path d="M85.98 80.27V91.13" stroke="black" stroke-width="0.5" />
    <path d="M85.98 91.13L86.03 91.3" stroke="black" stroke-width="0.5" />
    <path d="M86.03 91.3L86.16 91.43" stroke="black" stroke-width="0.5" />
    <path d="M86.16 91.43L86.33 91.48" stroke="black" stroke-width="0.5" />
    <path d="M86.33 91.48H86.75" stroke="black" stroke-width="0.5" />
    <path d="M86.75 91.48V91.13" stroke="black" stroke-width="0.5" />
    <path d="M86.75 91.13H86.4" stroke="black" stroke-width="0.5" />
    <path d="M86.4 91.13L86.36 91.12" stroke="black" stroke-width="0.5" />
    <path d="M86.36 91.12L86.34 91.09" stroke="black" stroke-width="0.5" />
    <path d="M86.34 91.09L86.33 91.06" stroke="black" stroke-width="0.5" />
    <path d="M86.33 91.06V80.34" stroke="black" stroke-width="0.5" />
    <path d="M83.33 90.74L83.4 90.18" stroke="black" stroke-width="0.5" />
    <path d="M83.4 90.18L83.46 89.77" stroke="black" stroke-width="0.5" />
    <path d="M83.46 89.77L83.52 89.41" stroke="black" stroke-width="0.5" />
    <path d="M83.52 89.41L83.58 89.1" stroke="black" stroke-width="0.5" />
    <path d="M83.58 89.1L83.64 88.86" stroke="black" stroke-width="0.5" />
    <path d="M83.64 88.86L83.68 88.68" stroke="black" stroke-width="0.5" />
    <path d="M83.68 88.68L83.72 88.55" stroke="black" stroke-width="0.5" />
    <path d="M83.72 88.55L83.74 88.45" stroke="black" stroke-width="0.5" />
    <path d="M83.74 88.45L83.76 88.34" stroke="black" stroke-width="0.5" />
    <path d="M83.76 88.34L83.77 88.21" stroke="black" stroke-width="0.5" />
    <path d="M83.77 88.21L83.78 88" stroke="black" stroke-width="0.5" />
    <path d="M83.78 88V87.77" stroke="black" stroke-width="0.5" />
    <path d="M83.78 87.77L83.76 87.5" stroke="black" stroke-width="0.5" />
    <path d="M83.76 87.5L83.72 87.2" stroke="black" stroke-width="0.5" />
    <path d="M83.72 87.2L83.65 86.89" stroke="black" stroke-width="0.5" />
    <path d="M83.65 86.89L83.56 86.58" stroke="black" stroke-width="0.5" />
    <path d="M83.56 86.58L83.43 86.27" stroke="black" stroke-width="0.5" />
    <path d="M83.43 86.27L83.27 85.99" stroke="black" stroke-width="0.5" />
    <path d="M83.27 85.99L83.09 85.74" stroke="black" stroke-width="0.5" />
    <path d="M83.09 85.74L82.88 85.52" stroke="black" stroke-width="0.5" />
    <path d="M82.88 85.52L82.66 85.34" stroke="black" stroke-width="0.5" />
    <path d="M82.66 85.34L82.44 85.21" stroke="black" stroke-width="0.5" />
    <path d="M82.44 85.21L82.22 85.13" stroke="black" stroke-width="0.5" />
    <path d="M82.22 85.13L82 85.11" stroke="black" stroke-width="0.5" />
    <path d="M82 85.11L81.78 85.14" stroke="black" stroke-width="0.5" />
    <path d="M81.78 85.14L81.56 85.21" stroke="black" stroke-width="0.5" />
    <path d="M81.56 85.21L81.33 85.34" stroke="black" stroke-width="0.5" />
    <path d="M81.33 85.34L81.11 85.52" stroke="black" stroke-width="0.5" />
    <path d="M81.11 85.52L80.91 85.74" stroke="black" stroke-width="0.5" />
    <path d="M80.91 85.74L80.72 85.99" stroke="black" stroke-width="0.5" />
    <path d="M80.72 85.99L80.56 86.27" stroke="black" stroke-width="0.5" />
    <path d="M80.56 86.27L80.44 86.57" stroke="black" stroke-width="0.5" />
    <path d="M80.44 86.57L80.34 86.89" stroke="black" stroke-width="0.5" />
    <path d="M80.34 86.89L80.27 87.2" stroke="black" stroke-width="0.5" />
    <path d="M80.27 87.2L80.23 87.5" stroke="black" stroke-width="0.5" />
    <path d="M80.23 87.5L80.22 87.77" stroke="black" stroke-width="0.5" />
    <path d="M80.22 87.77V88.01" stroke="black" stroke-width="0.5" />
    <path d="M80.22 88.01L80.23 88.21" stroke="black" stroke-width="0.5" />
    <path d="M80.23 88.21L80.24 88.34" stroke="black" stroke-width="0.5" />
    <path d="M80.24 88.34L80.25 88.44" stroke="black" stroke-width="0.5" />
    <path d="M80.25 88.44L80.28 88.55" stroke="black" stroke-width="0.5" />
    <path d="M80.28 88.55L80.31 88.68" stroke="black" stroke-width="0.5" />
    <path d="M80.31 88.68L80.36 88.87" stroke="black" stroke-width="0.5" />
    <path d="M80.36 88.87L80.42 89.11" stroke="black" stroke-width="0.5" />
    <path d="M80.42 89.11L80.48 89.41" stroke="black" stroke-width="0.5" />
    <path d="M80.48 89.41L80.54 89.77" stroke="black" stroke-width="0.5" />
    <path d="M80.54 89.77L80.59 90.18" stroke="black" stroke-width="0.5" />
    <path d="M80.59 90.18L80.65 90.62" stroke="black" stroke-width="0.5" />
    <path d="M80.65 90.62V90.74" stroke="black" stroke-width="0.5" />
    <path d="M80.18 90.89L80.14 90.9" stroke="black" stroke-width="0.5" />
    <path d="M80.14 90.9L80.1 90.91" stroke="black" stroke-width="0.5" />
    <path d="M80.1 90.91L80.07 90.93" stroke="black" stroke-width="0.5" />
    <path d="M80.07 90.93L80.04 90.94" stroke="black" stroke-width="0.5" />
    <path d="M80.04 90.94L80 90.96" stroke="black" stroke-width="0.5" />
    <path d="M80 90.96L79.97 90.98" stroke="black" stroke-width="0.5" />
    <path d="M79.97 90.98L79.94 91.01" stroke="black" stroke-width="0.5" />
    <path d="M79.94 91.01L79.9 91.04" stroke="black" stroke-width="0.5" />
    <path d="M79.9 91.04L79.86 91.08" stroke="black" stroke-width="0.5" />
    <path d="M79.86 91.08L79.81 91.13" stroke="black" stroke-width="0.5" />
    <path d="M79.81 91.13L79.76 91.18" stroke="black" stroke-width="0.5" />
    <path d="M79.76 91.18L79.71 91.24" stroke="black" stroke-width="0.5" />
    <path d="M79.71 91.24L79.67 91.3" stroke="black" stroke-width="0.5" />
    <path d="M79.67 91.3L79.62 91.36" stroke="black" stroke-width="0.5" />
    <path d="M79.62 91.36L79.58 91.43" stroke="black" stroke-width="0.5" />
    <path d="M79.58 91.43L79.55 91.51" stroke="black" stroke-width="0.5" />
    <path d="M79.55 91.51L79.52 91.58" stroke="black" stroke-width="0.5" />
    <path d="M79.52 91.58L79.5 91.66" stroke="black" stroke-width="0.5" />
    <path d="M79.5 91.66L79.49 91.73" stroke="black" stroke-width="0.5" />
    <path d="M79.49 91.73L79.48 91.81" stroke="black" stroke-width="0.5" />
    <path d="M79.48 91.81L79.47 91.89" stroke="black" stroke-width="0.5" />
    <path d="M79.47 91.89V91.96" stroke="black" stroke-width="0.5" />
    <path d="M79.47 91.96V92.04" stroke="black" stroke-width="0.5" />
    <path d="M79.47 92.04V92.11" stroke="black" stroke-width="0.5" />
    <path d="M79.47 92.11V92.18" stroke="black" stroke-width="0.5" />
    <path d="M79.47 92.18V92.24" stroke="black" stroke-width="0.5" />
    <path d="M79.47 92.24L79.48 92.3" stroke="black" stroke-width="0.5" />
    <path d="M79.48 92.3V92.36" stroke="black" stroke-width="0.5" />
    <path d="M79.48 92.36V92.42" stroke="black" stroke-width="0.5" />
    <path d="M79.48 92.42L79.49 92.47" stroke="black" stroke-width="0.5" />
    <path d="M79.49 92.47V92.52" stroke="black" stroke-width="0.5" />
    <path d="M79.49 92.52L79.5 92.57" stroke="black" stroke-width="0.5" />
    <path d="M79.5 92.57L79.51 92.61" stroke="black" stroke-width="0.5" />
    <path d="M79.51 92.61L79.52 92.65" stroke="black" stroke-width="0.5" />
    <path d="M79.52 92.65L79.53 92.69" stroke="black" stroke-width="0.5" />
    <path d="M79.53 92.69L79.55 92.72" stroke="black" stroke-width="0.5" />
    <path d="M79.55 92.72L79.57 92.75" stroke="black" stroke-width="0.5" />
    <path d="M79.57 92.75L79.6 92.78" stroke="black" stroke-width="0.5" />
    <path d="M79.6 92.78L79.63 92.8" stroke="black" stroke-width="0.5" />
    <path d="M79.63 92.8L79.68 92.82" stroke="black" stroke-width="0.5" />
    <path d="M79.68 92.82L79.73 92.84" stroke="black" stroke-width="0.5" />
    <path d="M79.73 92.84L79.79 92.86" stroke="black" stroke-width="0.5" />
    <path d="M79.79 92.86L79.86 92.87" stroke="black" stroke-width="0.5" />
    <path d="M79.86 92.87L79.95 92.88" stroke="black" stroke-width="0.5" />
    <path d="M79.95 92.88L80.05 92.89" stroke="black" stroke-width="0.5" />
    <path d="M80.05 92.89L80.16 92.9" stroke="black" stroke-width="0.5" />
    <path d="M80.16 92.9L80.29 92.91" stroke="black" stroke-width="0.5" />
    <path d="M80.29 92.91L80.43 92.92" stroke="black" stroke-width="0.5" />
    <path d="M80.43 92.92H80.59" stroke="black" stroke-width="0.5" />
    <path d="M80.59 92.92L80.76 92.93" stroke="black" stroke-width="0.5" />
    <path d="M80.76 92.93L80.95 92.94" stroke="black" stroke-width="0.5" />
    <path d="M80.95 92.94H81.15" stroke="black" stroke-width="0.5" />
    <path d="M81.15 92.94H81.35" stroke="black" stroke-width="0.5" />
    <path d="M81.35 92.94L81.56 92.95" stroke="black" stroke-width="0.5" />
    <path d="M81.56 92.95H81.78" stroke="black" stroke-width="0.5" />
    <path d="M81.78 92.95H82" stroke="black" stroke-width="0.5" />
    <path d="M82 92.95H82.22" stroke="black" stroke-width="0.5" />
    <path d="M82.22 92.95H82.43" stroke="black" stroke-width="0.5" />
    <path d="M82.43 92.95L82.65 92.94" stroke="black" stroke-width="0.5" />
    <path d="M82.65 92.94H82.85" stroke="black" stroke-width="0.5" />
    <path d="M82.85 92.94H83.05" stroke="black" stroke-width="0.5" />
    <path d="M83.05 92.94L83.24 92.93" stroke="black" stroke-width="0.5" />
    <path d="M83.24 92.93L83.41 92.92" stroke="black" stroke-width="0.5" />
    <path d="M83.41 92.92H83.57" stroke="black" stroke-width="0.5" />
    <path d="M83.57 92.92L83.71 92.91" stroke="black" stroke-width="0.5" />
    <path d="M83.71 92.91L83.84 92.9" stroke="black" stroke-width="0.5" />
    <path d="M83.84 92.9L83.95 92.89" stroke="black" stroke-width="0.5" />
    <path d="M83.95 92.89L84.05 92.88" stroke="black" stroke-width="0.5" />
    <path d="M84.05 92.88L84.13 92.87" stroke="black" stroke-width="0.5" />
    <path d="M84.13 92.87L84.21 92.85" stroke="black" stroke-width="0.5" />
    <path d="M84.21 92.85L84.27 92.83" stroke="black" stroke-width="0.5" />
    <path d="M84.27 92.83L84.32 92.81" stroke="black" stroke-width="0.5" />
    <path d="M84.32 92.81L84.36 92.79" stroke="black" stroke-width="0.5" />
    <path d="M84.36 92.79L84.4 92.77" stroke="black" stroke-width="0.5" />
    <path d="M84.4 92.77L84.42 92.74" stroke="black" stroke-width="0.5" />
    <path d="M84.42 92.74L84.45 92.71" stroke="black" stroke-width="0.5" />
    <path d="M84.45 92.71L84.46 92.68" stroke="black" stroke-width="0.5" />
    <path d="M84.46 92.68L84.48 92.65" stroke="black" stroke-width="0.5" />
    <path d="M84.48 92.65V92.61" stroke="black" stroke-width="0.5" />
    <path d="M84.48 92.61L84.49 92.56" stroke="black" stroke-width="0.5" />
    <path d="M84.49 92.56L84.5 92.52" stroke="black" stroke-width="0.5" />
    <path d="M84.5 92.52V92.47" stroke="black" stroke-width="0.5" />
    <path d="M84.5 92.47L84.51 92.41" stroke="black" stroke-width="0.5" />
    <path d="M84.51 92.41V92.36" stroke="black" stroke-width="0.5" />
    <path d="M84.51 92.36L84.52 92.3" stroke="black" stroke-width="0.5" />
    <path d="M84.52 92.3V92.24" stroke="black" stroke-width="0.5" />
    <path d="M84.52 92.24V92.17" stroke="black" stroke-width="0.5" />
    <path d="M84.52 92.17V92.1" stroke="black" stroke-width="0.5" />
    <path d="M84.52 92.1V92.03" stroke="black" stroke-width="0.5" />
    <path d="M84.52 92.03V91.96" stroke="black" stroke-width="0.5" />
    <path d="M84.52 91.96V91.89" stroke="black" stroke-width="0.5" />
    <path d="M84.52 91.89L84.51 91.81" stroke="black" stroke-width="0.5" />
    <path d="M84.51 91.81L84.5 91.74" stroke="black" stroke-width="0.5" />
    <path d="M84.5 91.74L84.49 91.66" stroke="black" stroke-width="0.5" />
    <path d="M84.49 91.66L84.47 91.59" stroke="black" stroke-width="0.5" />
    <path d="M84.47 91.59L84.44 91.51" stroke="black" stroke-width="0.5" />
    <path d="M84.44 91.51L84.41 91.44" stroke="black" stroke-width="0.5" />
    <path d="M84.41 91.44L84.37 91.37" stroke="black" stroke-width="0.5" />
    <path d="M84.37 91.37L84.32 91.31" stroke="black" stroke-width="0.5" />
    <path d="M84.32 91.31L84.27 91.24" stroke="black" stroke-width="0.5" />
    <path d="M84.27 91.24L84.21 91.18" stroke="black" stroke-width="0.5" />
    <path d="M84.21 91.18L84.15 91.12" stroke="black" stroke-width="0.5" />
    <path d="M84.15 91.12L84.09 91.07" stroke="black" stroke-width="0.5" />
    <path d="M84.09 91.07L84.02 91.02" stroke="black" stroke-width="0.5" />
    <path d="M84.02 91.02L83.95 90.98" stroke="black" stroke-width="0.5" />
    <path d="M83.95 90.98L83.88 90.94" stroke="black" stroke-width="0.5" />
    <path d="M83.88 90.94L83.81 90.91" stroke="black" stroke-width="0.5" />
    <path d="M83.81 90.91L83.74 90.87" stroke="black" stroke-width="0.5" />
    <path d="M83.74 90.87L83.67 90.85" stroke="black" stroke-width="0.5" />
    <path d="M83.67 90.85L83.6 90.82" stroke="black" stroke-width="0.5" />
    <path d="M83.6 90.82L83.52 90.8" stroke="black" stroke-width="0.5" />
    <path d="M83.52 90.8L83.45 90.77" stroke="black" stroke-width="0.5" />
    <path d="M83.45 90.77L83.37 90.75" stroke="black" stroke-width="0.5" />
    <path d="M83.37 90.75L83.29 90.73" stroke="black" stroke-width="0.5" />
    <path d="M83.29 90.73L83.21 90.71" stroke="black" stroke-width="0.5" />
    <path d="M83.21 90.71L83.13 90.69" stroke="black" stroke-width="0.5" />
    <path d="M83.13 90.69L83.05 90.68" stroke="black" stroke-width="0.5" />
    <path d="M83.05 90.68L82.97 90.66" stroke="black" stroke-width="0.5" />
    <path d="M82.97 90.66L82.88 90.65" stroke="black" stroke-width="0.5" />
    <path d="M82.88 90.65L82.79 90.63" stroke="black" stroke-width="0.5" />
    <path d="M82.79 90.63L82.7 90.62" stroke="black" stroke-width="0.5" />
    <path d="M82.7 90.62L82.6 90.61" stroke="black" stroke-width="0.5" />
    <path d="M82.6 90.61L82.51 90.6" stroke="black" stroke-width="0.5" />
    <path d="M82.51 90.6L82.41 90.59" stroke="black" stroke-width="0.5" />
    <path d="M82.41 90.59H82.3" stroke="black" stroke-width="0.5" />
    <path d="M82.3 90.59L82.2 90.58" stroke="black" stroke-width="0.5" />
    <path d="M82.2 90.58H82.1" stroke="black" stroke-width="0.5" />
    <path d="M82.1 90.58H81.99" stroke="black" stroke-width="0.5" />
    <path d="M81.99 90.58H81.89" stroke="black" stroke-width="0.5" />
    <path d="M81.89 90.58H81.79" stroke="black" stroke-width="0.5" />
    <path d="M81.79 90.58H81.68" stroke="black" stroke-width="0.5" />
    <path d="M81.68 90.58L81.58 90.59" stroke="black" stroke-width="0.5" />
    <path d="M81.58 90.59L81.49 90.6" stroke="black" stroke-width="0.5" />
    <path d="M81.49 90.6H81.39" stroke="black" stroke-width="0.5" />
    <path d="M81.39 90.6L81.3 90.61" stroke="black" stroke-width="0.5" />
    <path d="M81.3 90.61L81.21 90.62" stroke="black" stroke-width="0.5" />
    <path d="M81.21 90.62L81.13 90.63" stroke="black" stroke-width="0.5" />
    <path d="M81.13 90.63L81.06 90.64" stroke="black" stroke-width="0.5" />
    <path d="M81.06 90.64L80.98 90.66" stroke="black" stroke-width="0.5" />
    <path d="M80.98 90.66L80.91 90.67" stroke="black" stroke-width="0.5" />
    <path d="M80.91 90.67L80.84 90.69" stroke="black" stroke-width="0.5" />
    <path d="M80.84 90.69L80.78 90.7" stroke="black" stroke-width="0.5" />
    <path d="M80.78 90.7L80.71 90.72" stroke="black" stroke-width="0.5" />
    <path d="M80.71 90.72L80.65 90.74" stroke="black" stroke-width="0.5" />
    <path d="M80.65 90.74L80.58 90.76" stroke="black" stroke-width="0.5" />
    <path d="M80.58 90.76L80.52 90.78" stroke="black" stroke-width="0.5" />
    <path d="M80.52 90.78L80.45 90.8" stroke="black" stroke-width="0.5" />
    <path d="M80.45 90.8L80.39 90.82" stroke="black" stroke-width="0.5" />
    <path d="M80.39 90.82L80.33 90.83" stroke="black" stroke-width="0.5" />
    <path d="M80.33 90.83L80.28 90.85" stroke="black" stroke-width="0.5" />
    <path d="M80.28 90.85L80.22 90.87" stroke="black" stroke-width="0.5" />
    <path d="M80.22 90.87L80.18 90.89" stroke="black" stroke-width="0.5" />
    <path d="M80.04 95.91H75.65" stroke="black" stroke-width="0.5" />
    <path d="M75.65 95.91L75.61 95.9" stroke="black" stroke-width="0.5" />
    <path d="M75.61 95.9L75.59 95.88" stroke="black" stroke-width="0.5" />
    <path d="M75.59 95.88L75.58 95.84" stroke="black" stroke-width="0.5" />
    <path d="M75.58 95.84V95.49" stroke="black" stroke-width="0.5" />
    <path d="M75.58 95.49H75.23" stroke="black" stroke-width="0.5" />
    <path d="M75.23 95.49V95.91" stroke="black" stroke-width="0.5" />
    <path d="M75.23 95.91L75.28 96.08" stroke="black" stroke-width="0.5" />
    <path d="M75.28 96.08L75.41 96.21" stroke="black" stroke-width="0.5" />
    <path d="M75.41 96.21L75.58 96.26" stroke="black" stroke-width="0.5" />
    <path d="M75.58 96.26H80.04" stroke="black" stroke-width="0.5" />
    <path d="M80.04 96.26H84.84" stroke="black" stroke-width="0.5" />
    <path d="M84.84 96.26L85.01 96.21" stroke="black" stroke-width="0.5" />
    <path d="M85.01 96.21L85.14 96.08" stroke="black" stroke-width="0.5" />
    <path d="M85.14 96.08L85.19 95.91" stroke="black" stroke-width="0.5" />
    <path d="M85.19 95.91V95.49" stroke="black" stroke-width="0.5" />
    <path d="M85.19 95.49H84.84" stroke="black" stroke-width="0.5" />
    <path d="M84.84 95.49V95.84" stroke="black" stroke-width="0.5" />
    <path d="M84.84 95.84L84.83 95.88" stroke="black" stroke-width="0.5" />
    <path d="M84.83 95.88L84.8 95.9" stroke="black" stroke-width="0.5" />
    <path d="M84.8 95.9L84.77 95.91" stroke="black" stroke-width="0.5" />
    <path d="M84.77 95.91H80.04" stroke="black" stroke-width="0.5" />
    <path d="M86.33 108.17L86.34 108.2" stroke="black" stroke-width="0.5" />
    <path d="M86.34 108.2L86.36 108.23" stroke="black" stroke-width="0.5" />
    <path d="M86.36 108.23L86.4 108.24" stroke="black" stroke-width="0.5" />
    <path d="M86.4 108.24H86.75" stroke="black" stroke-width="0.5" />
    <path d="M86.75 108.24V108.59" stroke="black" stroke-width="0.5" />
    <path d="M86.75 108.59H86.33" stroke="black" stroke-width="0.5" />
    <path d="M86.33 108.59L86.16 108.54" stroke="black" stroke-width="0.5" />
    <path d="M86.16 108.54L86.03 108.41" stroke="black" stroke-width="0.5" />
    <path d="M86.03 108.41L85.98 108.24" stroke="black" stroke-width="0.5" />
    <path d="M85.98 108.24V97.38" stroke="black" stroke-width="0.5" />
    <path d="M85.98 97.38L86.03 97.2" stroke="black" stroke-width="0.5" />
    <path d="M86.03 97.2L86.16 97.08" stroke="black" stroke-width="0.5" />
    <path d="M86.16 97.08L86.33 97.03" stroke="black" stroke-width="0.5" />
    <path d="M86.33 97.03H86.75" stroke="black" stroke-width="0.5" />
    <path d="M86.75 97.03V97.38" stroke="black" stroke-width="0.5" />
    <path d="M86.75 97.38H86.4" stroke="black" stroke-width="0.5" />
    <path d="M86.4 97.38L86.36 97.39" stroke="black" stroke-width="0.5" />
    <path d="M86.36 97.39L86.34 97.41" stroke="black" stroke-width="0.5" />
    <path d="M86.34 97.41L86.33 97.45" stroke="black" stroke-width="0.5" />
    <path d="M86.33 97.45V108.17" stroke="black" stroke-width="0.5" />
    <path d="M83.33 97.77L83.4 98.32" stroke="black" stroke-width="0.5" />
    <path d="M83.4 98.32L83.46 98.74" stroke="black" stroke-width="0.5" />
    <path d="M83.46 98.74L83.52 99.1" stroke="black" stroke-width="0.5" />
    <path d="M83.52 99.1L83.58 99.4" stroke="black" stroke-width="0.5" />
    <path d="M83.58 99.4L83.64 99.65" stroke="black" stroke-width="0.5" />
    <path d="M83.64 99.65L83.68 99.83" stroke="black" stroke-width="0.5" />
    <path d="M83.68 99.83L83.72 99.96" stroke="black" stroke-width="0.5" />
    <path d="M83.72 99.96L83.74 100.06" stroke="black" stroke-width="0.5" />
    <path d="M83.74 100.06L83.76 100.17" stroke="black" stroke-width="0.5" />
    <path d="M83.76 100.17L83.77 100.3" stroke="black" stroke-width="0.5" />
    <path d="M83.77 100.3L83.78 100.5" stroke="black" stroke-width="0.5" />
    <path d="M83.78 100.5V100.74" stroke="black" stroke-width="0.5" />
    <path d="M83.78 100.74L83.76 101.01" stroke="black" stroke-width="0.5" />
    <path d="M83.76 101.01L83.72 101.31" stroke="black" stroke-width="0.5" />
    <path d="M83.72 101.31L83.65 101.62" stroke="black" stroke-width="0.5" />
    <path d="M83.65 101.62L83.56 101.93" stroke="black" stroke-width="0.5" />
    <path d="M83.56 101.93L83.43 102.24" stroke="black" stroke-width="0.5" />
    <path d="M83.43 102.24L83.27 102.52" stroke="black" stroke-width="0.5" />
    <path d="M83.27 102.52L83.09 102.77" stroke="black" stroke-width="0.5" />
    <path d="M83.09 102.77L82.88 102.99" stroke="black" stroke-width="0.5" />
    <path d="M82.88 102.99L82.66 103.16" stroke="black" stroke-width="0.5" />
    <path d="M82.66 103.16L82.44 103.29" stroke="black" stroke-width="0.5" />
    <path d="M82.44 103.29L82.22 103.37" stroke="black" stroke-width="0.5" />
    <path d="M82.22 103.37L82 103.4" stroke="black" stroke-width="0.5" />
    <path d="M82 103.4L81.78 103.37" stroke="black" stroke-width="0.5" />
    <path d="M81.78 103.37L81.56 103.29" stroke="black" stroke-width="0.5" />
    <path d="M81.56 103.29L81.33 103.16" stroke="black" stroke-width="0.5" />
    <path d="M81.33 103.16L81.11 102.99" stroke="black" stroke-width="0.5" />
    <path d="M81.11 102.99L80.91 102.77" stroke="black" stroke-width="0.5" />
    <path d="M80.91 102.77L80.72 102.52" stroke="black" stroke-width="0.5" />
    <path d="M80.72 102.52L80.56 102.23" stroke="black" stroke-width="0.5" />
    <path d="M80.56 102.23L80.44 101.93" stroke="black" stroke-width="0.5" />
    <path d="M80.44 101.93L80.34 101.62" stroke="black" stroke-width="0.5" />
    <path d="M80.34 101.62L80.27 101.31" stroke="black" stroke-width="0.5" />
    <path d="M80.27 101.31L80.23 101.01" stroke="black" stroke-width="0.5" />
    <path d="M80.23 101.01L80.22 100.73" stroke="black" stroke-width="0.5" />
    <path d="M80.22 100.73V100.49" stroke="black" stroke-width="0.5" />
    <path d="M80.22 100.49L80.23 100.3" stroke="black" stroke-width="0.5" />
    <path d="M80.23 100.3L80.24 100.17" stroke="black" stroke-width="0.5" />
    <path d="M80.24 100.17L80.25 100.06" stroke="black" stroke-width="0.5" />
    <path d="M80.25 100.06L80.28 99.96" stroke="black" stroke-width="0.5" />
    <path d="M80.28 99.96L80.31 99.83" stroke="black" stroke-width="0.5" />
    <path d="M80.31 99.83L80.36 99.64" stroke="black" stroke-width="0.5" />
    <path d="M80.36 99.64L80.42 99.4" stroke="black" stroke-width="0.5" />
    <path d="M80.42 99.4L80.48 99.1" stroke="black" stroke-width="0.5" />
    <path d="M80.48 99.1L80.54 98.74" stroke="black" stroke-width="0.5" />
    <path d="M80.54 98.74L80.59 98.33" stroke="black" stroke-width="0.5" />
    <path d="M80.59 98.33L80.65 97.89" stroke="black" stroke-width="0.5" />
    <path d="M80.65 97.89V97.77" stroke="black" stroke-width="0.5" />
    <path d="M80.18 97.62L80.14 97.61" stroke="black" stroke-width="0.5" />
    <path d="M80.14 97.61L80.1 97.59" stroke="black" stroke-width="0.5" />
    <path d="M80.1 97.59L80.07 97.58" stroke="black" stroke-width="0.5" />
    <path d="M80.07 97.58L80.04 97.56" stroke="black" stroke-width="0.5" />
    <path d="M80.04 97.56L80 97.55" stroke="black" stroke-width="0.5" />
    <path d="M80 97.55L79.97 97.52" stroke="black" stroke-width="0.5" />
    <path d="M79.97 97.52L79.94 97.5" stroke="black" stroke-width="0.5" />
    <path d="M79.94 97.5L79.9 97.46" stroke="black" stroke-width="0.5" />
    <path d="M79.9 97.46L79.86 97.43" stroke="black" stroke-width="0.5" />
    <path d="M79.86 97.43L79.81 97.38" stroke="black" stroke-width="0.5" />
    <path d="M79.81 97.38L79.76 97.33" stroke="black" stroke-width="0.5" />
    <path d="M79.76 97.33L79.71 97.27" stroke="black" stroke-width="0.5" />
    <path d="M79.71 97.27L79.67 97.21" stroke="black" stroke-width="0.5" />
    <path d="M79.67 97.21L79.62 97.14" stroke="black" stroke-width="0.5" />
    <path d="M79.62 97.14L79.58 97.07" stroke="black" stroke-width="0.5" />
    <path d="M79.58 97.07L79.55 97" stroke="black" stroke-width="0.5" />
    <path d="M79.55 97L79.52 96.93" stroke="black" stroke-width="0.5" />
    <path d="M79.52 96.93L79.5 96.85" stroke="black" stroke-width="0.5" />
    <path d="M79.5 96.85L79.49 96.77" stroke="black" stroke-width="0.5" />
    <path d="M79.49 96.77L79.48 96.7" stroke="black" stroke-width="0.5" />
    <path d="M79.48 96.7L79.47 96.62" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.62V96.54" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.54V96.47" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.47V96.4" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.4V96.33" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.33V96.27" stroke="black" stroke-width="0.5" />
    <path d="M79.47 96.27L79.48 96.2" stroke="black" stroke-width="0.5" />
    <path d="M79.48 96.2V96.14" stroke="black" stroke-width="0.5" />
    <path d="M79.48 96.14V96.09" stroke="black" stroke-width="0.5" />
    <path d="M79.48 96.09L79.49 96.04" stroke="black" stroke-width="0.5" />
    <path d="M79.49 96.04V95.99" stroke="black" stroke-width="0.5" />
    <path d="M79.49 95.99L79.5 95.94" stroke="black" stroke-width="0.5" />
    <path d="M79.5 95.94L79.51 95.9" stroke="black" stroke-width="0.5" />
    <path d="M79.51 95.9L79.52 95.86" stroke="black" stroke-width="0.5" />
    <path d="M79.52 95.86L79.53 95.82" stroke="black" stroke-width="0.5" />
    <path d="M79.53 95.82L79.55 95.79" stroke="black" stroke-width="0.5" />
    <path d="M79.55 95.79L79.57 95.76" stroke="black" stroke-width="0.5" />
    <path d="M79.57 95.76L79.6 95.73" stroke="black" stroke-width="0.5" />
    <path d="M79.6 95.73L79.63 95.71" stroke="black" stroke-width="0.5" />
    <path d="M79.63 95.71L79.68 95.68" stroke="black" stroke-width="0.5" />
    <path d="M79.68 95.68L79.73 95.67" stroke="black" stroke-width="0.5" />
    <path d="M79.73 95.67L79.79 95.65" stroke="black" stroke-width="0.5" />
    <path d="M79.79 95.65L79.86 95.64" stroke="black" stroke-width="0.5" />
    <path d="M79.86 95.64L79.95 95.62" stroke="black" stroke-width="0.5" />
    <path d="M79.95 95.62L80.05 95.61" stroke="black" stroke-width="0.5" />
    <path d="M80.05 95.61L80.16 95.6" stroke="black" stroke-width="0.5" />
    <path d="M80.16 95.6H80.29" stroke="black" stroke-width="0.5" />
    <path d="M80.29 95.6L80.43 95.59" stroke="black" stroke-width="0.5" />
    <path d="M80.43 95.59L80.59 95.58" stroke="black" stroke-width="0.5" />
    <path d="M80.59 95.58H80.76" stroke="black" stroke-width="0.5" />
    <path d="M80.76 95.58L80.95 95.57" stroke="black" stroke-width="0.5" />
    <path d="M80.95 95.57H81.15" stroke="black" stroke-width="0.5" />
    <path d="M81.15 95.57L81.35 95.56" stroke="black" stroke-width="0.5" />
    <path d="M81.35 95.56H81.56" stroke="black" stroke-width="0.5" />
    <path d="M81.56 95.56H81.78" stroke="black" stroke-width="0.5" />
    <path d="M81.78 95.56H82" stroke="black" stroke-width="0.5" />
    <path d="M82 95.56H82.22" stroke="black" stroke-width="0.5" />
    <path d="M82.22 95.56H82.43" stroke="black" stroke-width="0.5" />
    <path d="M82.43 95.56H82.65" stroke="black" stroke-width="0.5" />
    <path d="M82.65 95.56L82.85 95.57" stroke="black" stroke-width="0.5" />
    <path d="M82.85 95.57H83.05" stroke="black" stroke-width="0.5" />
    <path d="M83.05 95.57L83.24 95.58" stroke="black" stroke-width="0.5" />
    <path d="M83.24 95.58H83.41" stroke="black" stroke-width="0.5" />
    <path d="M83.41 95.58L83.57 95.59" stroke="black" stroke-width="0.5" />
    <path d="M83.57 95.59L83.71 95.6" stroke="black" stroke-width="0.5" />
    <path d="M83.71 95.6L83.84 95.61" stroke="black" stroke-width="0.5" />
    <path d="M83.84 95.61L83.95 95.62" stroke="black" stroke-width="0.5" />
    <path d="M83.95 95.62L84.05 95.63" stroke="black" stroke-width="0.5" />
    <path d="M84.05 95.63L84.13 95.64" stroke="black" stroke-width="0.5" />
    <path d="M84.13 95.64L84.21 95.66" stroke="black" stroke-width="0.5" />
    <path d="M84.21 95.66L84.27 95.67" stroke="black" stroke-width="0.5" />
    <path d="M84.27 95.67L84.32 95.69" stroke="black" stroke-width="0.5" />
    <path d="M84.32 95.69L84.36 95.71" stroke="black" stroke-width="0.5" />
    <path d="M84.36 95.71L84.4 95.74" stroke="black" stroke-width="0.5" />
    <path d="M84.4 95.74L84.42 95.76" stroke="black" stroke-width="0.5" />
    <path d="M84.42 95.76L84.45 95.79" stroke="black" stroke-width="0.5" />
    <path d="M84.45 95.79L84.46 95.83" stroke="black" stroke-width="0.5" />
    <path d="M84.46 95.83L84.48 95.86" stroke="black" stroke-width="0.5" />
    <path d="M84.48 95.86V95.9" stroke="black" stroke-width="0.5" />
    <path d="M84.48 95.9L84.49 95.94" stroke="black" stroke-width="0.5" />
    <path d="M84.49 95.94L84.5 95.99" stroke="black" stroke-width="0.5" />
    <path d="M84.5 95.99V96.04" stroke="black" stroke-width="0.5" />
    <path d="M84.5 96.04L84.51 96.09" stroke="black" stroke-width="0.5" />
    <path d="M84.51 96.09V96.15" stroke="black" stroke-width="0.5" />
    <path d="M84.51 96.15L84.52 96.21" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.21V96.27" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.27V96.34" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.34V96.41" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.41V96.48" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.48V96.55" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.55V96.62" stroke="black" stroke-width="0.5" />
    <path d="M84.52 96.62L84.51 96.7" stroke="black" stroke-width="0.5" />
    <path d="M84.51 96.7L84.5 96.77" stroke="black" stroke-width="0.5" />
    <path d="M84.5 96.77L84.49 96.85" stroke="black" stroke-width="0.5" />
    <path d="M84.49 96.85L84.47 96.92" stroke="black" stroke-width="0.5" />
    <path d="M84.47 96.92L84.44 96.99" stroke="black" stroke-width="0.5" />
    <path d="M84.44 96.99L84.41 97.07" stroke="black" stroke-width="0.5" />
    <path d="M84.41 97.07L84.37 97.14" stroke="black" stroke-width="0.5" />
    <path d="M84.37 97.14L84.32 97.2" stroke="black" stroke-width="0.5" />
    <path d="M84.32 97.2L84.27 97.27" stroke="black" stroke-width="0.5" />
    <path d="M84.27 97.27L84.21 97.33" stroke="black" stroke-width="0.5" />
    <path d="M84.21 97.33L84.15 97.38" stroke="black" stroke-width="0.5" />
    <path d="M84.15 97.38L84.09 97.44" stroke="black" stroke-width="0.5" />
    <path d="M84.09 97.44L84.02 97.49" stroke="black" stroke-width="0.5" />
    <path d="M84.02 97.49L83.95 97.53" stroke="black" stroke-width="0.5" />
    <path d="M83.95 97.53L83.88 97.57" stroke="black" stroke-width="0.5" />
    <path d="M83.88 97.57L83.81 97.6" stroke="black" stroke-width="0.5" />
    <path d="M83.81 97.6L83.74 97.63" stroke="black" stroke-width="0.5" />
    <path d="M83.74 97.63L83.67 97.66" stroke="black" stroke-width="0.5" />
    <path d="M83.67 97.66L83.6 97.69" stroke="black" stroke-width="0.5" />
    <path d="M83.6 97.69L83.52 97.71" stroke="black" stroke-width="0.5" />
    <path d="M83.52 97.71L83.45 97.73" stroke="black" stroke-width="0.5" />
    <path d="M83.45 97.73L83.37 97.76" stroke="black" stroke-width="0.5" />
    <path d="M83.37 97.76L83.29 97.78" stroke="black" stroke-width="0.5" />
    <path d="M83.29 97.78L83.21 97.8" stroke="black" stroke-width="0.5" />
    <path d="M83.21 97.8L83.13 97.81" stroke="black" stroke-width="0.5" />
    <path d="M83.13 97.81L83.05 97.83" stroke="black" stroke-width="0.5" />
    <path d="M83.05 97.83L82.97 97.85" stroke="black" stroke-width="0.5" />
    <path d="M82.97 97.85L82.88 97.86" stroke="black" stroke-width="0.5" />
    <path d="M82.88 97.86L82.79 97.87" stroke="black" stroke-width="0.5" />
    <path d="M82.79 97.87L82.7 97.89" stroke="black" stroke-width="0.5" />
    <path d="M82.7 97.89L82.6 97.9" stroke="black" stroke-width="0.5" />
    <path d="M82.6 97.9L82.51 97.91" stroke="black" stroke-width="0.5" />
    <path d="M82.51 97.91H82.41" stroke="black" stroke-width="0.5" />
    <path d="M82.41 97.91L82.3 97.92" stroke="black" stroke-width="0.5" />
    <path d="M82.3 97.92H82.2" stroke="black" stroke-width="0.5" />
    <path d="M82.2 97.92L82.1 97.93" stroke="black" stroke-width="0.5" />
    <path d="M82.1 97.93H81.99" stroke="black" stroke-width="0.5" />
    <path d="M81.99 97.93H81.89" stroke="black" stroke-width="0.5" />
    <path d="M81.89 97.93H81.79" stroke="black" stroke-width="0.5" />
    <path d="M81.79 97.93L81.68 97.92" stroke="black" stroke-width="0.5" />
    <path d="M81.68 97.92H81.58" stroke="black" stroke-width="0.5" />
    <path d="M81.58 97.92L81.49 97.91" stroke="black" stroke-width="0.5" />
    <path d="M81.49 97.91L81.39 97.9" stroke="black" stroke-width="0.5" />
    <path d="M81.39 97.9H81.3" stroke="black" stroke-width="0.5" />
    <path d="M81.3 97.9L81.21 97.89" stroke="black" stroke-width="0.5" />
    <path d="M81.21 97.89L81.13 97.88" stroke="black" stroke-width="0.5" />
    <path d="M81.13 97.88L81.06 97.86" stroke="black" stroke-width="0.5" />
    <path d="M81.06 97.86L80.98 97.85" stroke="black" stroke-width="0.5" />
    <path d="M80.98 97.85L80.91 97.84" stroke="black" stroke-width="0.5" />
    <path d="M80.91 97.84L80.84 97.82" stroke="black" stroke-width="0.5" />
    <path d="M80.84 97.82L80.78 97.81" stroke="black" stroke-width="0.5" />
    <path d="M80.78 97.81L80.71 97.79" stroke="black" stroke-width="0.5" />
    <path d="M80.71 97.79L80.65 97.77" stroke="black" stroke-width="0.5" />
    <path d="M80.65 97.77L80.58 97.75" stroke="black" stroke-width="0.5" />
    <path d="M80.58 97.75L80.52 97.73" stroke="black" stroke-width="0.5" />
    <path d="M80.52 97.73L80.45 97.71" stroke="black" stroke-width="0.5" />
    <path d="M80.45 97.71L80.39 97.69" stroke="black" stroke-width="0.5" />
    <path d="M80.39 97.69L80.33 97.67" stroke="black" stroke-width="0.5" />
    <path d="M80.33 97.67L80.28 97.65" stroke="black" stroke-width="0.5" />
    <path d="M80.28 97.65L80.22 97.64" stroke="black" stroke-width="0.5" />
    <path d="M80.22 97.64L80.18 97.62" stroke="black" stroke-width="0.5" />
    <path d="M209.58 78.52H238.4" stroke="black" stroke-width="0.5" />
    <path d="M209.58 76.39H236.27" stroke="black" stroke-width="0.5" />
    <path d="M209.58 2.74001H210.71" stroke="black" stroke-width="0.5" />
    <path d="M208.5 66.24H210.45" stroke="black" stroke-width="0.5" />
    <path d="M209.64 63.47L209.67 63.52" stroke="black" stroke-width="0.5" />
    <path d="M209.68 63.54L209.7 63.59" stroke="black" stroke-width="0.5" />
    <path d="M209.71 63.61V63.63" stroke="black" stroke-width="0.5" />
    <path d="M209.71 63.63L209.73 63.66" stroke="black" stroke-width="0.5" />
    <path d="M209.74 63.68L209.76 63.73" stroke="black" stroke-width="0.5" />
    <path d="M209.77 63.75L209.78 63.78" stroke="black" stroke-width="0.5" />
    <path d="M209.78 63.78L209.79 63.8" stroke="black" stroke-width="0.5" />
    <path d="M209.8 63.82L209.82 63.86" stroke="black" stroke-width="0.5" />
    <path d="M209.83 63.89L209.85 63.93" stroke="black" stroke-width="0.5" />
    <path d="M209.86 63.95L209.88 64" stroke="black" stroke-width="0.5" />
    <path d="M209.89 64.02L209.92 64.07" stroke="black" stroke-width="0.5" />
    <path d="M209.93 64.09L209.95 64.14" stroke="black" stroke-width="0.5" />
    <path d="M209.96 64.16L209.98 64.2" stroke="black" stroke-width="0.5" />
    <path d="M209.99 64.22L210 64.24" stroke="black" stroke-width="0.5" />
    <path d="M210 64.24L210.02 64.27" stroke="black" stroke-width="0.5" />
    <path d="M210.03 64.29L210.05 64.34" stroke="black" stroke-width="0.5" />
    <path d="M210.06 64.36L210.08 64.39" stroke="black" stroke-width="0.5" />
    <path d="M210.08 64.39V64.4" stroke="black" stroke-width="0.5" />
    <path d="M210.09 64.43L210.12 64.47" stroke="black" stroke-width="0.5" />
    <path d="M210.13 64.49L210.15 64.54" stroke="black" stroke-width="0.5" />
    <path d="M210.16 64.56L210.19 64.6" stroke="black" stroke-width="0.5" />
    <path d="M210.2 64.62L210.22 64.67" stroke="black" stroke-width="0.5" />
    <path d="M210.05 62.92V25.81" stroke="black" stroke-width="0.5" />
    <path
      d="M209.64 63.44C209.752 63.403 209.85 63.334 209.923 63.2415C209.996 63.1491 210.04 63.0373 210.05 62.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M210.05 25.81C210.061 25.6805 210.026 25.5514 209.951 25.4453C209.876 25.3392 209.766 25.263 209.64 25.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M210.44 25.73C210.446 25.5002 210.368 25.2761 210.221 25.0995C210.073 24.9229 209.867 24.8058 209.64 24.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M210.24 23.8L210.16 23.95" stroke="black" stroke-width="0.5" />
    <path d="M210.16 23.95L210.08 24.1" stroke="black" stroke-width="0.5" />
    <path d="M210.08 24.1L210 24.26" stroke="black" stroke-width="0.5" />
    <path d="M210 24.26L209.93 24.41" stroke="black" stroke-width="0.5" />
    <path d="M209.93 24.41L209.85 24.56" stroke="black" stroke-width="0.5" />
    <path d="M209.85 24.56L209.78 24.71" stroke="black" stroke-width="0.5" />
    <path d="M209.78 24.71L209.71 24.87" stroke="black" stroke-width="0.5" />
    <path d="M209.71 24.87L209.64 25.02" stroke="black" stroke-width="0.5" />
    <path
      d="M209.64 63.86C209.855 63.8189 210.05 63.7087 210.196 63.5462C210.343 63.3837 210.432 63.1778 210.45 62.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M-1.91998 72.33H3.03003" stroke="black" stroke-width="0.5" />
    <path d="M3.03003 72.33V65.93" stroke="black" stroke-width="0.5" />
    <path d="M3.03003 65.93H0.75" stroke="black" stroke-width="0.5" />
    <path d="M-1.53003 69.43H1.69" stroke="black" stroke-width="0.5" />
    <path d="M1.69 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M32.02 51.05V52.05" stroke="black" stroke-width="0.5" />
    <path d="M32.02 52.88V54.55" stroke="black" stroke-width="0.5" />
    <path d="M32.02 55.38V56.38" stroke="black" stroke-width="0.5" />
    <path d="M32.02 56.38H44.78" stroke="black" stroke-width="0.5" />
    <path d="M32.02 54.25H32.98" stroke="black" stroke-width="0.5" />
    <path d="M33.82 54.25H35.48" stroke="black" stroke-width="0.5" />
    <path d="M36.32 54.25H37.99" stroke="black" stroke-width="0.5" />
    <path d="M38.82 54.25H40.49" stroke="black" stroke-width="0.5" />
    <path d="M41.32 54.25H42.99" stroke="black" stroke-width="0.5" />
    <path d="M43.82 54.25H44.78" stroke="black" stroke-width="0.5" />
    <path d="M39.1 52.67V52.32" stroke="black" stroke-width="0.5" />
    <path d="M38.08 52.67H39.1" stroke="black" stroke-width="0.5" />
    <path d="M38.08 52.32V52.67" stroke="black" stroke-width="0.5" />
    <path d="M39.1 52.32H38.08" stroke="black" stroke-width="0.5" />
    <path d="M40.34 55.16L40.27 55.46" stroke="black" stroke-width="0.5" />
    <path d="M40.27 55.46L40.09 55.68" stroke="black" stroke-width="0.5" />
    <path d="M40.09 55.68L39.84 55.76" stroke="black" stroke-width="0.5" />
    <path d="M37.35 55.76L37.1 55.68" stroke="black" stroke-width="0.5" />
    <path d="M37.1 55.68L36.92 55.46" stroke="black" stroke-width="0.5" />
    <path d="M36.92 55.46L36.85 55.16" stroke="black" stroke-width="0.5" />
    <path d="M36.85 53.68L37 53.25" stroke="black" stroke-width="0.5" />
    <path d="M37 53.25L37.35 53.08" stroke="black" stroke-width="0.5" />
    <path d="M39.84 53.08L40.19 53.25" stroke="black" stroke-width="0.5" />
    <path d="M40.19 53.25L40.34 53.68" stroke="black" stroke-width="0.5" />
    <path d="M40.34 55.16V53.68" stroke="black" stroke-width="0.5" />
    <path d="M37.35 55.76H39.84" stroke="black" stroke-width="0.5" />
    <path d="M36.85 53.68V55.16" stroke="black" stroke-width="0.5" />
    <path d="M39.84 53.08H37.35" stroke="black" stroke-width="0.5" />
    <path d="M40.68 56.11V51.95" stroke="black" stroke-width="0.5" />
    <path d="M36.51 56.11H40.68" stroke="black" stroke-width="0.5" />
    <path d="M36.51 51.95V56.11" stroke="black" stroke-width="0.5" />
    <path d="M40.68 51.95H36.51" stroke="black" stroke-width="0.5" />
    <path d="M32.02 79.87V80.87" stroke="black" stroke-width="0.5" />
    <path d="M32.02 81.7V83.37" stroke="black" stroke-width="0.5" />
    <path d="M32.02 84.2V85.2" stroke="black" stroke-width="0.5" />
    <path d="M32.02 85.2H44.78" stroke="black" stroke-width="0.5" />
    <path d="M32.02 83.07H32.98" stroke="black" stroke-width="0.5" />
    <path d="M33.82 83.07H35.48" stroke="black" stroke-width="0.5" />
    <path d="M36.32 83.07H37.99" stroke="black" stroke-width="0.5" />
    <path d="M38.82 83.07H40.49" stroke="black" stroke-width="0.5" />
    <path d="M41.32 83.07H42.99" stroke="black" stroke-width="0.5" />
    <path d="M43.82 83.07H44.78" stroke="black" stroke-width="0.5" />
    <path d="M39.1 81.49V81.14" stroke="black" stroke-width="0.5" />
    <path d="M38.08 81.49H39.1" stroke="black" stroke-width="0.5" />
    <path d="M38.08 81.14V81.49" stroke="black" stroke-width="0.5" />
    <path d="M39.1 81.14H38.08" stroke="black" stroke-width="0.5" />
    <path d="M40.34 83.98L40.27 84.28" stroke="black" stroke-width="0.5" />
    <path d="M40.27 84.28L40.09 84.5" stroke="black" stroke-width="0.5" />
    <path d="M40.09 84.5L39.84 84.58" stroke="black" stroke-width="0.5" />
    <path d="M37.35 84.58L37.1 84.5" stroke="black" stroke-width="0.5" />
    <path d="M37.1 84.5L36.92 84.28" stroke="black" stroke-width="0.5" />
    <path d="M36.92 84.28L36.85 83.98" stroke="black" stroke-width="0.5" />
    <path d="M36.85 82.5L37 82.08" stroke="black" stroke-width="0.5" />
    <path d="M37 82.08L37.35 81.9" stroke="black" stroke-width="0.5" />
    <path d="M39.84 81.9L40.19 82.08" stroke="black" stroke-width="0.5" />
    <path d="M40.19 82.08L40.34 82.5" stroke="black" stroke-width="0.5" />
    <path d="M40.34 83.98V82.5" stroke="black" stroke-width="0.5" />
    <path d="M37.35 84.58H39.84" stroke="black" stroke-width="0.5" />
    <path d="M36.85 82.5V83.98" stroke="black" stroke-width="0.5" />
    <path d="M39.84 81.9H37.35" stroke="black" stroke-width="0.5" />
    <path d="M40.68 84.93V80.77" stroke="black" stroke-width="0.5" />
    <path d="M36.51 84.93H40.68" stroke="black" stroke-width="0.5" />
    <path d="M36.51 80.77V84.93" stroke="black" stroke-width="0.5" />
    <path d="M40.68 80.77H36.51" stroke="black" stroke-width="0.5" />
    <path d="M65.66 95.32V97.67" stroke="black" stroke-width="0.5" />
    <path d="M65.66 97.67L65.79 98.8" stroke="black" stroke-width="0.5" />
    <path d="M65.79 98.8L66.19 99.87" stroke="black" stroke-width="0.5" />
    <path d="M65.92 95.32V97.67" stroke="black" stroke-width="0.5" />
    <path d="M65.92 97.67L66.05 98.74" stroke="black" stroke-width="0.5" />
    <path d="M66.05 98.74L66.43 99.75" stroke="black" stroke-width="0.5" />
    <path d="M69.34 99.51L69.68 98.61" stroke="black" stroke-width="0.5" />
    <path d="M69.68 98.61L69.79 97.67" stroke="black" stroke-width="0.5" />
    <path d="M69.79 97.67V97.08" stroke="black" stroke-width="0.5" />
    <path d="M69.79 97.08L69.7 96.73" stroke="black" stroke-width="0.5" />
    <path d="M69.7 96.73L69.45 96.48" stroke="black" stroke-width="0.5" />
    <path d="M69.45 96.48L69.1 96.39" stroke="black" stroke-width="0.5" />
    <path d="M69.1 96.39H67.14" stroke="black" stroke-width="0.5" />
    <path d="M67.14 96.39L66.8 96.48" stroke="black" stroke-width="0.5" />
    <path d="M66.8 96.48L66.55 96.73" stroke="black" stroke-width="0.5" />
    <path d="M66.55 96.73L66.46 97.08" stroke="black" stroke-width="0.5" />
    <path d="M66.46 97.08V97.67" stroke="black" stroke-width="0.5" />
    <path d="M66.46 97.67L66.57 98.61" stroke="black" stroke-width="0.5" />
    <path d="M66.57 98.61L66.9 99.51" stroke="black" stroke-width="0.5" />
    <path d="M66.19 99.87H70.06" stroke="black" stroke-width="0.5" />
    <path d="M70.59 95.32V97.67" stroke="black" stroke-width="0.5" />
    <path d="M70.59 97.67L70.45 98.8" stroke="black" stroke-width="0.5" />
    <path d="M70.45 98.8L70.06 99.87" stroke="black" stroke-width="0.5" />
    <path d="M70.32 95.32V97.67" stroke="black" stroke-width="0.5" />
    <path d="M70.32 97.67L70.19 98.74" stroke="black" stroke-width="0.5" />
    <path d="M70.19 98.74L69.82 99.75" stroke="black" stroke-width="0.5" />
    <path d="M66.43 99.75H69.82" stroke="black" stroke-width="0.5" />
    <path d="M69.1 96.39H70.32" stroke="black" stroke-width="0.5" />
    <path d="M67.14 96.39H65.92" stroke="black" stroke-width="0.5" />
    <path d="M66.9 99.51H69.34" stroke="black" stroke-width="0.5" />
    <path d="M68.46 96.99L68.36 97.23" stroke="black" stroke-width="0.5" />
    <path d="M68.36 97.23L68.12 97.33" stroke="black" stroke-width="0.5" />
    <path d="M68.12 97.33L67.88 97.23" stroke="black" stroke-width="0.5" />
    <path d="M67.88 97.23L67.78 96.99" stroke="black" stroke-width="0.5" />
    <path d="M67.78 96.99L67.88 96.75" stroke="black" stroke-width="0.5" />
    <path d="M67.88 96.75L68.12 96.65" stroke="black" stroke-width="0.5" />
    <path d="M68.12 96.65L68.36 96.75" stroke="black" stroke-width="0.5" />
    <path d="M68.36 96.75L68.46 96.99" stroke="black" stroke-width="0.5" />
    <path d="M68.26 95.85L68.22 95.95" stroke="black" stroke-width="0.5" />
    <path d="M68.22 95.95L68.12 95.99" stroke="black" stroke-width="0.5" />
    <path d="M68.12 95.99L68.03 95.95" stroke="black" stroke-width="0.5" />
    <path d="M68.03 95.95L67.99 95.85" stroke="black" stroke-width="0.5" />
    <path d="M67.99 95.85L68.03 95.76" stroke="black" stroke-width="0.5" />
    <path d="M68.03 95.76L68.12 95.72" stroke="black" stroke-width="0.5" />
    <path d="M68.12 95.72L68.22 95.76" stroke="black" stroke-width="0.5" />
    <path d="M68.22 95.76L68.26 95.85" stroke="black" stroke-width="0.5" />
    <path d="M68.86 95.85L68.82 95.95" stroke="black" stroke-width="0.5" />
    <path d="M68.82 95.95L68.73 95.99" stroke="black" stroke-width="0.5" />
    <path d="M68.73 95.99L68.64 95.95" stroke="black" stroke-width="0.5" />
    <path d="M68.64 95.95L68.6 95.85" stroke="black" stroke-width="0.5" />
    <path d="M68.6 95.85L68.64 95.76" stroke="black" stroke-width="0.5" />
    <path d="M68.64 95.76L68.73 95.72" stroke="black" stroke-width="0.5" />
    <path d="M68.73 95.72L68.82 95.76" stroke="black" stroke-width="0.5" />
    <path d="M68.82 95.76L68.86 95.85" stroke="black" stroke-width="0.5" />
    <path d="M67.65 95.85L67.61 95.95" stroke="black" stroke-width="0.5" />
    <path d="M67.61 95.95L67.52 95.99" stroke="black" stroke-width="0.5" />
    <path d="M67.52 95.99L67.42 95.95" stroke="black" stroke-width="0.5" />
    <path d="M67.42 95.95L67.39 95.85" stroke="black" stroke-width="0.5" />
    <path d="M67.39 95.85L67.42 95.76" stroke="black" stroke-width="0.5" />
    <path d="M67.42 95.76L67.52 95.72" stroke="black" stroke-width="0.5" />
    <path d="M67.52 95.72L67.61 95.76" stroke="black" stroke-width="0.5" />
    <path d="M67.61 95.76L67.65 95.85" stroke="black" stroke-width="0.5" />
    <path d="M65.66 93.3V90.96" stroke="black" stroke-width="0.5" />
    <path d="M65.66 90.96L65.79 89.82" stroke="black" stroke-width="0.5" />
    <path d="M65.79 89.82L66.19 88.75" stroke="black" stroke-width="0.5" />
    <path d="M65.92 93.3V90.96" stroke="black" stroke-width="0.5" />
    <path d="M65.92 90.96L66.05 89.89" stroke="black" stroke-width="0.5" />
    <path d="M66.05 89.89L66.43 88.87" stroke="black" stroke-width="0.5" />
    <path d="M69.34 89.12L69.68 90.01" stroke="black" stroke-width="0.5" />
    <path d="M69.68 90.01L69.79 90.96" stroke="black" stroke-width="0.5" />
    <path d="M69.79 90.96V91.55" stroke="black" stroke-width="0.5" />
    <path d="M69.79 91.55L69.7 91.89" stroke="black" stroke-width="0.5" />
    <path d="M69.7 91.89L69.45 92.14" stroke="black" stroke-width="0.5" />
    <path d="M69.45 92.14L69.1 92.24" stroke="black" stroke-width="0.5" />
    <path d="M69.1 92.24H67.14" stroke="black" stroke-width="0.5" />
    <path d="M67.14 92.24L66.8 92.14" stroke="black" stroke-width="0.5" />
    <path d="M66.8 92.14L66.55 91.89" stroke="black" stroke-width="0.5" />
    <path d="M66.55 91.89L66.46 91.55" stroke="black" stroke-width="0.5" />
    <path d="M66.46 91.55V90.96" stroke="black" stroke-width="0.5" />
    <path d="M66.46 90.96L66.57 90.01" stroke="black" stroke-width="0.5" />
    <path d="M66.57 90.01L66.9 89.12" stroke="black" stroke-width="0.5" />
    <path d="M66.19 88.75H70.06" stroke="black" stroke-width="0.5" />
    <path d="M70.59 93.3V90.96" stroke="black" stroke-width="0.5" />
    <path d="M70.59 90.96L70.45 89.82" stroke="black" stroke-width="0.5" />
    <path d="M70.45 89.82L70.06 88.75" stroke="black" stroke-width="0.5" />
    <path d="M70.32 93.3V90.96" stroke="black" stroke-width="0.5" />
    <path d="M70.32 90.96L70.19 89.89" stroke="black" stroke-width="0.5" />
    <path d="M70.19 89.89L69.82 88.87" stroke="black" stroke-width="0.5" />
    <path d="M66.43 88.87H69.82" stroke="black" stroke-width="0.5" />
    <path d="M69.1 92.24H70.32" stroke="black" stroke-width="0.5" />
    <path d="M67.14 92.24H65.92" stroke="black" stroke-width="0.5" />
    <path d="M66.9 89.12H69.34" stroke="black" stroke-width="0.5" />
    <path d="M68.46 91.63L68.36 91.87" stroke="black" stroke-width="0.5" />
    <path d="M68.36 91.87L68.12 91.97" stroke="black" stroke-width="0.5" />
    <path d="M68.12 91.97L67.88 91.87" stroke="black" stroke-width="0.5" />
    <path d="M67.88 91.87L67.78 91.63" stroke="black" stroke-width="0.5" />
    <path d="M67.78 91.63L67.88 91.39" stroke="black" stroke-width="0.5" />
    <path d="M67.88 91.39L68.12 91.29" stroke="black" stroke-width="0.5" />
    <path d="M68.12 91.29L68.36 91.39" stroke="black" stroke-width="0.5" />
    <path d="M68.36 91.39L68.46 91.63" stroke="black" stroke-width="0.5" />
    <path d="M68.26 92.77L68.22 92.86" stroke="black" stroke-width="0.5" />
    <path d="M68.22 92.86L68.12 92.9" stroke="black" stroke-width="0.5" />
    <path d="M68.12 92.9L68.03 92.86" stroke="black" stroke-width="0.5" />
    <path d="M68.03 92.86L67.99 92.77" stroke="black" stroke-width="0.5" />
    <path d="M67.99 92.77L68.03 92.68" stroke="black" stroke-width="0.5" />
    <path d="M68.03 92.68L68.12 92.64" stroke="black" stroke-width="0.5" />
    <path d="M68.12 92.64L68.22 92.68" stroke="black" stroke-width="0.5" />
    <path d="M68.22 92.68L68.26 92.77" stroke="black" stroke-width="0.5" />
    <path d="M68.86 92.77L68.82 92.86" stroke="black" stroke-width="0.5" />
    <path d="M68.82 92.86L68.73 92.9" stroke="black" stroke-width="0.5" />
    <path d="M68.73 92.9L68.64 92.86" stroke="black" stroke-width="0.5" />
    <path d="M68.64 92.86L68.6 92.77" stroke="black" stroke-width="0.5" />
    <path d="M68.6 92.77L68.64 92.68" stroke="black" stroke-width="0.5" />
    <path d="M68.64 92.68L68.73 92.64" stroke="black" stroke-width="0.5" />
    <path d="M68.73 92.64L68.82 92.68" stroke="black" stroke-width="0.5" />
    <path d="M68.82 92.68L68.86 92.77" stroke="black" stroke-width="0.5" />
    <path d="M67.65 92.77L67.61 92.86" stroke="black" stroke-width="0.5" />
    <path d="M67.61 92.86L67.52 92.9" stroke="black" stroke-width="0.5" />
    <path d="M67.52 92.9L67.42 92.86" stroke="black" stroke-width="0.5" />
    <path d="M67.42 92.86L67.39 92.77" stroke="black" stroke-width="0.5" />
    <path d="M67.39 92.77L67.42 92.68" stroke="black" stroke-width="0.5" />
    <path d="M67.42 92.68L67.52 92.64" stroke="black" stroke-width="0.5" />
    <path d="M67.52 92.64L67.61 92.68" stroke="black" stroke-width="0.5" />
    <path d="M67.61 92.68L67.65 92.77" stroke="black" stroke-width="0.5" />
    <path d="M107.75 72.54V78.95" stroke="black" stroke-width="0.5" />
    <path d="M117.43 72.54H115.81" stroke="black" stroke-width="0.5" />
    <path d="M114.98 72.54H113.31" stroke="black" stroke-width="0.5" />
    <path d="M112.47 72.54H110.81" stroke="black" stroke-width="0.5" />
    <path d="M109.97 72.54H108.35" stroke="black" stroke-width="0.5" />
    <path d="M108.35 72.54V74.61" stroke="black" stroke-width="0.5" />
    <path d="M108.35 75.45V77.11" stroke="black" stroke-width="0.5" />
    <path d="M108.35 77.95V80.02" stroke="black" stroke-width="0.5" />
    <path d="M108.35 80.02H109.97" stroke="black" stroke-width="0.5" />
    <path d="M110.81 80.02H112.47" stroke="black" stroke-width="0.5" />
    <path d="M113.31 80.02H114.98" stroke="black" stroke-width="0.5" />
    <path d="M115.81 80.02H117.43" stroke="black" stroke-width="0.5" />
    <path d="M108.35 79.75H109.97" stroke="black" stroke-width="0.5" />
    <path d="M110.81 79.75H112.47" stroke="black" stroke-width="0.5" />
    <path d="M113.31 79.75H114.98" stroke="black" stroke-width="0.5" />
    <path d="M115.81 79.75H117.43" stroke="black" stroke-width="0.5" />
    <path d="M108.35 85.35V80.02" stroke="black" stroke-width="0.5" />
    <path d="M108.61 80.02V85.35" stroke="black" stroke-width="0.5" />
    <path d="M108.35 80.02H108.61" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.02L113.51 81.4" stroke="black" stroke-width="0.5" />
    <path d="M113.51 81.4L112.98 82.68" stroke="black" stroke-width="0.5" />
    <path d="M112.98 82.68L112.13 83.79" stroke="black" stroke-width="0.5" />
    <path d="M112.13 83.79L111.02 84.64" stroke="black" stroke-width="0.5" />
    <path d="M111.02 84.64L109.74 85.17" stroke="black" stroke-width="0.5" />
    <path d="M109.74 85.17L108.35 85.35" stroke="black" stroke-width="0.5" />
    <path d="M117.43 83.75V80.02" stroke="black" stroke-width="0.5" />
    <path d="M117.25 80.02V83.75" stroke="black" stroke-width="0.5" />
    <path d="M117.43 80.02H117.25" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.02L113.88 81.17" stroke="black" stroke-width="0.5" />
    <path d="M113.88 81.17L114.41 82.21" stroke="black" stroke-width="0.5" />
    <path d="M114.41 82.21L115.23 83.04" stroke="black" stroke-width="0.5" />
    <path d="M115.23 83.04L116.27 83.57" stroke="black" stroke-width="0.5" />
    <path d="M116.27 83.57L117.43 83.75" stroke="black" stroke-width="0.5" />
    <path d="M100.92 69.43H104.14" stroke="black" stroke-width="0.5" />
    <path d="M104.14 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M100.92 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M105.74 64.8V71.21" stroke="black" stroke-width="0.5" />
    <path d="M99.32 64.8V71.21" stroke="black" stroke-width="0.5" />
    <path d="M153.02 151.94H158.64" stroke="black" stroke-width="0.5" />
    <path d="M158.64 151.94V164.76" stroke="black" stroke-width="0.5" />
    <path d="M158.64 164.76H153.02" stroke="black" stroke-width="0.5" />
    <path d="M153.02 164.76V151.94" stroke="black" stroke-width="0.5" />
    <path d="M156.27 151.94V152.93" stroke="black" stroke-width="0.5" />
    <path d="M156.27 153.76V155.43" stroke="black" stroke-width="0.5" />
    <path d="M156.27 156.27V157.93" stroke="black" stroke-width="0.5" />
    <path d="M156.27 158.77V160.44" stroke="black" stroke-width="0.5" />
    <path d="M156.27 161.27V162.94" stroke="black" stroke-width="0.5" />
    <path d="M156.27 163.77V164.76" stroke="black" stroke-width="0.5" />
    <path d="M154.45 157.74H154.1" stroke="black" stroke-width="0.5" />
    <path d="M154.45 158.76V157.74" stroke="black" stroke-width="0.5" />
    <path d="M154.1 158.76H154.45" stroke="black" stroke-width="0.5" />
    <path d="M154.1 157.74V158.76" stroke="black" stroke-width="0.5" />
    <path d="M156.94 156.51L157.24 156.58" stroke="black" stroke-width="0.5" />
    <path d="M157.24 156.58L157.46 156.76" stroke="black" stroke-width="0.5" />
    <path d="M157.46 156.76L157.54 157.01" stroke="black" stroke-width="0.5" />
    <path d="M157.54 159.5L157.46 159.75" stroke="black" stroke-width="0.5" />
    <path d="M157.46 159.75L157.24 159.93" stroke="black" stroke-width="0.5" />
    <path d="M157.24 159.93L156.94 160" stroke="black" stroke-width="0.5" />
    <path d="M155.46 160L155.03 159.85" stroke="black" stroke-width="0.5" />
    <path d="M155.03 159.85L154.86 159.5" stroke="black" stroke-width="0.5" />
    <path d="M154.86 157.01L155.03 156.66" stroke="black" stroke-width="0.5" />
    <path d="M155.03 156.66L155.46 156.51" stroke="black" stroke-width="0.5" />
    <path d="M156.94 156.51H155.46" stroke="black" stroke-width="0.5" />
    <path d="M157.54 159.5V157.01" stroke="black" stroke-width="0.5" />
    <path d="M155.46 160H156.94" stroke="black" stroke-width="0.5" />
    <path d="M154.86 157.01V159.5" stroke="black" stroke-width="0.5" />
    <path d="M157.89 156.17H153.72" stroke="black" stroke-width="0.5" />
    <path d="M157.89 160.34V156.17" stroke="black" stroke-width="0.5" />
    <path d="M153.72 160.34H157.89" stroke="black" stroke-width="0.5" />
    <path d="M153.72 156.17V160.34" stroke="black" stroke-width="0.5" />
    <path d="M208.75 66.24V64.6H209.33V66.24H208.75Z" fill="black" />
    <path d="M208.75 63.76V62.09H209.33V63.76H208.75Z" fill="black" />
    <path d="M208.75 61.26V59.59H209.33V61.26H208.75Z" fill="black" />
    <path d="M208.75 58.76V57.09H209.33V58.76H208.75Z" fill="black" />
    <path d="M208.75 56.26V54.59H209.33V56.26H208.75Z" fill="black" />
    <path d="M208.75 53.75V52.09H209.33V53.75H208.75Z" fill="black" />
    <path d="M208.75 51.25V49.58H209.33V51.25H208.75Z" fill="black" />
    <path d="M208.75 48.75V47.08H209.33V48.75H208.75Z" fill="black" />
    <path d="M208.75 46.25V44.58H209.33V46.25H208.75Z" fill="black" />
    <path d="M208.75 43.75V42.08H209.33V43.75H208.75Z" fill="black" />
    <path d="M208.75 41.24V39.58H209.33V41.24H208.75Z" fill="black" />
    <path d="M208.75 38.74V37.08H209.33V38.74H208.75Z" fill="black" />
    <path d="M208.75 36.24V34.57H209.33V36.24H208.75Z" fill="black" />
    <path d="M208.75 33.74V32.07H209.33V33.74H208.75Z" fill="black" />
    <path d="M208.75 31.24V29.57H209.33V31.24H208.75Z" fill="black" />
    <path d="M208.75 28.74V27.07H209.33V28.74H208.75Z" fill="black" />
    <path d="M208.75 26.23V24.57H209.33V26.23H208.75Z" fill="black" />
    <path d="M208.75 23.73V22.06H209.33V23.73H208.75Z" fill="black" />
    <path d="M208.75 21.23V19.56H209.33V21.23H208.75Z" fill="black" />
    <path d="M208.75 18.73V17.06H209.33V18.73H208.75Z" fill="black" />
    <path d="M208.75 16.23V14.56H209.33V16.23H208.75Z" fill="black" />
    <path d="M208.75 13.72V12.06H209.33V13.72H208.75Z" fill="black" />
    <path d="M208.75 11.22V9.56H209.33V11.22H208.75Z" fill="black" />
    <path d="M208.75 8.72V7.05H209.33V8.72H208.75Z" fill="black" />
    <path d="M208.75 6.22V4.55H209.33V6.22H208.75Z" fill="black" />
    <path d="M208.75 3.72V2.08H209.33V3.72H208.75Z" fill="black" />
    <path d="M209.04 1.78999H210.71V2.37H209.04V1.78999Z" fill="black" />
    <path d="M208.34 1.41V0.0800018" stroke="black" stroke-width="0.5" />
    <path d="M208.34 0.0800018H201.93" stroke="black" stroke-width="0.5" />
    <path d="M201.93 0.0800018V1.41" stroke="black" stroke-width="0.5" />
    <path d="M44.78 96.41L42.9 96.6" stroke="black" stroke-width="0.5" />
    <path d="M42.9 96.6L41.1 97.15" stroke="black" stroke-width="0.5" />
    <path d="M41.1 97.15L39.44 98.03" stroke="black" stroke-width="0.5" />
    <path d="M39.44 98.03L37.99 99.23" stroke="black" stroke-width="0.5" />
    <path d="M37.99 99.23L36.79 100.69" stroke="black" stroke-width="0.5" />
    <path d="M36.79 100.69L35.91 102.35" stroke="black" stroke-width="0.5" />
    <path d="M35.91 102.35L35.36 104.15" stroke="black" stroke-width="0.5" />
    <path d="M35.36 104.15L35.18 106.02" stroke="black" stroke-width="0.5" />
    <path d="M44.78 106.02V105.55" stroke="black" stroke-width="0.5" />
    <path d="M44.78 105.55L35.19 105.56" stroke="black" stroke-width="0.5" />
    <path d="M35.18 106.02H44.78" stroke="black" stroke-width="0.5" />
    <path d="M44.78 67.59L42.9 67.78" stroke="black" stroke-width="0.5" />
    <path d="M42.9 67.78L41.1 68.32" stroke="black" stroke-width="0.5" />
    <path d="M41.1 68.32L39.44 69.21" stroke="black" stroke-width="0.5" />
    <path d="M39.44 69.21L37.99 70.41" stroke="black" stroke-width="0.5" />
    <path d="M37.99 70.41L36.79 71.86" stroke="black" stroke-width="0.5" />
    <path d="M36.79 71.86L35.91 73.53" stroke="black" stroke-width="0.5" />
    <path d="M35.91 73.53L35.36 75.33" stroke="black" stroke-width="0.5" />
    <path d="M35.36 75.33L35.18 77.2" stroke="black" stroke-width="0.5" />
    <path d="M44.78 77.2V76.73" stroke="black" stroke-width="0.5" />
    <path d="M44.78 76.73L35.19 76.74" stroke="black" stroke-width="0.5" />
    <path d="M35.18 77.2H44.78" stroke="black" stroke-width="0.5" />
    <path d="M44.78 119.1L42.9 118.91" stroke="black" stroke-width="0.5" />
    <path d="M42.9 118.91L41.1 118.36" stroke="black" stroke-width="0.5" />
    <path d="M41.1 118.36L39.44 117.47" stroke="black" stroke-width="0.5" />
    <path d="M39.44 117.47L37.99 116.28" stroke="black" stroke-width="0.5" />
    <path d="M37.99 116.28L36.79 114.82" stroke="black" stroke-width="0.5" />
    <path d="M36.79 114.82L35.91 113.16" stroke="black" stroke-width="0.5" />
    <path d="M35.91 113.16L35.36 111.36" stroke="black" stroke-width="0.5" />
    <path d="M35.36 111.36L35.18 109.48" stroke="black" stroke-width="0.5" />
    <path d="M44.78 109.49V109.96" stroke="black" stroke-width="0.5" />
    <path d="M44.78 109.96L35.19 109.95" stroke="black" stroke-width="0.5" />
    <path d="M35.18 109.48L44.78 109.49" stroke="black" stroke-width="0.5" />
    <path d="M44.78 147.92L42.9 147.73" stroke="black" stroke-width="0.5" />
    <path d="M42.9 147.73L41.1 147.18" stroke="black" stroke-width="0.5" />
    <path d="M41.1 147.18L39.44 146.3" stroke="black" stroke-width="0.5" />
    <path d="M39.44 146.3L37.99 145.1" stroke="black" stroke-width="0.5" />
    <path d="M37.99 145.1L36.79 143.64" stroke="black" stroke-width="0.5" />
    <path d="M36.79 143.64L35.91 141.98" stroke="black" stroke-width="0.5" />
    <path d="M35.91 141.98L35.36 140.18" stroke="black" stroke-width="0.5" />
    <path d="M35.36 140.18L35.18 138.31" stroke="black" stroke-width="0.5" />
    <path d="M44.78 138.31V138.78" stroke="black" stroke-width="0.5" />
    <path d="M44.78 138.78L35.19 138.77" stroke="black" stroke-width="0.5" />
    <path d="M35.18 138.31H44.78" stroke="black" stroke-width="0.5" />
  </svg>
</template>
