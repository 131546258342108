import { markRaw } from 'vue';

import VAV332Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite319A/VAV332Drawing.vue';
import VAV333Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite319A/VAV333Drawing.vue';
import VAV334Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite319A/VAV334Drawing.vue';
import VAV335Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite319A/VAV335Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 3-32',
    controlId: 2351,
    component: markRaw(VAV332Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-32',
      },
    },
    style: {
      position: 'absolute',
      top: '7.85%',
      left: '31.5%',
      width: '46.55%',
      height: '46.55%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '30%',
      left: '45%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 3-33',
    controlId: 2352,
    component: markRaw(VAV333Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-33',
      },
    },
    style: {
      position: 'absolute',
      top: '52.5%',
      left: '22.4%',
      width: '52.9%',
      height: '52.9%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '78%',
      left: '45%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 3-34',
    controlId: 2353,
    component: markRaw(VAV334Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-34',
      },
    },
    style: {
      position: 'absolute',
      top: '8.8%',
      left: '64.2%',
      width: '51.5%',
      height: '51.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '37%',
      left: '88%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 3-35',
    controlId: 2354,
    component: markRaw(VAV335Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-35',
      },
    },
    style: {
      position: 'absolute',
      top: '8.8%',
      left: '58.9%',
      width: '25%',
      height: '25%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '68%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
