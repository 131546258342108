<template>
  <v-container fluid>
    <SimpleTitleBar>Contact Support</SimpleTitleBar>
    <v-card class="pa-4 rounded-2">
      <form @submit.prevent="submit">
        <v-text-field
          v-model="name.value.value"
          :counter="10"
          :error-messages="name.errorMessage.value"
          label="Name"
        />
        <v-text-field
          v-model="email.value.value"
          :error-messages="email.errorMessage.value"
          label="E-mail"
        />
        <v-textarea
          counter
          :clearable="true"
          no-resize
          label="Message"
          :error-messages="message.errorMessage.value"
          :model-value="message.value"
          hint="Please describe the feature you were trying to use and what happened."
        />
        <v-row class="mt-3">
          <v-col>
            <v-btn type="submit" class="rounded-2" color="primary">
              Send
            </v-btn>
            <v-btn class="ml-5 rounded-2" @click="handleCancel"> Cancel </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
    <v-row v-if="showAlert">
      <v-col cols="6">
        <v-alert
          class="mt-5"
          :type="alertType?.type"
          :title="alertType?.title"
          :text="alertType?.text"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import API from '@/Core.Service.Domain/Contact/API';
import SimpleTitleBar from '@/Core.UI.Domain/Components/SimpleTitleBar.vue';

import { Icontact } from '@/Core.Service.Domain/Contact/types';

const router = useRouter();
const showAlert = ref(false);
const alertType = ref<
  | {
      type: 'success' | 'error';
      title: string;
      text: string;
    }
  | undefined
>({
  type: 'success',
  title: 'Message sent',
  text: 'E360 support will be contacting you shortly. Thank you.',
});

const { handleSubmit } = useForm({
  validationSchema: {
    name(value: string) {
      if (value?.length >= 2) return true;

      return 'Name needs to be at least 2 characters.';
    },
    email(value: string) {
      if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;

      return 'Must be a valid e-mail.';
    },
    message(value: string) {
      if (!value) return 'Message is required.';
      if (value?.length <= 500) return true;

      return 'Max 500 characters';
    },
  },
});
const name = useField('name');
const email = useField('email');
const message = useField('message');

// methods
const submit = handleSubmit(async (values) => {
  const { name, email, message } = values;
  const contact: Icontact = {
    name,
    email,
    message,
    subject: 'Technical support issue',
  };

  try {
    await API.sendIssueEmail(contact);
    showAlert.value = true;
    clearForm();
  } catch (error) {
    return Promise.reject(error);
  } finally {
    setTimeout(() => {
      showAlert.value = false;
    }, 10000);
  }
});

const handleCancel = () => {
  clearForm();
  router.push({ name: 'Portfolio' });
};

const clearForm = () => {
  name.value.value = '';
  email.value.value = '';
  message.value.value = '';
};
</script>
