<template>
  <v-card class="controls-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ label }}
      </v-app-bar-title>
      <v-row v-if="!isDigitalTwinRoute">
        <v-col>
          <v-autocomplete
            class="mt-6 mr-5"
            :items="sitesWithControls"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            return-object
            variant="outlined"
            density="compact"
            @update:modelValue="
              (selectedSite) => {
                clearAnchorPosition();
                setDefaultSiteFromControlsDomain(selectedSite);
              }
            "
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            bg-color="white"
            single-line
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ControlsBar',

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
    };
  },

  async created() {
    await this.getSitesWithControls();
    this.setDefaultSiteFromControlsDomain(this.defaultSite);
  },

  beforeUnmount() {
    this.setControlCurrentRoute('');
  },

  computed: {
    ...mapGetters({
      sitesWithControls: 'ControlsStore/sitesWithControls',
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Control';
    },

    label() {
      return this.$route.meta.label || 'Controls';
    },

    controlTypeName() {
      return this.$route.meta.controlTypeName;
    },

    isDigitalTwinRoute() {
      return this.$route.name === 'DigitalTwin';
    },
  },

  watch: {
    $route(to) {
      const regex = /\/history\//;
      if (regex.test(to.path)) return;

      this.setDefaultSiteFromControlsDomain(this.defaultSite);
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapActions({
      getSitesWithControls: 'ControlsStore/getSitesWithControls',
      getControlsBySite: 'ControlsStore/getControlsBySite',
      setControlCurrentRoute: 'ControlsStore/setControlCurrentRoute',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setControlSearchTerm: 'ControlsStore/CONTROL_SEARCH_TERM',
    }),

    setDefaultSiteFromControlsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesWithControls[0];

      this.getControlsBySite({
        selectedSite: this.selectedSite,
        controlTypeName: this.controlTypeName,
      }).then(() => {
        this.setControlCurrentRoute(this.$route.name);
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setControlSearchTerm('');
      } else {
        this.setControlSearchTerm(value);
      }
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setControlSearchTerm('');
    },

    clearAnchorPosition() {
      localStorage.removeItem('controlAnchorPosition');
    },
  },
};
</script>
<style lang="scss" scoped>
.controls-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
