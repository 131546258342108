import { markRaw } from 'vue';

import VAV212Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite211/VAV212Drawing.vue';
import VAV277Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite211/VAV277Drawing.vue';
import VAV278Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite211/VAV278Drawing.vue';
import VAV279Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite211/VAV279Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 2-12',
    controlId: 2220,
    component: markRaw(VAV212Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-12',
      },
    },
    style: {
      position: 'absolute',
      top: '55.3%',
      left: '34.9%',
      width: '34%',
      height: '34%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '70%',
      left: '48%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 2-77',
    controlId: 2302,
    component: markRaw(VAV277Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-77',
      },
    },
    style: {
      position: 'absolute',
      top: '10.6%',
      left: '22.1%',
      width: '53.5%',
      height: '53.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '55%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 2-78',
    controlId: 2303,
    component: markRaw(VAV278Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-78',
      },
    },
    style: {
      position: 'absolute',
      top: '21.8%',
      left: '55.3%',
      width: '74.5%',
      height: '74.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '32%',
      left: '100%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 2-79',
    controlId: 2303,
    disabled: true,
    component: markRaw(VAV279Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-79',
      },
    },
    style: {
      position: 'absolute',
      top: '28%',
      left: '23%',
      width: '74.7%',
      height: '74.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '48.5%',
      left: '62%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
