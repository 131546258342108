<template>
  <v-combobox
    ref="siteLocation"
    :items="siteLocations"
    item-value="id"
    item-title="siteLocationName"
    v-model="selectedSiteLocation"
    label="Site Location"
    hint="What location is this monitor installed in"
    persistent-hint
    @keydown="handleKeydownSiteLocation"
    @update:modelValue="handleInputSiteLocation"
    return-object
    variant="outlined"
  >
    <template v-slot:append>
      <v-btn
        color="primary"
        title="Add a new site location"
        @click="handleCreateSiteLocation"
        :disabled="!canAddSiteLocation"
      >
        Add
      </v-btn>

      <v-dialog v-model="editSiteLocationDialog" max-width="75vw">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="ml-1"
            :disabled="!canEditSiteLocation"
            color="primary"
            title="Edit selected site location"
            @click="handleEditSiteLocation"
          >
            Edit
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Site Location</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="selectedSiteLocationName"
                    label="Site Location Name"
                    :rules="[(v) => !!v || 'Site Location Name is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="cancelEditSiteLocation"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="saveEditSiteLocation"
            >
              Save Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-combobox>
</template>

<script>
import api from '../_api';

export default {
  props: {
    siteId: {
      validator: function (value) {
        return typeof value === 'string' || typeof value === 'number';
      },
      required: true,
    },
    selectedSiteLocationId: { type: Number, default: null },
  },

  watch: {
    selectedSiteLocation: function (newVal) {
      if (newVal?.id === undefined) {
        this.canAddSiteLocation = true;
        this.canEditSiteLocation = false;
      } else {
        this.canAddSiteLocation = false;
        this.canEditSiteLocation = true;
        this.selectedSiteLocationName = newVal?.siteLocationName;
      }
    },
  },

  data() {
    return {
      selectedSiteLocation: null,
      selectedSiteLocationName: null,
      canAddSiteLocation: false,
      canEditSiteLocation: false,
      siteLocations: [],
      editSiteLocationDialog: false,
    };
  },

  async created() {
    this.siteLocations = await api.getSiteLocationsBySiteId(this.siteId);
    if (this.selectedSiteLocationId !== null) {
      this.handleSetSiteLocation(this.selectedSiteLocationId);
    } else {
      //this assumes we are coming from Add New X
      this.canAddSiteLocation = false;
      this.canEditSiteLocation = false;
    }

    this.selectedSiteLocationName = this.selectedSiteLocation?.siteLocationName;
  },

  methods: {
    handleSetSiteLocation(siteLocationId) {
      this.selectedSiteLocation = this.siteLocations.find(
        (s) => s.id.toString() === siteLocationId.toString()
      );
      this.$emit('sitelocationselected', this.selectedSiteLocation);
    },

    handleKeydownSiteLocation() {
      this.canAddSiteLocation = true;
    },

    //When you select from the site location dropdown
    handleInputSiteLocation(siteLocation) {
      console.log(siteLocation);
      this.$emit('sitelocationselected', siteLocation);
    },

    //Clicking Add Site Location calls api to create
    async handleCreateSiteLocation() {
      this.$refs.siteLocation.blur();

      let currentSiteLocation = {
        siteId: this.siteId,
        siteLocationName: this.selectedSiteLocation,
      };

      try {
        var newSiteLocation = await api.createSiteLocation(currentSiteLocation);
        this.siteLocations = await api.getSiteLocationsBySiteId(this.siteId);
        this.selectedSiteLocation = newSiteLocation;
        this.$emit('sitelocationselected', this.selectedSiteLocation);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    //Open edit site location dialog
    handleEditSiteLocation() {
      this.editSiteLocationDialog = true;
    },

    //Close edit site location dialog
    cancelEditSiteLocation() {
      this.editSiteLocationDialog = false;
    },

    //Save edit site location
    async saveEditSiteLocation() {
      var editedSiteLocation = {
        siteId: this.siteId,
        siteLocationId: this.selectedSiteLocation?.id,
        siteLocationName: this.selectedSiteLocationName,
      };

      try {
        let siteLocation = await api.updateSiteLocation(editedSiteLocation);
        this.selectedSiteLocation = siteLocation;
        this.siteLocations = await api.getSiteLocationsBySiteId(this.siteId);

        this.$emit('sitelocationselected', this.selectedSiteLocation);
        this.editSiteLocationDialog = false;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
