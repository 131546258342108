<template>
  <v-card class="project-bar">
    <!-- Select Site -->
    <v-app-bar elevation="1" class="px-13">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Projects
      </v-app-bar-title>
      <v-spacer />
      <v-row>
        <v-col>
          <v-autocomplete
            class="mt-6 mr-5"
            :items="sitesWithAnalyticsProjects"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            variant="outlined"
            density="compact"
            return-object
            @update:modelValue="setDefaultSiteFromAnalyticsDomain"
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            bg-color="white"
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>

      <template v-slot:append>
        <!-- Add Button -->
        <v-btn
          class="rounded-2 mr-3"
          color="primary"
          variant="flat"
          size="large"
          @click="handleAddAnalyticsProject"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            {{ $route.meta.buttonLabel }}
          </div>
        </v-btn>
      </template>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProjectBar',

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
    };
  },

  async created() {
    await this.getSitesWithAnalyticsProjects();
    this.setDefaultSiteFromAnalyticsDomain(this.defaultSite);
  },

  beforeUnmount() {
    this.setAnalyticsProjectsCurrentRoute('');
  },

  computed: {
    ...mapGetters({
      sitesWithAnalyticsProjects:
        'AnalyticsStore/AnalyticsProjectsStore/sitesWithAnalyticsProjects',
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Project';
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromAnalyticsDomain(this.defaultSite);
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapActions({
      getSitesWithAnalyticsProjects:
        'AnalyticsStore/AnalyticsProjectsStore/getSitesWithAnalyticsProjects',
      getAnalyticsProjectsBySiteId:
        'AnalyticsStore/AnalyticsProjectsStore/getAnalyticsProjectsBySiteId',
      setAnalyticsProjectsCurrentRoute:
        'AnalyticsStore/AnalyticsProjectsStore/setAnalyticsProjectsCurrentRoute',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setAnalyticsProjectSearchTerm:
        'AnalyticsStore/AnalyticsProjectsStore/ANALYTICS_PROJECT_SEARCH_TERM',
    }),

    setDefaultSiteFromAnalyticsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesWithAnalyticsProjects[0];
      this.setDefaultSite(this.selectedSite);

      this.getAnalyticsProjectsBySiteId({
        selectedSite: this.selectedSite,
      }).then(() => {
        this.setAnalyticsProjectsCurrentRoute(this.$route.name);
        this.clearSearchTerm();
      });
    },

    handleAddAnalyticsProject() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: 'AnalyticsMandVProjectNew',
        params: { siteId: this.selectedSite.siteId || this.selectedSite.id },
      });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setAnalyticsProjectSearchTerm('');
      } else {
        this.setAnalyticsProjectSearchTerm(value);
      }
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setAnalyticsProjectSearchTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.project-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
