<template>
  <v-card
    :id="`vav_${VAVInfo.controlName}`"
    class="rounded-2"
    :width="cardWidth"
    elevation="24"
  >
    <!--Card Header-->
    <v-toolbar color="primary" height="88">
      <v-alert variant="text" class="ml-n4">
        <v-container>
          <v-row>
            <v-col class="d-flex">
              <span class="text-h6 font-weight-bold ml-1">
                {{ VAVInfo.controlFriendlyName }}
              </span>
            </v-col>
            <v-col :cols="height >= maxHeight ? 2 : 1">
              <!--Dirty Icon-->
              <v-tooltip location="bottom" content-class="vav-control-tooltip">
                <template v-slot:activator="{ props }">
                  <v-icon
                    class="mt-2 vav-control-dirty-icon"
                    :class="height >= maxHeight ? 'ml-6' : 'ml-15'"
                    color="accentOrange"
                    v-bind="props"
                    v-if="isVAVDirty"
                  >
                    {{ 'mdi-alert-circle' }}
                  </v-icon>
                </template>
                <!--Warnings-->
                <VAVCardAlert
                  :isVAVDirty="isVAVDirty"
                  :getVAVNewSettings="getVAVNewSettings"
                />
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <span
                class="text-aliceBlue text-caption ml-1 mt-n4 font-weight-bold"
              >
                {{ VAVInfo.controlSiteLocationName }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-alert>
    </v-toolbar>
    <!--Card Body-->
    <v-container class="pa-4 rounded-2">
      <v-row>
        <!--Temperature Card-->
        <v-col>
          <v-card variant="outlined" height="104" class="mb-n5">
            <v-row
              align="center"
              justify="center"
              class="mt-3 d-flex justify-center flex-column"
            >
              <span class="text-primary text-caption font-weight-bold">
                <span>Temperature (ºF)</span>
              </span>
              <span class="text-primary text-h3 font-weight-bold">
                {{ VAVInfo.spaceTemperature }}&deg;
              </span>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!--Zone Setpoints Card-->
        <v-col :cols="height >= maxHeight ? 12 : 4">
          <v-card variant="outlined" class="mb-n5 px-2">
            <v-row>
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                >
                  Zone Setpoints
                </span>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mt-1 px-1">
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-2 font-weight-bold"
                >
                  Occupied Setpoint (ºF)
                  <v-icon v-if="userCanUpdate" color="warning">
                    mdi-pencil-box
                  </v-icon>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-slider
                  v-model="localOccupiedSetpoint"
                  :readonly="!userCanUpdate"
                  data-testid="occupiedSetpointSlider"
                  class="align-center my-n7"
                  color="primary"
                  :max="VAVInfo.maxOccupiedSetpoint"
                  :min="VAVInfo.minOccupiedSetpoint"
                  hide-details
                  step="0.5"
                  show-ticks
                  thumb-label
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="localOccupiedSetpoint"
                      hide-details
                      data-testid="occupiedSetpointTextField"
                      :readonly="!userCanUpdate"
                      class="display-value rounded-2 font-weight-bold text-primary"
                      single-line
                      variant="outlined"
                      density="compact"
                      style="width: 70px"
                      suffix="º"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="mt-7 px-1">
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Occupied Setpoint Max (ºF)"
                  :model-value="VAVInfo.maxOccupiedSetpoint"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="px-1 mt-n7">
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Occupied Setpoint Min (ºF)"
                  :model-value="VAVInfo.minOccupiedSetpoint"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!--Operating Status Card-->
        <v-col :cols="height >= maxHeight ? 12 : 4">
          <v-card variant="outlined" class="mb-n5 px-2">
            <v-row>
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                >
                  Operating Status
                </span>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mt-1 px-1">
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Supply Air Temperature (ºF)"
                  :model-value="VAVInfo.supplyAirTemperature"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                >
                  Current Airflow
                </span>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mt-1 px-1">
              <v-col cols="6">
                <v-text-field
                  class="text-primary"
                  label="Heating Signal %"
                  :model-value="VAVInfo.heatingSignal"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Cooling Signal %"
                  :model-value="VAVInfo.coolingSignal"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!--Damper Control Card-->
        <v-col :cols="height >= maxHeight ? 12 : 4">
          <v-card variant="outlined" class="mb-n5 px-2">
            <v-row>
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left pa-2"
                >
                  Damper Control
                </span>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mt-1 px-1">
              <v-col cols="6">
                <v-text-field
                  class="text-primary"
                  label="Desired Airflow (cfm)"
                  :model-value="VAVInfo.desiredAirflow"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="text-primary"
                  label="Current Airflow (cfm)"
                  :model-value="VAVInfo.currentAirflow"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="px-1 mt-n7">
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Need More Airflow %"
                  :model-value="VAVInfo.needMoreAirflow"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="px-1 mt-n7">
              <v-col>
                <v-text-field
                  class="text-primary"
                  label="Open Estimated Damper Position %"
                  :model-value="VAVInfo.openEstimatedDamperPosition"
                  variant="outlined"
                  density="compact"
                  readonly
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="6" align-self="center">
          <v-btn
            :disabled="isVAVSetLoading"
            data-testid="resetVAVButton"
            class="rounded-2"
            color="primary"
            variant="flat"
            block
            size="large"
            @click="isVAVDirty ? resetOccupiedSetpointValue() : handleCancel()"
          >
            <div
              class="text-subtitle-2 text-white text-truncate font-weight-bold"
            >
              {{ isVAVDirty ? 'Reset' : 'Cancel' }}
            </div>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            :loading="isVAVSetLoading"
            :disabled="!isVAVDirty || !userCanUpdate"
            data-testid="setVAVButton"
            class="rounded-2"
            :color="!isVAVDirty ? 'grey' : 'accentOrange'"
            variant="flat"
            block
            size="large"
            @click="handleSet"
          >
            <div
              class="text-subtitle-2 text-white text-truncate font-weight-bold"
            >
              Set
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { ref, watch, computed, getCurrentInstance } from 'vue';

import { IVAV } from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';
import API from '@/Core.Service.Domain/Controls/DigitalTwin/API/index.ts';

import VAVCardAlert from '@/Core.Service.Domain/Controls/DigitalTwin/Components/VAVCardAlert.vue';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

import { useDisplay } from 'vuetify';

const { height } = useDisplay();

const maxHeight = ref(1260);
const cardWidth = computed(() => {
  if (height.value < maxHeight.value) {
    return '1200px';
  } else {
    return '360px';
  }
});

const props = defineProps({
  VAVInfo: {
    type: Object as () => IVAV,
    required: true,
  },
  borderColor: {
    type: String,
    default: '#378CDA',
  },
});

const isVAVSetLoading = ref(false);
const localOccupiedSetpoint = ref(0);
const isDirty = ref({
  occupiedSetpoint: false,
});

const isVAVDirty = computed(() =>
  Object.values(isDirty.value).some((value) => value)
);

const instance = getCurrentInstance()!;
const appContext = instance?.appContext.config.globalProperties;

const userCanUpdate = computed(() => {
  return appContext?.$can('update', 'controls');
});

const handleSet = async () => {
  if (!userCanUpdate.value) return;
  isVAVSetLoading.value = true;

  try {
    await API.setVAVSetpoint(
      props.VAVInfo.controlId,
      localOccupiedSetpoint.value
    );
    resetIsDirty();

    const options: ToastOptions = {
      title: 'Success',
      message: `VAV settings have been updated successfully.<br /> Values can take up to 2 minutes to reflect.`,
      status: 'success',
    };
    appContext?.$toast.show(options);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isVAVSetLoading.value = false;
  }
};

const resetIsDirty = () => {
  isDirty.value = {
    occupiedSetpoint: false,
  };
};

const getVAVNewSettings = () => {
  return [
    {
      name: 'Occupied Setpoint',
      currentSetting: `${props.VAVInfo.occupiedSetpoint}`,
      newSetting: `${localOccupiedSetpoint.value}`,
    },
  ];
};

const resetOccupiedSetpointValue = () => {
  localOccupiedSetpoint.value = props.VAVInfo.occupiedSetpoint;
};

const handleCancel = () => {
  resetOccupiedSetpointValue();
  resetIsDirty();
  eventBus.$emit('update:selectedVAVId', props.VAVInfo.controlId);
};

watch(
  () => props.VAVInfo,
  () => {
    localOccupiedSetpoint.value = props.VAVInfo.occupiedSetpoint;
  },
  { immediate: true }
);

watch(localOccupiedSetpoint, (newVal) => {
  isDirty.value.occupiedSetpoint =
    Number(newVal) !== props.VAVInfo.occupiedSetpoint;
});
</script>

<style lang="scss">
.vav-control-tooltip {
  background: none !important;
}
</style>

<style lang="scss" scoped>
.v-text-field :deep(.v-text-field__slot) {
  input,
  .v-select__selection {
    color: $primary !important;
  }
}

.v-text-field :deep(.v-text-field__suffix) {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.v-alert :deep(.v-alert__border) {
  opacity: 1 !important;
}

.vav-control-dirty-icon {
  cursor: pointer;
}
</style>
