<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary mb-3">
      <span class="font-weight-bold">Demand Response Event</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <!--
          <v-text-field
            v-model="localInputData.EventType"
            outlined
            label="Event Type (e.g. SIMPLE-level)"
          />
        -->

        <v-select
          :items="eventTypes"
          :item-title="(item) => item.Name || ''"
          :item-value="(item) => item.Id"
          v-model="localInputData.EventType"
          label="Event Type"
          :rules="[(v) => !!v || 'Event Type is required']"
          variant="outlined"
          density="compact"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.EventValue"
          :rules="[isNumber]"
          label="Event Value"
          variant="outlined"
          density="compact"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.EventStart"
          :rules="[isDate]"
          label="Event Start Date/Time"
          variant="outlined"
          density="compact"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.EventEnd"
          :rules="[isDate]"
          label="Event End Date/Time (optional)"
          variant="outlined"
          density="compact"
        />
      </v-col>

      <v-col sm="3">
        <v-text-field
          v-model="localInputData.EventLengthMinutes"
          :rules="[isNumber]"
          label="Event Duration (minutes)"
          variant="outlined"
          density="compact"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'DemandResponseV1Input',

  data() {
    return {
      // devices: [],
      eventTypes: [{ Id: 'SIMPLE-level', Name: 'SIMPLE - level' }],
      localInputData: { ...this.InputData },
    };
  },
  async created() {
    // devices is an array of { "id": int, "name": string}
    //   this.devices = this.DataSources.find((d) => d.Key == 'PieraSensors').Value;
  },
  props: {
    InputData: {
      type: Object,
      required: true,
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
