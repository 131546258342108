<template>
  <v-card class="monitors-bar">
    <v-app-bar elevation="1" class="px-13">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ label }}
      </v-app-bar-title>
      <v-spacer />
      <v-row>
        <v-col>
          <v-autocomplete
            class="mt-6 mr-5"
            :items="sitesForPieraMonitors"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            return-object
            variant="outlined"
            density="compact"
            @update:modelValue="setDefaultSiteFromMonitorsDomain"
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            bg-color="white"
            single-line
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PieraMonitorsBar',

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null,
    };
  },

  created() {
    this.getSitesForPiera();
    this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
  },

  computed: {
    ...mapGetters({
      sitesForPieraMonitors:
        'MonitorsStore/PieraMonitorsStore/sitesForPieraMonitors',
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Monitor';
    },

    userCanDelete() {
      return this.$can('delete', 'meters');
    },
    userCanUpdate() {
      return this.$can('update', 'meters');
    },

    label() {
      return this.$route.meta.label || 'Piera Monitors';
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapActions({
      getSitesForPiera: 'MonitorsStore/PieraMonitorsStore/getSitesForPiera',
      getPieraMonitorsBySiteId:
        'MonitorsStore/PieraMonitorsStore/getPieraMonitorsBySiteId',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setPieraMonitorsTerm:
        'MonitorsStore/PieraMonitorsStore/PIERA_MONITORS_SEARCH_TERM',
    }),

    setDefaultSiteFromMonitorsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesForPieraMonitors[0];

      this.getPieraMonitorsBySiteId({
        selectedSite: this.selectedSite,
      }).then(() => {
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleSearchInput(value) {
      this.setPieraMonitorsTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setPieraMonitorsTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.monitors-bar {
  z-index: 1;

  ::v-deep .v-select {
    .v-select__slot > input {
      color: $primary !important;
    }
  }

  ::v-deep .v-list {
    .v-list-item__title {
      color: $primary !important;
    }
  }
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
