import { markRaw } from 'vue';

import VAV262Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite213/VAV262Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList = [
  {
    id: 0,
    name: 'VAV 2-62',
    controlId: 2233,
    component: markRaw(VAV262Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-62',
      },
    },
    style: {
      position: 'absolute',
      top: '9.2%',
      left: '15.8%',
      width: '93.3%',
      height: '93.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '50%',
      left: '59%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
