<template>
  <Form ref="obs">
    <v-form @submit.prevent="submit">
      <v-container fluid>
        <v-card class="pa-4 rounded-2">
          <v-row>
            <v-col sm="6">
              <v-select
                :items="inputTypes"
                item-value="inputKey"
                item-title="typeName"
                :loading="isLoadingTypes"
                :model-value="strategyTemplate.inputTypeName"
                label="Strategy Template Trigger"
                disabled
                variant="outlined"
                density="compact"
                :rules="[(v) => !!v || 'Input type is required']"
              />
            </v-col>
            <v-col sm="6">
              <v-select
                :items="frequencies"
                item-value="id"
                item-title="text"
                :rules="[(v) => !!v || 'Frequency is required']"
                :model-value="strategyTemplate.executionFrequency"
                label="Execution Frequency"
                hint="How often should the rule be executed?"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field
                autofocus
                :model-value="strategyTemplate.name"
                label="Strategy Template Name"
                required
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                :model-value="strategyTemplate.description"
                label="Strategy Template Description"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-card variant="outlined">
                <v-card-text
                  id="divRuleEditor"
                  ref="divRuleEditor"
                ></v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="ruleInvalid"
                class="mr-5"
              >
                Save
              </v-btn>
              <v-btn color="primary" @click="validateRule">Validate</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-form>
  </Form>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import API from '@/Core.Service.Domain/Strategies/StrategyTemplates/API';

import { Form } from 'vee-validate';

import {
  ISelectedTemplate,
  IRuleEditor,
  StrategyValidator,
  IStrategyPayload,
  InputType,
} from '@/Core.Service.Domain/Strategies/Strategies/types/strategies.ts';
import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

const instance = getCurrentInstance()!;
const appContext = instance?.appContext.config.globalProperties;

// Refs
const siteId = ref<string>('');
const inputTypes = ref<InputType[]>([]);
const ruleInvalid = ref(true);
const strategyTemplateId = ref('');
const strategyTemplate = ref<Partial<ISelectedTemplate>>({});
const frequencies = ref([
  { id: 'EveryFiveMinutes', text: 'Every 5 minutes' },
  { id: 'EveryTenMinutes', text: 'Every 10 minutes' },
  { id: 'EveryFifteenMinutes', text: 'Every 15 minutes' },
  { id: 'EveryThirtyMinutes', text: 'Every 30 minutes' },
  { id: 'EveryHour', text: 'Every hour' },
  { id: 'EveryDay', text: 'Every day' },
]);

const isLoadingTypes = ref(false);
const editorSettings = ref<{
  editorData: unknown;
  sourceData: unknown;
  ruleData: unknown;
} | null>(null);
const ruleEditor = ref<IRuleEditor | ISelectedTemplate | null>(null);

const route = useRoute();
const router = useRouter();

// Lifecycle
onMounted(async () => {
  const codeEffectsScript = document.createElement('script');
  codeEffectsScript.setAttribute('src', '/js/codeeffects.editor.js');
  document.head.appendChild(codeEffectsScript);

  const cssLink1 = document.createElement('link');
  cssLink1.setAttribute('rel', 'stylesheet');
  cssLink1.setAttribute('href', '/js/codeeffects.common.css');
  document.head.appendChild(cssLink1);

  const cssLink2 = document.createElement('link');
  cssLink2.setAttribute('rel', 'stylesheet');
  cssLink2.setAttribute('href', '/js/codeeffects.gray.css');
  document.head.appendChild(cssLink2);

  try {
    strategyTemplateId.value = route.params.strategyTemplateId as string;
    strategyTemplate.value = await API.getStrategyTemplateById(
      strategyTemplateId.value
    );
    inputTypes.value = await API.getInputTypes();
    editorSettings.value = await API.getEditorSettingsForStrategyTemplate(
      strategyTemplateId.value
    );
    loadEditorSettings();
  } catch (error) {
    return Promise.reject(error);
  }
});

// Methods
const submit = async () => {
  const valid = await (instance.refs.obs as StrategyValidator).validate();
  if (!valid) {
    return;
  }
  const ruleContent = ruleEditor.value?.extract();

  const payload: IStrategyPayload = {
    siteId: siteId.value,
    strategyName: strategyTemplate.value.name,
    strategyDescription: strategyTemplate.value.description,
    inputTypeName:
      typeof strategyTemplate.value.inputTypeName === 'string'
        ? strategyTemplate.value.inputTypeName
        : '',
    frequency: strategyTemplate.value.executionFrequency,
    editorData: ruleContent as object,
  };

  await API.saveStrategyTemplate(payload);

  const options: ToastOptions = {
    title: 'Success',
    message: 'Successfully created your strategy template.',
  };
  appContext?.$toast.show(options);
  router.push({ name: 'StrategyTemplatesList' });
};

const validateRule = async () => {
  try {
    const valid = await (instance.refs.obs as StrategyValidator).validate();
    if (!valid) {
      return;
    }
    const ruleContent = ruleEditor.value?.extract();

    const payload: Partial<ISelectedTemplate> = {
      strategyName: strategyTemplate.value.name,
      strategyDescription: strategyTemplate.value.description,
      inputTypeName: strategyTemplate.value.inputTypeName,
      frequency: strategyTemplate.value.executionFrequency,
      editorData: ruleContent as object,
    };

    const validationResult = await API.validateRule({
      ...payload,
      inputTypeName:
        typeof payload.inputTypeName === 'string'
          ? payload.inputTypeName
          : payload.inputTypeName?.inputKey ?? '',
    });
    if (validationResult.empty) {
      const options: ToastOptions = {
        title: 'Warning',
        message: 'Rule cannot be empty.',
        status: 'warning',
      };

      appContext?.$toast.show(options);
      ruleInvalid.value = true;
      return;
    }

    if (!validationResult.valid) {
      ruleEditor.value?.loadInvalids(
        JSON.stringify(validationResult.clientInvalidData)
      );
      ruleInvalid.value = true;
    } else {
      const options: ToastOptions = {
        title: 'Success',
        message: 'Rule is valid and can be saved.',
      };

      appContext?.$toast.show(options);
      ruleInvalid.value = false;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const handleCancelCrud = () => {
  router.push({ name: 'StrategyTemplatesList' });
};

const loadEditorSettings = () => {
  const $rule = window['$rule'];
  if (ruleEditor.value != null) ruleEditor.value.dispose();
  if ($rule && $rule.Context) {
    $rule.Context.clear();
  }

  ruleEditor.value = $rule.init(editorSettings.value?.editorData);
  ruleEditor.value?.clear();
  ruleEditor.value?.loadSettings(editorSettings.value?.sourceData);
  if (editorSettings.value?.ruleData)
    ruleEditor.value?.loadRule(editorSettings.value.ruleData);
};
</script>

<style lang="scss">
div .ceRule {
  min-height: 20vh;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
