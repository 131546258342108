<template>
  <v-card
    :id="`power-control_${PowerControlInfo.controlId}`"
    class="rounded-2"
    height="100%"
    :width="cardWidth"
    elevation="24"
  >
    <!--Card Header-->
    <v-toolbar color="primary" height="88">
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mt-n2" start size="small">
                    mdi-power-settings
                  </v-icon>
                  {{ PowerControlInfo.controlInfo.friendlyName }}
                </v-toolbar-title>
              </template>
              <span>{{ PowerControlInfo.controlInfo.friendlyName }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <!--Dirty Icon-->
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon
                  class="mt-2 ml-2 control-dirty-icon"
                  color="accentOrange"
                  v-bind="props"
                >
                  {{ isPowerControlDirty ? 'mdi-alert-circle' : '' }}
                </v-icon>
              </template>
              <span>
                {{
                  `You've made changes on ${this.$route.meta.toolTip}'s settings`
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <span class="text-aliceBlue text-caption ml-3 mt-n4">
              {{ PowerControlInfo.controlInfo.siteLocationName }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <v-container class="pa-4 rounded-2">
      <!--Settings Card-->
      <v-row>
        <v-col>
          <v-card variant="outlined" height="240px" class="pa-4 mb-n2">
            <v-row>
              <v-col>
                <v-alert
                  color="white"
                  border="start"
                  elevation="2"
                  :border-color="runningScheduleObject.color"
                  :icon="false"
                  class="mb-n2"
                >
                  <v-icon :color="runningScheduleObject.color" class="mr-4">
                    {{ runningScheduleObject.icon }}
                  </v-icon>
                  <span
                    class="text-primary font-weight-bold text-caption text-sm-subtitle-1"
                  >
                    {{ runningScheduleMessage }}
                  </span>
                </v-alert>
              </v-col>
            </v-row>
            <!--Settings-->
            <v-row>
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold"
                >
                  Toggle Device:
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col>
                <v-switch
                  hide-details
                  class="ml-1 mb-n3"
                  :disabled="!userCanUpdate"
                  color="primary"
                  inset
                  v-model="PowerControlStatus.on"
                  :label="scheduleToggleModes[PowerControlStatus.on]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="text-left text-caption text-sm-subtitle-2 text-primary font-weight-bold"
                >
                  {{ mapSetBy }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--Set Button-->
      <v-row>
        <v-col cols="6" align-self="center">
          <span
            class="text-left text-caption text-primary"
            :class="{ 'text-truncate': !smallBreakpoint }"
          >
            Last updated{{ PowerControlInfo.timeSinceLastUpdate }}
          </span>
        </v-col>
        <v-col cols="6">
          <v-btn
            v-if="userCanUpdate"
            :disabled="!isPowerControlDirty || isPowerControlSetLoading"
            :loading="isPowerControlSetLoading"
            class="rounded-2"
            :color="!isPowerControlDirty ? 'grey' : 'accentOrange'"
            variant="flat"
            block
            size="large"
            @click="handleSet"
          >
            <div class="text-subtitle-2 text-white font-weight-bold">Set</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialogs - Delete-->
    <ConfirmDialogDeletePowerControl
      v-if="showConfirmDialogDelete"
      :dialog="showConfirmDialogDelete"
      :powerControlInfo="PowerControlInfo"
      @update:dialog="(value) => (showConfirmDialogDelete = value)"
    />
    <!--Confirm Dialogs - Schedule-->
    <ConfirmDialogSchedulePowerControl
      v-if="showConfirmDialogSchedule"
      :dialog="showConfirmDialogSchedule"
      :powerControlInfo="PowerControlInfo"
      @update:dialog="(value) => (showConfirmDialogSchedule = value)"
    />
    <!--Confirm Dialogs - Admin-->
    <ConfirmDialogAdminPowerControl
      v-if="showConfirmDialogAdmin"
      :dialog="showConfirmDialogAdmin"
      :powerControlInfo="PowerControlInfo"
      @update:dialog="(value) => (showConfirmDialogAdmin = value)"
    />
    <!--History Dialog-->
    <PowerControlSettingsHistoryDialog
      v-if="showSettingsHistoryDialog"
      :dialog="showSettingsHistoryDialog"
      @update:dialog="(value) => (showSettingsHistoryDialog = value)"
      :controlDetails="controlDetails"
      @update:settingsHistoryList="() => (isSettingsHistoryLoading = false)"
    />
  </v-card>
</template>

<script>
import API from '@/Core.Service.Domain/Controls/PowerControls/API';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import ConfirmDialogDeletePowerControl from '@/Core.Service.Domain/Controls/PowerControls/Components/ConfirmDialogDeletePowerControl.vue';

import ConfirmDialogSchedulePowerControl from '@/Core.Service.Domain/Controls/PowerControls/Components/ConfirmDialogSchedulePowerControl.vue';

import PowerControlSettingsHistoryDialog from '@/Core.Service.Domain/Controls/PowerControls/Components/PowerControlSettingsHistoryDialog.vue';

import ConfirmDialogAdminPowerControl from '@/Core.Service.Domain/Controls/PowerControls/Components/ConfirmDialogAdminPowerControl.vue';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'PowerControlCard',

  components: {
    ActionButtonsVue,
    ConfirmDialogDeletePowerControl,
    ConfirmDialogSchedulePowerControl,
    ConfirmDialogAdminPowerControl,
    PowerControlSettingsHistoryDialog,
  },

  props: {
    PowerControlInfo: {
      type: Object,
      required: true,
    },
  },

  created() {
    eventBus.$on('isConfigLoading', (value) => {
      this.isConfigLoading = value;
    });
  },

  mounted() {
    this.$nextTick(() => {
      const controlAnchorPosition = localStorage.getItem(
        'controlAnchorPosition'
      );
      if (controlAnchorPosition) {
        window.scrollTo({
          top: document.querySelector(controlAnchorPosition).offsetTop - 150,
          behavior: 'smooth',
        });
      }
    });
  },

  data(instance) {
    return {
      controlDetails: {
        name: instance.PowerControlInfo.controlInfo.friendlyName,
        controlId: instance.PowerControlInfo.controlId,
        siteLocationName:
          instance.PowerControlInfo.controlInfo.siteLocationName,
      },
      isPowerControlSetLoading: false,
      isSettingsHistoryLoading: false,
      showConfirmDialogDelete: false,
      showConfirmDialogSchedule: false,
      showConfirmDialogAdmin: false,
      showHistoryDialog: false,
      showSettingsHistoryDialog: false,
      cardWidth: 360,
      isDirty: {
        on: false,
      },

      isConfigLoading: false,

      PowerControlStatus: {
        on: instance.PowerControlInfo.on,
      },

      actionButtons: [
        {
          icon: 'calendar-blank',
          toolTip: 'Schedule Dashboard',
          show: this.$can('update', 'controlschedules'),
          action: () => {
            instance.showConfirmDialogSchedule = true;
          },
          loading: () => instance.isConfigLoading,
        },
        {
          icon: 'cog',
          toolTip: 'Admin Dashboard',
          show: this.$can('administer', 'control'),
          action: () => {
            instance.showConfirmDialogAdmin = true;
          },
        },
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          show: this.$can('update', 'controls'),
          action: () => {
            // this route will be replaced when the new edit page is ready
            instance.$router.push({
              name: 'ControlsEdit',
              params: {
                controlId: instance.PowerControlInfo.controlId,
                type: 'powercontrol',
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          show: this.$can('delete', 'controls'),
          action: () => {
            instance.showConfirmDialogDelete = true;
          },
        },
        {
          icon: 'history',
          toolTip: `${instance.$route.meta.toolTip}'s Changes' History`,
          disabled: () =>
            instance.PowerControlInfo.controlInfo.hasSettingsMessages === false,
          loading: () => instance.isSettingsHistoryLoading,
          action: () => {
            if (
              instance.PowerControlInfo.controlInfo.hasSettingsMessages ===
              false
            )
              return;
            instance.isSettingsHistoryLoading = true;
            instance.showSettingsHistoryDialog = true;
          },
        },
      ],

      scheduleToggleModes: {
        true: 'On',
        false: 'Off',
      },
    };
  },

  computed: {
    isPowerControlDirty() {
      return Object.values(this.isDirty).some((value) => value);
    },

    runningScheduleObject() {
      return this.PowerControlInfo.isScheduleOn
        ? { color: 'greenGraph', icon: 'mdi-check-circle' }
        : { color: 'warning', icon: 'mdi-alert-octagon' };
    },

    runningScheduleMessage() {
      return `Is Running ${
        this.scheduleToggleModes[this.PowerControlInfo.isScheduleOn]
      } Schedule`;
    },

    mapSetBy() {
      const setBy = {
        Schedule: 'Following Schedule',
        Station: 'Local Override',
        Remote: 'Software Override',
      };
      return setBy[this.PowerControlInfo.setBy];
    },

    userCanUpdate() {
      return this.$can('update', 'controls');
    },
  },

  watch: {
    'PowerControlStatus.on': function (newVal) {
      this.isDirty.on = newVal !== this.PowerControlInfo.on;
    },
  },

  methods: {
    async handleSet() {
      if (!this.userCanUpdate) {
        return;
      }

      this.controlAnchorPosition();
      this.isPowerControlSetLoading = true;

      const sanitizedData = this.sanitizePowerControlData();
      try {
        await API.setPowerControlData(sanitizedData);
        this.cachePowerControls(sanitizedData);
        this.$emit('set-power-control-config');
        this.isDirty = { on: false };
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.isPowerControlSetLoading = false;
      }
    },

    controlAnchorPosition() {
      localStorage.setItem(
        'controlAnchorPosition',
        `#power-control_${this.PowerControlInfo.controlId}`
      );
    },

    sanitizePowerControlData() {
      const { PowerControlInfo, PowerControlStatus, scheduleToggleModes } =
        this;
      const { controlInfo, ...powerControl } = PowerControlInfo;

      return {
        ...powerControl,
        ...controlInfo,
        on: PowerControlStatus.on,
        output: scheduleToggleModes[PowerControlStatus.on],
        powerControlId: controlInfo.controlId || controlInfo.id,
      };
    },

    cachePowerControls(powerControl, now = Math.floor(Date.now() / 1000)) {
      try {
        const powerControls =
          JSON.parse(localStorage.getItem('local-storage-power-controls')) ||
          [];
        powerControl.updatedAt = now;

        const foundPowerControl = powerControls.findIndex(
          (item) => item.id === powerControl.id
        );
        if (foundPowerControl > -1) {
          powerControls[foundPowerControl] = powerControl;
        } else {
          powerControls.push(powerControl);
        }

        localStorage.setItem(
          'local-storage-power-controls',
          JSON.stringify(powerControls)
        );
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-alert :deep(.v-alert__border) {
  opacity: 1 !important;
}

.control-dirty-icon {
  cursor: pointer;
}
</style>
